import _includes from 'lodash/includes'
import _toLower from 'lodash/toLower'
import _toUpper from 'lodash/toUpper'
import _split from 'lodash/split'

// returns the symbols present in a pair
// it supports the following formats:
// - <any>/<any>
// - <any>:<any>
// - t<any>/<any>
// - t<any>:<any>
// - YYY/ZZZ
// - tYYY/ZZZ
// - fYYY
// - [t]XXXXYYYY
// - [t]XXXXYYY
// - [t]XXXYYYY
export function getSymbols(pair = '') {
  const [firstLetter, ...rest] = pair.split('')
  const input = firstLetter === 't' || firstLetter === 'f' ? rest.join('') : pair

  if (_includes(pair, ':')) {
    return _split(input, ':', 2) // XXXF0:YYYF0 format
  }

  if (_includes(pair, '/')) {
    return _split(input, '/', 2) // XXX/YYY format
  }

  // XXXXYYYY format
  if (input.length === 8) {
    return [input.slice(0, 4), input.slice(4, 8)]
  }

  // XXXXYYY or XXXYYYY format (only reversed (t) DAI pairs)
  if (input.length === 7) {
    const isFirstLong = ['USDT'].includes(input.slice(0, 4)) // USDT is only 4-char DAI pair

    if (isFirstLong) {
      return [input.slice(0, 4), input.slice(4, 7)] // XXXXYYY format
    } else {
      return [input.slice(0, 3), input.slice(3, 7)] // XXXYYYY format
    }
  }

  return [input.slice(0, 3), input.slice(3, 6)] // XXXYYY format
}

// first symbol of a pair
// ex. tBTCUSD, tBTC/USD, tBTC:USD

export function firstInPair(pair?: string, uppercase?: boolean) {
  const [first = '-'] = getSymbols(pair)

  return uppercase ? _toUpper(first) : _toLower(first)
}

// last symbol of a pair
// ex. tBTCUSD, tBTC/USD, tBTC:USD
export function lastInPair(pair?: string, uppercase?: boolean) {
  const [, second = '-'] = getSymbols(pair)

  return uppercase ? _toUpper(second) : _toLower(second)
}

export function firstInPairPreserveCase(pair: string): string
export function firstInPairPreserveCase(pair?: string | null | undefined): string | null
export function firstInPairPreserveCase(pair?: string | null | undefined) {
  if (!pair) {
    return null
  }
  const [first = '-'] = getSymbols(pair)
  return first
}

export function lastInPairPreserveCase(pair: string): string
export function lastInPairPreserveCase(pair?: string | null | undefined): string | null
export function lastInPairPreserveCase(pair?: string | null | undefined) {
  if (!pair) {
    return null
  }
  const [, second = '-'] = getSymbols(pair)
  return second
}
