import P from '@rhino.fi/aigle'
import { NameServices, nameServiceReverseLookup } from '../../services/nameServices/nameServices'
import type { AppDispatch } from '../../store/configureStore'
import { userSlice } from '../../reducers/userSlice'

export const setNameServiceNames = (dispatch: AppDispatch) => async (address: string) => {
  const nsNames = await P.reduce(
    Object.values(NameServices),
    async (acc, ns) => {
      try {
        const nsName = await nameServiceReverseLookup(address, ns)
        if (!nsName) {
          return acc
        }
        return {
          ...acc,
          [ns]: nsName,
        }
      } catch (error) {
        console.error(error)
        return acc
      }
    },
    {},
  )
  dispatch(userSlice.actions.setNameServiceNames({ nsNames }))
}
