import type { Account } from '@paradex/sdk/dist/account'
import type { ParaclearProvider } from '@paradex/sdk/dist/paraclear-provider'
import type { ParadexConfig } from '@paradex/sdk/dist/config'

let paradexProvider: ParaclearProvider | null = null
let paradexAccountProvider: Account | null = null
let paradexConfig: ParadexConfig | null = null

export const setParadexProvider = (provider: ParaclearProvider, accountProvider: Account, config: ParadexConfig) => {
  paradexProvider = provider
  paradexAccountProvider = accountProvider
  paradexConfig = config
}

export const getParadexProvider = () => {
  if (!paradexProvider || !paradexAccountProvider || !paradexConfig) {
    throw new Error('Please connect to Paradex first.')
  }
  return {
    account: paradexAccountProvider,
    provider: paradexProvider,
    config: paradexConfig,
  }
}
