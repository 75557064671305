import * as Sentry from '@sentry/react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- errors are usualy of type unknown, so typing would require a lot of additional type guards, possibly impacting the logic
export const reportToSentry = (error: any) => {
  const errorMessage =
    error?.error?.details?.error?.message ||
    error?.error?.message ||
    error?.reason ||
    error?.message ||
    (typeof error === 'string' && error) ||
    'Unknown error'
  if (
    typeof errorMessage === 'string' &&
    (errorMessage.indexOf('denied') >= 0 ||
      errorMessage.indexOf('failed to fetch') >= 0 ||
      errorMessage.indexOf('MetaMask') >= 0)
  ) {
    return false
  }

  try {
    Sentry.captureException(error instanceof Error ? error : new Error(errorMessage), { extra: error })
  } catch (caughtError) {}
}

export const reportMessageToSentry = (message: string, extra: Record<string, unknown>) => {
  try {
    Sentry.captureMessage(message, {
      level: 'error',
      extra,
    })
  } catch (error) {}
}
