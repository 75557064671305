import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import { getChainRpcUrl } from '../ethereum/chainProviders'

const APP_NAME = 'rhino.fi'
const APP_LOGO_URL = 'https://rhino.fi/wp-content/uploads/2022/07/Primary_Rhino_Grad1x2.png'
const DEFAULT_ETH_JSONRPC_URL = getChainRpcUrl('ETHEREUM')
const DEFAULT_CHAIN_ID = 1

// Initialize Coinbase Wallet SDK
let coinbaseWallet: CoinbaseWalletSDK | null

// Initialize a Web3 Provider object
export const getCoinbaseProvider = () => {
  if (!coinbaseWallet) {
    coinbaseWallet = new CoinbaseWalletSDK({
      appName: APP_NAME,
      appLogoUrl: APP_LOGO_URL,
      darkMode: true,
    })
  }

  return coinbaseWallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID)
}
