import type { Action, ListenerEffectAPI } from '@reduxjs/toolkit'
import { merge } from 'lodash'
import type { UPDATE_STATUS_NOTIFICATION, UpdateStatusNotificationAction } from '../actions/types/statusNotifications'
import { heapEvents } from '../constants/heapEvents'
import { trackHeapEvent } from '../services/apiService'
import type { FilledRootState } from '../store/rootReducer'
import type { AppDispatch } from '../store/store.types'

export const updateStatusNotificationListener = (
  action: Action<typeof UPDATE_STATUS_NOTIFICATION>,
  listenerApi: ListenerEffectAPI<FilledRootState, AppDispatch, unknown>,
) => {
  const { id, updatedFields } = (action as UpdateStatusNotificationAction).payload
  const notification = listenerApi.getState().notifications.statusNotifications[id]

  trackHeapEvent(
    heapEvents.notification,
    merge(
      {},
      {
        id: notification.id,
        status: notification.status,
        title: notification.title,
        description: notification?.meta?.description,
      },
      {
        status: updatedFields.status,
        title: updatedFields.title,
        description: updatedFields?.meta?.description,
      },
    ),
  )
}
