import crypto from 'crypto'
import { authKey, recoverTradingKey, sendWalletFailedEvent, storeTradingKey } from '../apiService'
import { showDTKRecoveryNotification } from '../../actions/notificationActions'
import { reportToSentry } from '../helperService/reportToSentry'
import { DTK_CREATION_ERROR, DTK_DECRYPT_ERROR, DTK_RECOVER_ERROR } from '../../constants/walletEvents'
import { isLegacyMM } from '../wallets/legacyService'
import { hashFromEIP712Signature, selectSigningMethod, signEIP712Hash } from '../dtk/signService'
import { SigningMethod } from '../dtk/signatures'
import { getDvf } from '../dvfClient'
import type { SupportedWallets } from '../../constants/types'
import type { WalletPayload } from '../wallets/wallet'
import { isValidWalletError } from '../helperService/isValidWalletError'
import { StatusNotificationStatus } from '../../actions/types/statusNotifications'
import { parseInvalidEIP712Signature } from '../helperService/parseInvalidEIP712Signature'
import { getStarkKeys } from '../starkService'
import type { TradingKey } from './tradingKey'

export const ACTIVE_DTK_VERSION: TradingKey['version'] = 'v3'

type TradingKeystore = {
  privateKey: string
}

export const getWebWalletTradingKey = async (
  address: string,
  walletType: SupportedWallets,
  isRegistered: boolean,
  registeredStarkPublicKey: string,
) => {
  let dtk: ReturnType<typeof getDTKOnly> | string = getDTKOnly(address)
  const { encryptedTradingKey, dtkVersion } = await recoverTradingKey()

  // Initiate migration modal for users using the dtk_v1 or dtk_v2
  if (encryptedTradingKey && (!dtkVersion || dtkVersion !== ACTIVE_DTK_VERSION)) {
    return {
      ...dtk,
      requestMigration: true,
      version: dtkVersion,
    }
  }

  // Check if locally available
  if (dtk.privateKey) {
    return {
      ...dtk,
      version: dtkVersion,
    }
  }

  // Retrieve the encrypted dtk from API and decrypt it with dtk_v3
  if (encryptedTradingKey && dtkVersion === ACTIVE_DTK_VERSION) {
    try {
      showDTKRecoveryNotification(StatusNotificationStatus.pending)
      dtk = await recoverDTK_v3(address, walletType, dtkVersion, encryptedTradingKey)
      showDTKRecoveryNotification(StatusNotificationStatus.success)
      return {
        privateKey: dtk,
        version: dtkVersion,
      }
    } catch (error) {
      console.error(error)
      reportToSentry(error)
      showDTKRecoveryNotification(StatusNotificationStatus.error)
      if (isValidWalletError(error)) {
        sendWalletFailedEvent(walletType, DTK_DECRYPT_ERROR)
      }
      return null
    }
  }

  // Check if the registered user is legacy since we couldn't recover a trading key
  if (registeredStarkPublicKey) {
    // Redirect legacy users
    const { tradingKeystore } = getStoredTradingKeys(address)
    if (tradingKeystore) {
      const _tradingKeystore = JSON.parse<TradingKeystore>(tradingKeystore)
      return {
        ..._tradingKeystore,
        isLegacy: await isLegacyMM(_tradingKeystore, isRegistered, registeredStarkPublicKey),
        requestLegacyMigration: true,
        version: dtkVersion,
      }
    } else if (!tradingKeystore && isRegistered) {
      return {
        requestLegacyMigration: true,
        needsToImportTradingKey: true,
        version: dtkVersion,
        privateKey: null,
      }
    }
  }

  return null
}

/**
 * Get tradingKeys stored in localStorage
 */
export const getStoredTradingKeys = (address: string) => {
  const tradingKeystore = window.localStorage.getItem(`tradingKey-${address.toLowerCase()}`)
  const dtk = window.localStorage.getItem(`dtk-${address.toLowerCase()}`)

  return {
    tradingKeystore,
    dtk,
  }
}

export const hasStoredDTK = (address: string) => {
  const { dtk } = getStoredTradingKeys(address)
  return dtk !== undefined && typeof dtk === 'string' && dtk.length > 0
}

export const recoverLegacyDTK = async (address: string, walletType?: SupportedWallets) => {
  try {
    const { encryptedTradingKey } = await recoverTradingKey()
    const dtk = await decryptMetamask(address, encryptedTradingKey)
    window.localStorage.setItem(`dtk-${address?.toLowerCase()}`, dtk)
    return dtk
  } catch (error) {
    if (isValidWalletError(error)) {
      sendWalletFailedEvent(walletType, DTK_RECOVER_ERROR)
    }
    if ((error as Error).message.indexOf('eth_decrypt does not exist') >= 0) {
      throw new Error('Please switch to desktop and migrate your account before using rhino.fi on mobile.')
    }
    throw error
  }
}

export const recoverDTK_v3 = async (
  address: string,
  walletType: SupportedWallets,
  dtkVersion?: string,
  _encryptedTradingKey?: string,
) => {
  let encryptedTradingKey = _encryptedTradingKey
  if (!encryptedTradingKey) {
    encryptedTradingKey = (await recoverTradingKey()).encryptedTradingKey
  }
  const eip712Signature = await signEIP712Hash(address, selectSigningMethod(walletType), dtkVersion, false)
  const encryptionPrivateKey = hashFromEIP712Signature(eip712Signature)

  try {
    const dtk = await decryptWithDTK_v3(encryptionPrivateKey, encryptedTradingKey)
    if (address) {
      persistDTK(address, dtk)
    }
    return dtk
  } catch (error: unknown) {
    // Attempt to re-decrypt in case of invalid keys with a fixed key
    // https://github.com/MetaMask/eth-ledger-bridge-keyring/issues/178
    if (error instanceof Error && error.message?.toLowerCase()?.indexOf('bad mac') >= 0) {
      try {
        const fixedEip712Signature = parseInvalidEIP712Signature(eip712Signature as string)
        const hashedEncryptionPrivateKey = hashFromEIP712Signature(fixedEip712Signature)
        const dtk = await decryptWithDTK_v3(hashedEncryptionPrivateKey, encryptedTradingKey)
        if (address) {
          persistDTK(address, dtk)
        }
        return dtk
      } catch (errorInner: unknown) {
        console.error(`Failed to re-decrypt with a fixed key: ${errorInner}`)
        throw errorInner
      }
    }
    throw error
  }
}

export const getDTKOnly = (address: string) => {
  const { dtk } = getStoredTradingKeys(address)
  if (dtk) {
    return {
      privateKey: dtk,
    }
  }
  return {
    privateKey: null,
  }
}

export const getDTKPrivateKey = (wallet: WalletPayload) => {
  return wallet?.tradingKey?.privateKey
}

// Create DTK and store it for recovery
export const createDTK = async (address: string, walletType: SupportedWallets) => {
  const dtk = crypto.randomBytes(31).toString('hex')
  let encryptedTradingKey
  try {
    const encryptionKey = await signEIP712Hash(address, selectSigningMethod(walletType), ACTIVE_DTK_VERSION)
    encryptedTradingKey = await encryptWithDTK_v3(address, encryptionKey, dtk, false)
  } catch (error) {
    console.error(error)
    if (isValidWalletError(error)) {
      sendWalletFailedEvent(walletType, DTK_CREATION_ERROR)
    }
    throw new Error('User denied public key request.')
  }
  persistDTK(address, dtk)

  return {
    privateKey: dtk,
    encryptedTradingKey: {
      dtk: encryptedTradingKey,
      dtkVersion: ACTIVE_DTK_VERSION,
    },
  }
}

type SavedTradingKey = {
  privateKey?: string
  isLegacy?: boolean
  isBackedUp?: boolean
}

export const migrateDTK_v3 = async (wallet: { address: string; tradingKey: SavedTradingKey }, isLegacyFlow = false) => {
  const { address, tradingKey } = wallet
  const encryptionPrivateKey = await signEIP712Hash(address, SigningMethod.v4, ACTIVE_DTK_VERSION)
  const dtk = tradingKey?.privateKey
  if (!dtk) {
    throw new Error('Trading key was not found. Please try again.')
  }
  if (isLegacyFlow) {
    window.localStorage.removeItem(`tradingKey-${address.toLowerCase()}`)
  }
  await encryptWithDTK_v3(address, encryptionPrivateKey, dtk, true)
  return true
}

export const tradingKeyBackedUp = (address: string) => {
  const tradingKey = JSON.parse<SavedTradingKey>(
    window.localStorage.getItem(`tradingKey-${address.toLowerCase()}`) || '{}',
  )
  tradingKey.isBackedUp = true
  window.localStorage.setItem(`tradingKey-${address.toLowerCase()}`, JSON.stringify(tradingKey))
}

export const updateTradingKey = (address: string, data?: SavedTradingKey) => {
  let tradingKey = JSON.parse<SavedTradingKey>(
    window.localStorage.getItem(`tradingKey-${address.toLowerCase()}`) || '{}',
  )
  if (tradingKey === null) {
    return
  }
  tradingKey = {
    ...tradingKey,
    ...data,
  }
  window.localStorage.setItem(`tradingKey-${(address || '').toLowerCase()}`, JSON.stringify(tradingKey))
}

export const encryptWithDTK_v3 = async (
  address: string,
  encryptionKey: string | undefined | null,
  dtk: string,
  persist: boolean,
) => {
  const { default: EthCrypto } = await import('eth-crypto')
  const publicKey = EthCrypto.publicKeyByPrivateKey(`0x${encryptionKey}`)
  const encrypted = await EthCrypto.encryptWithPublicKey(
    publicKey, // publicKey
    JSON.stringify({ data: dtk }), // message
  )
  const encryptedMessage = EthCrypto.cipher.stringify(encrypted)
  if (persist) {
    // call API to store the DTK safely
    try {
      await storeTradingKey(encryptedMessage)
      persistDTK(address, dtk)
    } catch (error) {
      throw new Error('Could not save trading key.')
    }
  }
  return encryptedMessage
}

const testWalletEncryption = async (address: string) => {
  const encryptionKey = await signEIP712Hash(address, selectSigningMethod('metamask'), ACTIVE_DTK_VERSION)

  const { default: EthCrypto } = await import('eth-crypto')
  const publicKey = EthCrypto.publicKeyByPrivateKey(`0x${encryptionKey}`)
  const encrypted = await EthCrypto.encryptWithPublicKey(
    publicKey, // publicKey
    JSON.stringify({ data: 'test message' }), // message
  )
  const encryptedMessage = EthCrypto.cipher.stringify(encrypted)

  const parsedEncryptedMessage = EthCrypto.cipher.parse(encryptedMessage)
  const result = await EthCrypto.decryptWithPrivateKey(`0x${encryptionKey}`, parsedEncryptedMessage)

  console.warn('Test result')
  console.warn(result)
}
// @ts-expect-error -- temporarily allow extending window
window.testWalletEncryption = testWalletEncryption

const testDtkEncryption = async (address: string) => {
  const storedDtk = window.localStorage.getItem(`dtk-${address.toLowerCase()}`)
  if (!storedDtk) {
    throw new Error('No DTK found.')
  }
  const encryptionKey = await signEIP712Hash(address, selectSigningMethod('metamask'), ACTIVE_DTK_VERSION)

  const { default: EthCrypto } = await import('eth-crypto')
  const publicKey = EthCrypto.publicKeyByPrivateKey(`0x${encryptionKey}`)
  const encrypted = await EthCrypto.encryptWithPublicKey(
    publicKey, // publicKey
    JSON.stringify({ data: storedDtk }), // message
  )
  const encryptedMessage = EthCrypto.cipher.stringify(encrypted)

  const starkKeys = await getStarkKeys(storedDtk)

  console.warn('Test result')
  console.warn(encryptedMessage)
  console.warn(starkKeys.starkPublicKey.x)
}
// @ts-expect-error -- temporarily allow extending window
window.testDtkEncryption = testDtkEncryption

const decryptWithDTK_v3 = async (dtk: string, encryptedMessage: string) => {
  const { default: EthCrypto } = await import('eth-crypto')
  const parsedEncryptedMessage = EthCrypto.cipher.parse(encryptedMessage)

  const result = await EthCrypto.decryptWithPrivateKey(`0x${dtk}`, parsedEncryptedMessage)

  const { data } = JSON.parse<{ data: string }>(result || '')
  return data
}

const decryptMetamask = (address: string, encryptedMessage: string) => {
  return window.ethereum.request({
    method: 'eth_decrypt',
    params: [encryptedMessage, address],
  })
}

export const persistDTK = (address: string, dtk: string) => {
  window.localStorage.setItem(`dtk-${address.toLowerCase()}`, dtk)
}

export const clearDTKandAuth = (address: string) => {
  window.localStorage.removeItem(`dtk-${address.toLowerCase()}`)
  window.localStorage.removeItem(authKey(address))
  window.localStorage.removeItem(`signingAddress-${address.toLowerCase()}`)
}

export const attachTradingKeyToDvf = async (dtk: string) => {
  const dvf = await getDvf()
  dvf.util.attachStarkProvider({
    type: 'tradingKey',
    meta: {
      starkPrivateKey: dtk,
    },
  })
}
