import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NETWORKS } from '../../constants/types'
import type { RootState } from '../../store/configureStore'
import { showModal } from '../../actions/modalActions/showModal'
import { modalKeys } from '../../constants/modalKeys'
import { requireChain } from '../../actions/accountPickerActions'
import { isAddressChangeAction } from '../../actions/userActions/addressChangeActions'
import { connectToStarknet } from './connectors/connectToStarknet'
import { connectToTron } from './connectors/connectToTron'
import type { SecondaryConnectionPayload } from './connectors/connectors.types'
import { triggerReconnectModal } from './triggerReconnectModal'
import { checkWalletInstalled } from './checkWalletInstalled'
import { connectToSolana } from './connectors/connectToSolana'
import { connectToParadex, ParadexWalletOptions } from './connectors/connectToParadex'

export const defaultSecondaryConnectState = {
  address: '',
  networkId: '',
  domain: '',
  connectedChain: '',
}
export const connectSecondaryWallet = createAsyncThunk<
  SecondaryConnectionPayload,
  { chain: string; walletOption?: string },
  { state: RootState }
>('secondary/connect', async ({ chain, walletOption = '' }, thunkAPI) => {
  try {
    const currentState = thunkAPI.getState().secondaryWallet
    const isInstalled = checkWalletInstalled(chain)
    if (!isInstalled) {
      showModal(thunkAPI.dispatch)(modalKeys.noWallet, { chain })
      return currentState
    }
    if (!!currentState.connectedChain && currentState.connectedChain !== chain && !walletOption) {
      const canProceed = await triggerReconnectModal(thunkAPI.dispatch, currentState.connectedChain, chain)
      if (!canProceed) {
        return currentState
      }
    }
    if (chain === NETWORKS.PARADEX && !walletOption) {
      showModal(thunkAPI.dispatch)(modalKeys.connectToParadex)
      return currentState
    }
    switch (chain) {
      case NETWORKS.STARKNET:
        return connectToStarknet(thunkAPI.dispatch)
      case NETWORKS.PARADEX:
        if (walletOption === ParadexWalletOptions.EVM) {
          const switchChainResult = await requireChain(thunkAPI.dispatch)(NETWORKS.ETHEREUM)
          if (switchChainResult.done === false) {
            return currentState
          }
        }
        return connectToParadex(walletOption)
      case NETWORKS.TRON:
        return connectToTron(thunkAPI.dispatch)
      case NETWORKS.SOLANA:
        return connectToSolana(thunkAPI.dispatch)
      default:
        return defaultSecondaryConnectState
    }
  } catch (error) {
    console.error(error)
    return defaultSecondaryConnectState
  }
})

export const secondaryWalletSlice = createSlice({
  name: 'secondaryWallet',
  initialState: {
    status: '',
    address: '',
    networkId: '',
    domain: '',
    connectedChain: '',
  },
  reducers: {
    updateSecondaryAddress: (state, action: PayloadAction<string>) => {
      if (state.address === action.payload) {
        return
      }
      state.address = action.payload
    },
    updateSecondaryNetworkId: (state, action: PayloadAction<string>) => {
      if (state.networkId === action.payload) {
        return
      }
      state.networkId = action.payload
    },
    updateSecondaryDomain: (state, action: PayloadAction<string>) => {
      if (state.domain === action.payload) {
        return
      }
      state.domain = action.payload
    },
    disconnectSecondaryWallet: (state) => {
      state.address = ''
      state.networkId = ''
      state.domain = ''
      state.connectedChain = ''
      state.status = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectSecondaryWallet.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(connectSecondaryWallet.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(connectSecondaryWallet.fulfilled, (state, action) => {
      if (!action.payload.address) {
        state.status = ''
        return
      }
      state.address = action.payload.address
      state.networkId = action.payload.networkId
      state.domain = action.payload.domain
      state.connectedChain = action.payload.connectedChain
      state.status = 'fulfilled'
    })
    builder.addMatcher(isAddressChangeAction, (state) => {
      // As paradex accounts are based on the primary wallet, disconnect so user can re-generate the key
      if (state.connectedChain === NETWORKS.PARADEX) {
        state.address = ''
        state.networkId = ''
        state.domain = ''
        state.connectedChain = ''
        state.status = ''
      }
    })
  },
})

export const { updateSecondaryAddress, updateSecondaryNetworkId, updateSecondaryDomain, disconnectSecondaryWallet } =
  secondaryWalletSlice.actions
