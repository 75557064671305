import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type Ticker = {
  symbol: string
  timestamp: string
  dailyChange: number
  dailyChangePerc: number
  dailyChangeRelative: number
  open: number
  high: number
  low: number
  lastPrice: number
  volume: number
}

export type TickerState = Record<string, Ticker>

const getInitialState = (): TickerState => {
  return {}
}

export const tickerSlice = createSlice({
  name: 'ticker',
  initialState: getInitialState(),
  reducers: {
    tickerRestUpdate(state, action: PayloadAction<Omit<Ticker, 'dailyChangePerc'>[]>) {
      action.payload?.forEach &&
        action.payload.forEach((ticker) => {
          state[ticker.symbol] = {
            ...ticker,
            dailyChangePerc: ticker.dailyChangeRelative,
          }
        })
    },
  },
})
