import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../../env/envConfig'
import { rhinoGmAbi } from '../abis/rhinoGmAbi'
import { getDvf } from '../dvfClient'
import { nameServiceReverseLookup } from '../nameServices/nameServices'
import type {
  ContractInteractionCodec,
  ContractsPaginated,
  GetMultipleContractsArgs,
  GetMultipleContractsParams,
  UserContractCodec,
} from './contractsInteractions.types'
import { CHAIN_NS_MAPPING } from './contractsInteractions.constants'

const baseUrl = `${envConfig.tradingApi}/contract-interactions`

export const contractsInteractionsApi = createApi({
  reducerPath: 'contractsInteractionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: ['SingleContract', 'MultipleContracts', 'UserInteractions', 'ContractFee'],
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getSingleContract: builder.query<UserContractCodec | null, { chain: string; address: string }>({
      query: ({ chain, address }) => ({
        url: `/contracts/${chain}/${address}`,
      }),
      transformResponse: (res: UserContractCodec | null) => {
        // isLoading is still true even though the request was completed
        if (res === null) {
          throw new Error('NOT_FOUND')
        }
        return res
      },
      providesTags: ['SingleContract'],
    }),
    getMultipleContracts: builder.query<ContractsPaginated<UserContractCodec>, GetMultipleContractsArgs>({
      query: ({ chain, deployer, sortBy, limit, skip, weekly }) => {
        const params: GetMultipleContractsParams = {
          sortBy,
          limit,
          skip,
        }

        if (deployer) {
          params.deployer = deployer
        }
        if (weekly) {
          params.weekly = true
        }

        return {
          url: `/contracts/${chain}`,
          params,
        }
      },
      providesTags: ['MultipleContracts'],
    }),
    getUserInteractions: builder.query<
      ContractsPaginated<ContractInteractionCodec>,
      { chain: string; address: string; limit: number; skip: number }
    >({
      query: ({ chain, address, limit, skip }) => ({
        url: `/interactions/${chain}/${address}`,
        params: { limit, skip },
      }),
      providesTags: ['UserInteractions'],
    }),
    getContractFee: builder.query<string, { chain: string; contractAddress: string }>({
      queryFn: async ({ chain, contractAddress }) => {
        const dvf = await getDvf()
        return {
          data: await dvf.eth.call<string>(rhinoGmAbi, contractAddress, 'feeOnGM', [], { chain }),
        }
      },
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
      providesTags: ['ContractFee'],
    }),
    doNsReverseLookup: builder.query<string, { address: string; chain: string }>({
      queryFn: async ({ address, chain }) => {
        const nsService = CHAIN_NS_MAPPING[chain]

        return {
          data: nsService ? await nameServiceReverseLookup(address, nsService) : '',
        }
      },
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
    }),
  }),
})

export const {
  useGetSingleContractQuery,
  useGetMultipleContractsQuery,
  useGetUserInteractionsQuery,
  useGetContractFeeQuery,
  useDoNsReverseLookupQuery,
} = contractsInteractionsApi
