import { createListenerMiddleware } from '@reduxjs/toolkit'
import { UPDATE_STATUS_NOTIFICATION } from '../actions/types/statusNotifications'
import { updateStatusNotificationListener } from '../listeners/updateStatusNotificationListener'
import type { RootState } from '../store/configureStore'
import type { AppDispatch } from '../store/store.types'
import { MODAL_SHOW } from '../actions/modalActions'
import { TxStatus } from '../services/txService/txService.types'
import { clearModal } from '../actions/modalActions/clearModal'

export const listenerMiddleware = createListenerMiddleware<RootState, AppDispatch>()

listenerMiddleware.startListening({
  type: UPDATE_STATUS_NOTIFICATION,
  effect: updateStatusNotificationListener,
})

// Close tx modal if the modal was previously closed and
// the async execution func wants to open a new one with a different txStatus
listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    if (action.type !== MODAL_SHOW) {
      return false
    }

    // Pass if no modal
    if (!currentState.modal.modal.modalShown) {
      return false
    }

    // Pass if same modal
    if (currentState.modal.modal.modalShown === previousState.modal.modal.modalShown) {
      return false
    }

    const { modalPayload } = currentState.modal.modal

    // Pass if modal doesn't have txStatus
    if (!modalPayload || (modalPayload && !('txStatus' in modalPayload))) {
      return false
    }

    // Fail if txStatus isn't the initial one
    return modalPayload.txStatus !== TxStatus.WAITING_WALLET
  },
  effect: (action, { dispatch }) => {
    clearModal(dispatch)()
  },
})
