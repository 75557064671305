import { showModal } from '../../../actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import type { SupportedWallets } from '../../../constants/types'
import type { AppDispatch } from '../../../store/store.types'
import { setUpWeb3 } from '../../web3/web3Service'
import type { LedgerProviderType } from '../ledgerService'
import { LedgerProvider } from '../ledgerService'

export class LedgerWallet {
  public provider: LedgerProviderType | null = null
  public walletType: SupportedWallets
  public address = ''
  public path = ''

  constructor(path: string, walletType: SupportedWallets) {
    this.path = path
    this.walletType = walletType
  }

  // This method needs to be called on instantiation
  public async connect({ dispatch }: { dispatch: AppDispatch }) {
    await this.setProvider({ dispatch })
    return { web3: await setUpWeb3({ provider: this.provider, isSubprovider: true }), provider: this.provider }
  }

  private async setProvider({ dispatch }: { dispatch: AppDispatch }) {
    const { default: TransportWebHid } = await import('@ledgerhq/hw-transport-webhid')
    const { default: Eth } = await import('@ledgerhq/hw-app-eth')
    let transport: Awaited<ReturnType<typeof TransportWebHid.create>> | null = null
    let timeout: number | null = null
    let timedOut = true
    let rejectAddressCall: (() => void) | null = null
    try {
      transport = await TransportWebHid.create()

      const eth = new Eth(transport)

      // start a timeout to close the transport and display a modal if this hangs. Will happen if the device is busy. Reject the offending promise to allow the app to proceed
      timeout = window.setTimeout(() => {
        if (timedOut) {
          if (transport) {
            transport.close()
          }
          showModal(dispatch)(modalKeys.ledgerIsBusy)
          rejectAddressCall && rejectAddressCall()
        }
      }, 5000)

      const account = await new Promise<Awaited<ReturnType<typeof eth.getAddress>>>((resolve, reject) => {
        rejectAddressCall = reject
        eth.getAddress(this.path).then((ethAccount) => {
          resolve(ethAccount)
        })
      })

      window.clearTimeout(timeout)
      timedOut = false

      this.address = account.address.toLowerCase()
      await transport.close()

      this.provider = new LedgerProvider(this.path, this.address) as unknown as LedgerProviderType
    } catch (error) {
      if (timeout) {
        window.clearTimeout(timeout)
      }
      timedOut = false
      if (transport) {
        await transport.close()
      }
      throw error
    }
  }

  public toJSON() {
    return {
      address: this.address,
      walletType: this.walletType,
      path: this.path,
      name: this.walletType,
    }
  }
}
