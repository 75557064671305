import { dynamicT, translate } from '../intl/i18n'
import type { AppDispatch } from '../store/configureStore'
import {
  addStatusNotification,
  clearStatusNotification,
  updateStatusNotification,
} from './notificationActions/statusNotificationsActions'
import { StatusNotificationStatus } from './types/statusNotifications'

export const addNotification = (dispatch: AppDispatch) => (notification: unknown) => {
  dispatch({
    type: 'ADD_NOTIFICATION',
    payload: {
      notification,
    },
  })
}

export const clearNotification = (dispatch: AppDispatch) => (id: string) => {
  dispatch({
    type: 'CLEAR_NOTIFICATION',
    payload: {
      id,
    },
  })
}

export const clearAllNotifications = (dispatch: AppDispatch) => () => {
  dispatch({
    type: 'CLEAR_ALL_NOTIFICATIONS',
  })
}

let hasQueuedSignature = false

export const showSignPrompt = (show: boolean, type = 'message') => {
  const signId = 'sign-pending'

  if (hasQueuedSignature && show) {
    return {
      isExecuting: true,
    }
  }
  hasQueuedSignature = show
  if (!show) {
    clearStatusNotification(window.store.dispatch)(signId)
    return {}
  }
  // @ts-expect-error TS(2322): Type '{ id: string; }' is not assignable to type '... Remove this comment to see the full error message
  hasQueuedSignature = addStatusNotification(window.store.dispatch)({
    id: signId,
    title: dynamicT(`global.pending_signature_${type}`),
    status: StatusNotificationStatus.pending,
  })
  return { isExecuting: false }
}

const connectId = 'connect-pending'
export const showConnectingWallet = (show: boolean) => {
  if (!show) {
    return clearNotification(window.store.dispatch)(connectId)
  }
  addStatusNotification(window.store.dispatch)({
    id: connectId,
    title: translate('global.notification_connecting_wallet'),
    status: StatusNotificationStatus.pending,
  })
}

export const NOTIFICATION_CONNECT_WALLET_ID = 'connect-wallet'
export const showConnectWalletNotification = (status: StatusNotificationStatus) => {
  let title = ''
  let description = ''

  switch (status) {
    case StatusNotificationStatus.pending:
      title = translate('onboarding.link_your_wallet')
      description = translate('global.signature_pending')
      break

    case StatusNotificationStatus.success:
      title = translate('onboarding.wallet_connected')
      description = translate('onboarding.wallet_connect_success_message')
      break

    case StatusNotificationStatus.error:
      title = translate('onboarding.wallet_connect_reject_title')
      description = translate('onboarding.wallet_connect_reject_message')
      break
  }

  addStatusNotification(window.store.dispatch)({
    id: NOTIFICATION_CONNECT_WALLET_ID,
    status,
    title,
    meta: {
      description,
    },
  })
}

export const showConnectSuccessful = () => {
  updateStatusNotification(window.store.dispatch)(connectId, {
    title: translate('global.notification_connecting_wallet'),
    status: StatusNotificationStatus.success,
  })
}

export const NOTIFICATION_RECOVERY_PENDING_ID = 'recovery-pending'
export const showDTKRecoveryNotification = (status: StatusNotificationStatus) => {
  if (status === StatusNotificationStatus.pending) {
    addStatusNotification(window.store.dispatch)({
      id: NOTIFICATION_RECOVERY_PENDING_ID,
      status: StatusNotificationStatus.pending,
      title: translate('onboarding.access_rhino_account'),
      meta: {
        description: translate('global.signature_pending'),
      },
    })
  } else if (status === StatusNotificationStatus.success) {
    updateStatusNotification(window.store.dispatch)(NOTIFICATION_RECOVERY_PENDING_ID, {
      status: StatusNotificationStatus.success,
    })
  } else {
    updateStatusNotification(window.store.dispatch)(NOTIFICATION_RECOVERY_PENDING_ID, {
      status: StatusNotificationStatus.error,
    })
  }
}
