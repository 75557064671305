import omit from 'lodash/omit'
import merge from 'lodash/merge'
import type { UnknownAction } from 'redux'
import { ADDRESS_CHANGE, WALLET_CONNECTED, WALLET_DISCONNECT } from '../actions/types/user'
import type { WalletConnectedAction, WalletDisconnectAction } from '../actions/userActions/connectWalletActions'
import type { AddressChangeAction } from '../actions/userActions/addressChangeActions'
import type { StatusNotification } from '../actions/types/statusNotifications'
type Notification = {
  id: string
} & (
  | {
      type: 'withdrawal'
      params: {
        amount: string | null
        token: string
        target: string
      }
    }
  | {
      type: 'compensation'
    }
  | {
      type: 'userNotice'
      params: {
        title: string
        message: string
        urgency: string
        onClose?: (() => void) | undefined
      }
    }
  | {
      type: 'error'
      params: {
        title: string
        text: string
        onClick?: () => void
      }
    }
)

type NotificationReducerState = {
  notifications: Notification[]
  feedback?: {
    url: string
    hiddenFields?: Record<string, string>
    onCloseFeedback: () => void
    titleComponent: React.ReactNode
    customHeight: number
  } | null
  statusNotifications: Record<string, StatusNotification>
}

export const getInitialState: () => NotificationReducerState = () => ({
  notifications: [],
  feedback: null,
  statusNotifications: {},
})

type AddStatusNotificationAction = {
  type: 'ADD_STATUS_NOTIFICATION'
  payload: StatusNotification
}

type UpdateStatusNotificationAction = {
  type: 'UPDATE_STATUS_NOTIFICATION'
  payload: {
    id: string
    updatedFields: Partial<StatusNotification>
  }
}

type ClearStatusNotificationAction = {
  type: 'CLEAR_STATUS_NOTIFICATION'
  payload: {
    id: string
  }
}

type AddNotificationAction = {
  type: 'ADD_NOTIFICATION'
  payload: {
    notification: Notification
  }
}

type ClearNotificationAction = {
  type: 'CLEAR_NOTIFICATION'
  payload: {
    id: string
  }
}

type ClearAllNotificationsAction = {
  type: 'CLEAR_ALL_NOTIFICATIONS'
}

export type NotificationAction =
  | AddStatusNotificationAction
  | UpdateStatusNotificationAction
  | ClearStatusNotificationAction
  | AddNotificationAction
  | ClearNotificationAction
  | ClearAllNotificationsAction

type NotificationReducerAction =
  | NotificationAction
  | WalletConnectedAction
  | WalletDisconnectAction
  | AddressChangeAction
// TODO FUD-1098: move notifications to a hook + context combination. Current implementation stores non-serializable entities in redux
export const notificationReducer = (
  state = getInitialState(),
  rawAction: NotificationReducerAction | UnknownAction,
): NotificationReducerState => {
  const action = rawAction as NotificationReducerAction

  switch (action.type) {
    // Clear notification data on wallet change
    case WALLET_CONNECTED:
    case WALLET_DISCONNECT:
    case ADDRESS_CHANGE:
      return {
        ...state,
        notifications: [],
        feedback: null,
      }

    case 'ADD_STATUS_NOTIFICATION': {
      return {
        ...state,
        statusNotifications: {
          ...state.statusNotifications,
          [action.payload.id]: action.payload,
        },
      }
    }

    case 'UPDATE_STATUS_NOTIFICATION': {
      return {
        ...state,
        statusNotifications: {
          ...state.statusNotifications,
          [action.payload.id]: merge({}, state.statusNotifications[action.payload.id], action.payload.updatedFields),
        },
      }
    }

    case 'CLEAR_STATUS_NOTIFICATION': {
      return {
        ...state,
        statusNotifications: omit(state.statusNotifications, [action.payload.id]),
      }
    }

    case 'ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [...state.notifications, action.payload.notification],
      }
    case 'CLEAR_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter((item) => item.id !== action.payload.id),
      }
    case 'CLEAR_ALL_NOTIFICATIONS':
      return {
        ...state,
        notifications: [],
      }
    default: {
      return state
    }
  }
}
