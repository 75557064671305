import type { TransactionReceipt } from 'web3-eth/types'
import type { AppDispatch } from '../../store/configureStore'

export enum TxStatus {
  WAITING_WALLET = 'waiting_wallet',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type TxStatusTitleMapping = Record<TxStatus, string>

export type ChainTxOnTxHashHandlerArgs = { txHash: string }
export type ChainTxOnReceiptHandlerArgs = { txHash: string; receipt: TransactionReceipt }
export type ChainTxOnErrorHandlerArgs = { error: string; txHash?: string; retryFunc: () => void }

export type ChainTxArgsExternal = {
  secondaryConnectedChainKey?: string
  destinationChainKey: string
  onInit: () => void
  onTxHash: (args: ChainTxOnTxHashHandlerArgs) => void
  onReceipt: (args: ChainTxOnReceiptHandlerArgs) => void
  onError: (args: ChainTxOnErrorHandlerArgs) => void
}

export type ChainTxArgs = { dispatch: AppDispatch; currentChainId: number; address: string } & ChainTxArgsExternal

export type ChainTxHashCallbackFunc = (error: string | null, txHash: string) => void

export type ChainExecutionFuncFactory<T> = (
  executionArgs: T,
) => (args: ChainTxArgs, txHashCallback: ChainTxHashCallbackFunc) => Promise<TransactionReceipt>

export type ExistingTxStatus = TxStatus.PENDING | TxStatus.ERROR | TxStatus.SUCCESS
export type TxStatusChangeExistingResult = { txHash: string; txStatus: ExistingTxStatus; chain: string }

export type TxStatusChangeResult = { txStatus: TxStatus.WAITING_WALLET; chain: string } | TxStatusChangeExistingResult
export type TxStatusChangeFunc = (args: TxStatusChangeResult) => void
