import { request } from '@rhino.fi/dvf-utils'
import { tradingApi } from '../../env/config'
import { parseDescription } from '../helperService/parseDescription'

type BaseTokenMeta = {
  token: string
  name: string
  circulatingSupply: string
  high24: number
  low24: number
  marketCap: number
  marketCapRank: number
  totalVolume: number
  priceChange24?: number
  description: string
  iconUrl: string
}

export type TokenMeta = Omit<BaseTokenMeta, 'circulatingSupply' | 'totalVolume' | 'high24' | 'low24'> & {
  dailyVolume: number
  dailyHigh: number
  dailyLow: number
  circulatingSupply: number
}
export type TokensMeta = Record<string, TokenMeta>
type GetTokenMetaResponse = Record<string, BaseTokenMeta>

export const getTokenMeta = async (tokens: string[] = [], locale = 'en', cutDescription = true) => {
  if (!tokens.length) {
    return {}
  }

  try {
    const data = await request.get<GetTokenMetaResponse>(
      `${tradingApi}/v1/trading/chainswap/token-meta?tokens=${tokens.join(',')}&locale=${locale}`,
    )

    return tokens.reduce((acc, token) => {
      if (data[token]) {
        const { circulatingSupply, description, totalVolume, high24, low24, ...meta } = data[token]

        acc[token] = {
          description: parseDescription(description, { onlySanitize: !cutDescription }),
          dailyVolume: totalVolume,
          dailyHigh: high24,
          dailyLow: low24,
          circulatingSupply: +circulatingSupply,
          ...meta,
        }
      } else {
        acc[token] = {
          token,
          name: token,
          description: '',
          iconUrl: '',
          dailyVolume: 0,
          dailyHigh: 0,
          dailyLow: 0,
          circulatingSupply: 0,
          marketCap: 0,
          marketCapRank: 0,
        }
      }

      return acc
    }, {} as TokensMeta)
  } catch (error) {
    return tokens.reduce((acc, token) => {
      acc[token] = {
        token,
        name: token,
        description: '',
        iconUrl: '',
        dailyVolume: 0,
        dailyHigh: 0,
        dailyLow: 0,
        circulatingSupply: 0,
        marketCap: 0,
        marketCapRank: 0,
      }
      return acc
    }, {} as TokensMeta)
  }
}
