import { NETWORKS } from '../../constants/types'
import { getTronTokenAllowance } from '../tronService/getTronTokenAllowance'

export const checkNonEVMDepositAllowance = (
  tokenAddress: string,
  contractAddress: string,
  chain: string,
  nonEVMWalletAddress: string,
) => {
  if (chain === NETWORKS.TRON) {
    return getTronTokenAllowance(tokenAddress, contractAddress, nonEVMWalletAddress)
  } else {
    throw new Error('Allowance check not available for this chain')
  }
}
