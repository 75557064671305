export const shortenEnsName = (name?: string, characters = 9) => {
  if (!name) {
    return ''
  }

  if (name.length <= characters + 3) {
    return name
  }

  return `${name.slice(0, characters)}...`
}
