import _omit from 'lodash/omit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ParsedOpenOrder } from '../services/apiService/getOrders'
import type { ParsedHistOrder } from '../services/apiService'
import { isWalletConnectedAction, isWalletDisconnectAction } from '../actions/userActions/connectWalletActions'
import { isAddressChangeAction } from '../actions/userActions/addressChangeActions'

type OrderState = {
  orderPlacingStatus:
    | string
    | {
        type: string
        text: string
      }
  openOrders: ParsedOpenOrder[]
  histOrders: ParsedHistOrder[]
  awaitingOrders: Record<
    string,
    {
      data?: unknown
      action: string
      hopResolveReject?: {
        resolve: (order: ParsedOpenOrder) => void
        reject: (order: ParsedOpenOrder) => void
      }
    }
  >
  minOrderSize: string | null
  maxOrderSize: string | null
  orderSizeSymbol: string | null
}

export const getInitialState = (): OrderState => ({
  orderPlacingStatus: '',
  openOrders: [],
  histOrders: [],
  awaitingOrders: {},
  minOrderSize: null,
  maxOrderSize: null,
  orderSizeSymbol: null,
})

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: getInitialState(),
  reducers: {
    addAwaitingOrder: (
      state,
      action: PayloadAction<{
        awaitingOrder: { _id: string | number; action: string; data?: unknown }
        hopResolveReject?: {
          resolve: (order: unknown) => void
          reject: (order: unknown) => void
        }
      }>,
    ) => {
      return {
        ...state,
        awaitingOrders: {
          ...state.awaitingOrders,
          [action.payload.awaitingOrder._id]: {
            ...action.payload.awaitingOrder,
            ...(action.payload.hopResolveReject ? { hopResolveReject: action.payload.hopResolveReject } : {}),
          },
        },
      }
    },
    resolveAwaitingOrders: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        awaitingOrders: _omit(state.awaitingOrders, action.payload),
      }
    },
    setOrderPlacingStatus: (
      state,
      action: PayloadAction<
        | string
        | {
            type: string
            text: string
          }
      >,
    ) => {
      state.orderPlacingStatus = action.payload
    },
    setOpenOrders: (state, action: PayloadAction<ParsedOpenOrder[]>) => {
      state.openOrders = action.payload
    },
    setHistOrders: (state, action: PayloadAction<ParsedHistOrder[]>) => {
      state.histOrders = action.payload
    },
    setMinMaxOrderSize: (
      state,
      action: PayloadAction<{
        minOrderSize?: string
        maxOrderSize?: string
        orderSizeSymbol: string
      }>,
    ) => {
      return {
        ...state,
        minOrderSize: action.payload.minOrderSize || null,
        maxOrderSize: action.payload.maxOrderSize || null,
        orderSizeSymbol: action.payload.orderSizeSymbol,
      }
    },
  },
  extraReducers: (builder) => {
    const cleanState = {
      openOrders: [],
      histOrders: [],
      fills: {},
      bookOrder: undefined,
      orderPlacingStatus: '',
      awaitingOrders: {},
      activeTrades: {},
      orderSizeSymbol: null,
    }
    const reset = (state: OrderState) => {
      return {
        ...state,
        ...cleanState,
      }
    }
    builder.addMatcher(isWalletConnectedAction, reset)
    builder.addMatcher(isAddressChangeAction, reset)
    builder.addMatcher(isWalletDisconnectAction, reset)
  },
})
