import { ensLookup, ensReverseLookup, validateENSName } from './ensService'
import { validateZKNSName, zknsLookup, zknsReverseLookup } from './zknsService'

export enum NameServices {
  ENS = 'ENS',
  ZKNS = 'ZKNS',
}

export type NSLookup = (name: string) => Promise<string | false>
export type NSReverseLookup = (address: string) => Promise<string>

// is valid on at least one NS
export const validateNameServicesName = (name: string): boolean => {
  return validateENSName(name) || validateZKNSName(name)
}

export const nameServiceLookup: NSLookup = (nsName: string) => {
  if (validateZKNSName(nsName)) {
    return zknsLookup(nsName)
  }
  if (validateENSName(nsName)) {
    return ensLookup(nsName)
  }
  return new Promise((resolve) => resolve(false))
}

export const nameServiceReverseLookup = (address: string, service: NameServices): Promise<string> => {
  switch (service) {
    case NameServices.ENS:
      return ensReverseLookup(address)
    case NameServices.ZKNS:
      return zknsReverseLookup(address)
    default:
      return new Promise((resolve) => resolve(''))
  }
}
