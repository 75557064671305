import type { UnknownAction } from '@reduxjs/toolkit'
import { getDvf } from '../../services/dvfClient'
import { checkWalletType } from '../../services/wallets/checkWalletType'
import { getProvider } from '../../services/wallets/wallet'
import { providerToEmitter } from '../../services/wallets/walletService'
import type { AppDispatch } from '../../store/configureStore'
import { disconnectWallet } from '../accountPickerActions'
import { ADDRESS_CHANGE } from '../types/user'
import { checkExistingAuthAndKey } from './authenticationActions/checkExistingAuthAndKey'

export const setAddressChangeAction = (payload: { address: string }) =>
  ({
    type: ADDRESS_CHANGE,
    payload,
  }) as const

export type AddressChangeAction = Omit<ReturnType<typeof setAddressChangeAction>, 'type'> & {
  type: typeof ADDRESS_CHANGE
}

export const isAddressChangeAction = (action: UnknownAction): action is AddressChangeAction =>
  action.type === ADDRESS_CHANGE

type Listener = {
  event: 'accountsChanged'
  callback: (...args: unknown[]) => void
}

export const activeAccountsChangedListeners: Array<Listener> = []

export const listenForAddressChange = (dispatch: AppDispatch) => () => {
  try {
    const provider = providerToEmitter(getProvider())
    if (provider === null) {
      return
    }

    activeAccountsChangedListeners.forEach((listener) => {
      provider.removeListener(listener.event, listener.callback)
    })
    // clear the array
    activeAccountsChangedListeners.length = 0

    const newListener: Listener = {
      event: 'accountsChanged',
      callback: async (arg) => {
        if (!Array.isArray(arg) || !arg.length || typeof arg[0] !== 'string') {
          return
        }
        const address = arg[0]
        const {
          user: { wallet },
        } = window.store.getState()
        const { data } = wallet
        if (address?.toLowerCase() === data?.address?.toLowerCase()) {
          return
        }
        try {
          await checkWalletType(address, data?.walletType)
        } catch (error) {
          disconnectWallet(dispatch)()
        }
        const dvf = await getDvf()
        dispatch(
          setAddressChangeAction({
            address,
          }),
        )
        dvf.set('account', address)
        await checkExistingAuthAndKey(dispatch)({ ...wallet, address: address })
      },
    }
    if (newListener.event === 'accountsChanged') {
      provider.on(newListener.event, newListener.callback)
    }
    activeAccountsChangedListeners.push(newListener)
  } catch (error) {}
}
