import { platform } from './portalConfig'

export const ccyNames: Record<string, string> = {
  BLUR: 'Blur',
  ETHBTCTREND: 'ETH-BTC Trend',
  ETHBTCMOMENTUM: 'ETH-BTC Momentum',
  BTC: 'Bitcoin (wBTC)',
  USD: 'Tether',
  USDT: 'Tether',
  ETH: 'Ethereum',
  REP: 'Augur',
  SNG: 'SingularDTV',
  RCN: 'Ripio Credit Network',
  GNT: 'Golem',
  MKR: 'Maker',
  RLC: 'iExec RLC',
  ANT: 'Aragon',
  BAT: 'Basic Attention Token',
  CFI: 'Cofound.it',
  KNC: 'Kyber Network',
  FUN: 'FunFair',
  SNT: 'Status',
  STJ: 'Storj',
  OMG: 'OmiseGO',
  ZRX: '0x',
  YYW: 'YOYOW',
  VEN: 'VeChain',
  LRC: 'Loopring',
  AVT: 'Aventus',
  TRX: 'TRON',
  MNA: 'Decentraland',
  EDO: 'Eidoo',
  AIO: 'Aion',
  REQ: 'Request Network',
  RDN: 'Raiden Network Token',
  QSH: 'QASH',
  VEE: 'BLOCKv',
  TNB: 'Time New Bank',
  DAT: 'Datum',
  ELF: 'aelf',
  WAX: 'WAX',
  DAI: 'Multi collateral DAI',
  SAI: 'Single collateral DAI',
  UTK: 'UTRUST',
  IOS: 'IOST',
  AGI: 'SingularityNET',
  SPK: 'Spankchain',
  AID: 'AidCoin',
  POY: 'Polymath',
  MTN: 'Medicalchain',
  DTH: 'Dether',
  FSN: 'Fusion',
  NIO: 'Autonio',
  NEC: 'Nectar',
  POA: 'POA Network',
  LYM: 'Lympo',
  BFT: 'BnkToTheFuture',
  ODE: 'ODEM',
  CBT: 'CommerceBlock',
  EVT: 'Ethfinex Voting Token',
  ORS: 'ORS',
  ENJ: 'Enjin',
  MLN: 'Enzyme',
  MGO: 'MobileGo',
  UDC: 'USD Coin',
  TSD: 'TrueUSD',
  PAS: 'BlockPass',
  PNK: 'Kleros',
  DGX: 'Digix',
  WBT: 'Wrapped Bitcoin',
  SCR: 'Data Transaction Token',
  BNB: 'Binance Coin',
  DUSK: 'Dusk Network',
  AMP: 'Ampleforth',
  SNX: 'Synthetix',
  XAUT: 'Tether Gold',
  LEND: 'ETHLend',
  COMP: 'Compound',
  LINK: 'Chainlink',
  DPI: 'DeFiPulse Index',
  HEZ: 'Hermez Network',
  AAVE: 'Aave',
  BAL: 'Balancer',
  UNI: 'Uniswap',
  YFI: 'yearn.finance',
  SUSHI: 'Sushi',
  CUSDT: 'Compound USDT',
  CRV: 'Curve',
  LDO: 'Lido',
  UMA: 'UMA',
  SRM: 'Serum',
  SUSD: 'Synth sUSD',
  BNT: 'Bancor',
  STAKE: 'xDAI Stake',
  BAND: 'Band',
  SETH: 'Synth sETH',
  REN: 'Republic',
  TKN: 'Monolith',
  IDEX: 'Idex',
  AUC: 'Auctus',
  DXD: 'DXdao',
  HOT: 'Hydro',
  NXM: 'Wrapped NXM',
  BZRX: 'bZx',
  RENBTC: 'renBTC',
  SBTC: 'Synth sBTC',
  SAN: 'Santiment',
  CEL: 'Celsius',
  CETH: 'Compound ETH',
  LEO: 'Bitfinex Leo',
  HEGIC: 'Hegic',
  KP3R: 'Keep3r',
  NU: 'Nucypher',
  NEXO: 'NEXO',
  BADGER: 'Badger DAO',
  TORN: 'Tornado Cash',
  '1INCH': '1inch',
  GRT: 'The Graph',
  RGT: 'Rari',
  ROOK: 'KeeperDAO',
  SDT: 'Stake DAO',
  RUNE: 'THORChain',
  ALN: 'Aluna',
  DVF: 'rhino.fi token',
  USDC: 'USD Coin',
  ERP: 'Entropyfi',
  YGG: 'Yield Guild Games',
  ILV: 'Illuvium',
  MPL: 'Maple',
  TOKE: 'Tokemak',
  BOND: 'BarnBridge',
  RAI: 'Rai Reflex Index',
  INV: 'Inverse Finance',
  EXRD: 'e-Radix',
  SHIB: 'Shiba Inu',
  AUST: 'Anchor UST',
  STMATIC: 'Lido Staked Matic',
  WSTETH: 'Wrapped Staked ETH',
  CDETI: 'Index Coop CoinDesk ETH Trend Index',
  mooStargateUSDT: 'USDT LP Vault Stargate',
  mooStargateUSDC: 'USDC LP Vault Stargate',
  'mooRamsesMAI-USDC': 'MAI-​USDC sLP vault',
  YVUSDT: 'USDT yVault',
  STETH: 'Staked ETH',
  APE: 'ApeCoin',
  SPELL: 'Spell Token',
  DOGE: 'Dogecoin',
  PSP: 'ParaSwap',
  MANA: 'Decentraland',
  xDVF: 'Staked rhino.fi Token',
  ICETH: 'Interest Compounding ETH',
  DSETH: 'Diversified Staked ETH',
  RBN: 'Ribbon Finance',
  MATIC: 'Polygon',
  YIELDUSD: 'Real Yield USD',
  SOMM: 'Sommelier',
  YIELDETH: 'Real Yield ETH',
  YIELDBTC: 'Real Yield BTC',
  TBILL: 'US Treasury Bill',
}

export const ccySymbols: Record<string, string> = {
  AMP: 'AMPL',
  DSK: 'DUSK',
  DAD: 'DADI',
  IOS: 'IOST',
  QSH: 'QASH',
  YYW: 'YOYOW',
  MNA: 'MANA',
  ORS: 'ORST',
  POY: 'POLY',
  AIO: 'AION',
  SNG: 'SNGLS',
  STJ: 'STORJ',
  SPK: 'SPANK',
  UDC: 'USDC',
  TSD: 'TUSD',
  PAS: 'PASS',
  USD: 'USDT',
  UST: 'USDT',
  WBT: 'WBTC',
  SCR: 'XD',
  CUSDT: 'cUSDT',
  WSTETH: 'wstETH',
  CDETI: 'cdETI',
  YVUSDT: 'yvUDT',
  STETH: 'stETH',
  ICETH: 'icETH',
  DSETH: 'dsETH',
}

export const ccyNiceSymbols: Record<string, string> = {
  DADI: 'DAD',
  IOST: 'IOS',
  QASH: 'QSH',
  YOYOW: 'YYW',
  MANA: 'MNA',
  ORST: 'ORS',
  POLY: 'POY',
  AION: 'AIO',
  SNGLS: 'SNG',
  STORJ: 'STJ',
  SPANK: 'SPK',
  PASS: 'PAS',
  TUSD: 'TSD',
  USDC: 'UDC',
  WBTC: 'WBT',
  XD: 'SCR',
  AMPL: 'AMP',
  DUSK: 'DSK',
  CUSDT: 'cUSDT',
  DVF: 'DVF',
  YVUSDT: 'yvUSDT',
  ICETH: 'icETH',
}

export function getCcyDetail(ccy = platform.DEFAULT_CCY, field: 'id' | 'name' | 'label' | 'symbol' | 'nice') {
  switch (field) {
    case 'id': {
      return ccy
    }
    case 'name':
    case 'label': {
      return ccyNames[ccy] || ccy
    }
    case 'symbol': {
      return ccySymbols[ccy] || ccy
    }
    case 'nice': {
      return ccyNiceSymbols[ccy] || ccy
    }
    default: {
      return ccy
    }
  }
}
