import { getAuthenticationData } from '../apiService'
import { getDvf } from '../dvfClient'
import { makeUrlFor } from './v3ApiUrls'

export const getSignatureAddress = async ({ useTradingKeyForAuth }: { useTradingKeyForAuth?: boolean } = {}) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf, { useTradingKey: useTradingKeyForAuth })
  const { signatureAddress } = await dvf.getAuthenticated<{ signatureAddress: string }>(
    makeUrlFor.signatureAddress(),
    nonce,
    signature,
  )
  dvf.set('signatureAddress', signatureAddress)

  return signatureAddress
}
