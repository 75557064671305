export const POLLING_INTERVALS = {
  CONFIG: 90000,
  BLOCKCHAIN_BALANCE: 30000,
  EXCHANGE_BALANCE: 15000,
  TOKEN_PRICES: 60000,
  WITHDRAWALS: 60000,
  TOKEN_PAGE: 30000,
}

export const WEI_MAX_LIMIT = 500000000000

const platforms = {
  deversifi: {
    Name: 'rhino.fi',
    DEFAULT_PAIR: 'ETH:USDT',
    DEFAULT_CCY: 'ETH',
  },
}
const currentPlatformID = 'deversifi'

export const platform = platforms[currentPlatformID] || {}
