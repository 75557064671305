import { noop } from 'lodash'
import { getDvf } from '../dvfClient'
import type { getAuthenticationData } from '../apiService'
import type { AppDispatch } from '../../store/store.types'
import { prepareConfig } from '../../actions/commonActions'
import { applicationSlice } from '../../reducers/applicationSlice'
import { portalSlice } from '../../reducers/portalSlice'
import type { DVFConfig } from './getConfig.types'

class ConfigFetcher {
  lastFetchPromises: Promise<unknown>[] = []

  public getConfig(args: {
    authConfig?: Parameters<typeof getAuthenticationData>[1]
    dispatch: AppDispatch
    isAuthenticated?: boolean
    isFromInterval?: boolean
  }): Promise<DVFConfig | null>

  public async getConfig({
    authConfig,
    dispatch,
    isAuthenticated,
    isFromInterval,
  }: {
    authConfig?: Parameters<typeof getAuthenticationData>[1]
    dispatch: AppDispatch
    isAuthenticated?: boolean
    isFromInterval?: boolean
  }) {
    // Wait for previous fetch to finish before starting a new one to avoid any race conditions.
    // Dvf client stores the config internally, so we can't just ingnore the result and need to override it.
    // To resolve we create a promise at the start of fetching, and store it in an array, resolving and removing once the fetch is done.
    // If new fetches are started in the meantime, they will add their own promise and await for all other promises to resolve before starting.
    // This means that calling this function multipe times quickly will always wait for the previous calls to finish, so no race conditions will occur.
    // Additionally, if fetch comes from an interval source and there are other ongoing calls the interval fetch is ignored
    if (isFromInterval && this.lastFetchPromises.length > 0) {
      return null
    }
    let resolveFetchPromise = noop
    let rejectFetchPromise = noop
    const fetchPromise = new Promise((resolve, reject) => {
      resolveFetchPromise = resolve
      rejectFetchPromise = reject
    })
    const lastPromisesWithoutSelf = [...this.lastFetchPromises]
    this.lastFetchPromises.push(fetchPromise)

    if (lastPromisesWithoutSelf.length > 0) {
      await Promise.allSettled(lastPromisesWithoutSelf)
    }

    let result: DVFConfig
    try {
      const dvf = await getDvf()
      result = await dvf.getConfig()

      dispatch(portalSlice.actions.setConfig(prepareConfig(result)))
      resolveFetchPromise()
      const index = this.lastFetchPromises.indexOf(fetchPromise)
      this.lastFetchPromises.splice(index, 1)

      return result
    } catch (error) {
      dispatch(applicationSlice.actions.setApplicationLoadingState(false))
      rejectFetchPromise()
      throw error
    }
  }
}

export const configFetcher = new ConfigFetcher()
