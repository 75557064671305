import * as eth from '../../services/ethereumService'
import { getProvider } from '../../services/wallets/wallet'
import type { AppDispatch } from '../../store/configureStore'
import { providerToEmitter } from '../../services/wallets/walletService'
import { userSlice } from '../../reducers/userSlice'

export const setNetworkId = (dispatch: AppDispatch) => async () => {
  try {
    const network = await eth.getNetworkId()
    dispatch(userSlice.actions.setNetwork({ network }))
  } catch (error) {}
}

export const listenForNetworkChange = (dispatch: AppDispatch) => () => {
  try {
    const provider = providerToEmitter(getProvider())
    provider?.on('chainChanged', async (networkIdHex) => {
      dispatch(userSlice.actions.setNetwork({ network: parseInt(networkIdHex) }))
    })
  } catch (error) {}
}
