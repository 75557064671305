import type { TransactionReceipt } from 'web3-eth/types'
import { isNil } from 'lodash'
import type Web3 from 'web3'
import { setEthereumWeb3, setSideChainWeb3 } from '../web3/web3Service'
import { evmSideChainProviders } from '../ethereum/chainProviders'
import { poll } from './poll'

const TRANSACTION_TIMEOUT_MS = 180000
const RECEIPT_POLLING_INTERVAL_MS = 10000

const poller = poll<TransactionReceipt>(TRANSACTION_TIMEOUT_MS, RECEIPT_POLLING_INTERVAL_MS, 'TRANSACTION_RECEIPT')

const txReceipt = (txHash: string, web3: Web3) => () =>
  web3.eth
    .getTransactionReceipt(txHash)
    // In case status is nil, keep polling
    .then((receipt) => (isNil(receipt?.status) ? undefined : receipt))

export const pollTxReceiptFromOurProvider = async (txHash: string, chain: string) => {
  const web3 = chain === 'ETHEREUM' ? await setEthereumWeb3() : await setSideChainWeb3(evmSideChainProviders[chain])
  return poller(txReceipt(txHash, web3))
}
