import { CallData } from 'starknet'
import { getPublicStarknetProvider } from '../../../../../services/secondaryWallet/providers/starknetProvider'
import { envConfig } from '../../../../../env/envConfig'
import { NETWORKS } from '../../../../../constants/types'
import type { NFTMinter } from './NFTMinterAssets'
import { getNFTMetaData } from './getNFTMetaData'

const { contractAddress, tokenIds } = envConfig.starknetNFTs

export const getStarknetNFTs = async (address: string): Promise<NFTMinter[]> => {
  if (!address || !contractAddress) {
    return []
  }
  const provider = getPublicStarknetProvider()

  const callResult: { result: Array<string> } = await provider.callContract({
    contractAddress,
    entrypoint: 'get_minted_nft',
    calldata: CallData.compile([address]),
  })

  if (!callResult?.result?.length) {
    return []
  }

  // Result mapping:
  // 0x1 -> last minted nft was 1
  // 0x2 -> last minted nft was 2
  // 0x3 -> already both 1 and 2 were minted
  const result = Number(callResult.result[0])
  return [
    ...(result === 1 || result === 3 ? [getNFTMetaData('EXPLORER', NETWORKS.STARKNET, tokenIds.EXPLORER)] : []),
    ...(result === 2 || result === 3 ? [getNFTMetaData('GOD', NETWORKS.STARKNET, tokenIds.GOD)] : []),
  ]
}
