import {
  ACTIVE_DTK_VERSION,
  attachTradingKeyToDvf,
  createDTK,
} from '../../services/tradingKeyService/webWalletTradingKey'
import { createWalletMetadata } from '../../services/wallets/walletService'
import { trackHeapEvent } from '../../services/apiService'
import { showModal } from '../modalActions/showModal'
import { userflowEvents } from '../../constants/userflowEvents'
import type { Wallet, WalletPayload } from '../../services/wallets/wallet'
import { APP_WALLETS, WALLETS } from '../../constants/types'
import type { AppDispatch } from '../../store/configureStore'
import { parsePrivateKey } from '../../services/wallets/legacyService'
import { heapEvents, heapStatuses } from '../../constants/heapEvents'
import { translate } from '../../intl/i18n'
import { trackUserLoginEvent } from '../../services/tracking/tracking'
import { modalKeys } from '../../constants/modalKeys'

import { register } from '../../services/apiService/register'
import { getUser } from '../../services/v3Api/getUser'
import { getSignatureAddress } from '../../services/v3Api/getSignatureAddress'
import { userSlice } from '../../reducers/userSlice'
import { portalSlice } from '../../reducers/portalSlice'
import type { SavedCampaignData } from '../../services/helperService/checkRequestSource'
import { trackUserflowEvent } from '../../services/apiService/trackUserflowEvent'

export const registerFeatureFlagAbTesting = (dispatch: AppDispatch) => async (data: NonNullable<WalletPayload>) => {
  return register(dispatch)(data)
}

export const registerUser = (dispatch: AppDispatch) => async (wallet: Wallet | null) => {
  try {
    if (!wallet) {
      throw new Error(translate('global.connect_wallet_to_continue'))
    }
    const { address, walletType, path, privateKey, name } = wallet

    const campaign = JSON.parse<SavedCampaignData | null>(localStorage.getItem('url-campaign') || 'null')
    const referer = localStorage.getItem('referer')
    let tradingKey = null

    if (!APP_WALLETS.includes(walletType)) {
      tradingKey = await createDTK(address, walletType)
    }
    if (walletType === WALLETS.KEYSTORE) {
      if (!privateKey) {
        return
      }
      tradingKey = { privateKey: parsePrivateKey(privateKey, false) }
    }

    if (tradingKey?.privateKey) {
      await attachTradingKeyToDvf(tradingKey.privateKey)
    }

    const meta = createWalletMetadata(
      {
        address: address,
        campaign,
        referer,
        isLegacy: false,
        name,
        ...(tradingKey && { tradingKey }),
        ...(path && { path }),
        ...(privateKey && { privateKey }),
      },
      walletType,
    )

    // TODO FUD-1346: register API requires starkPublicKey but its not given here?
    // @ts-expect-error -- TODO above
    await registerFeatureFlagAbTesting(dispatch)(meta)

    const signatureAddress = await getSignatureAddress()
    dispatch(portalSlice.actions.setUserSignatureAddress({ signatureAddress }))

    const { isRegistered, starkKeyHex } = await getUser(signatureAddress)

    if (isRegistered) {
      localStorage.removeItem('referer')
      localStorage.removeItem('campaign')
    }

    dispatch(userSlice.actions.setRegistered({ isRegistered, starkPublicKey: starkKeyHex || '' }))

    if (tradingKey?.privateKey) {
      dispatch(
        userSlice.actions.setTradingKey({
          privateKey: tradingKey.privateKey,
          version: ACTIVE_DTK_VERSION,
        }),
      )
    }

    showModal(dispatch)(modalKeys.newUserEmail)
    trackUserflowEvent(userflowEvents.accountCreation)
    trackUserflowEvent(userflowEvents.unlockWallet)
    trackHeapEvent(heapEvents.unlockWallet, {
      status: heapStatuses.success,
    })
    trackUserLoginEvent(wallet)
  } catch (error) {
    console.error(error)
    trackHeapEvent(heapEvents.unlockWallet, {
      status: heapStatuses.fail,
    })
    throw error
  }
}
