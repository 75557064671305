import { setDvf } from '../services/dvfClient'
import type { SupportedWallets } from '../constants/types'
import type { AppDispatch } from '../store/configureStore'
import { getProvider, initDefaultWeb3 } from '../services/wallets/wallet'
import { isCoinbase, isWalletConnect, providerToEmitter } from '../services/wallets/walletService'
import { disconnectStarknetWallet } from '../services/starknetService/disconnectStarknetWallet'
import { disconnectTronWallet } from '../services/tronService/disconnectTronWallet'
import { portalSlice } from '../reducers/portalSlice'
import { fetchExchangeBalances } from './balanceActions'
import { fetchOrders, fetchOrdersHist } from './orderActions'
import { walletDisconnectAction } from './userActions/connectWalletActions'
import { setNameServiceNames } from './userActions/nameServiceActions'
import { activeAccountsChangedListeners } from './userActions/addressChangeActions'

export const fetchData = (dispatch: AppDispatch) => (address: string) => {
  fetchExchangeBalances(dispatch)()
  fetchOrders(dispatch)()
  setNameServiceNames(dispatch)(address)
  fetchOrdersHist(dispatch)()
}

export const disconnectWallet = (dispatch: AppDispatch) => async (walletType?: SupportedWallets) => {
  const provider = getProvider()

  if (isWalletConnect(provider, walletType)) {
    provider.disconnect()
  } else if (isCoinbase(provider, walletType)) {
    provider.close()
  }
  // if there are listeners on accountsChanged = try to remove them
  try {
    const emitter = providerToEmitter(provider)
    if (emitter) {
      activeAccountsChangedListeners.forEach((listener) => {
        emitter.removeListener(listener.event, listener.callback)
      })
    }
  } catch {}

  dispatch(walletDisconnectAction())
  const web3 = await initDefaultWeb3()
  setDvf({ web3 })

  disconnectStarknetWallet(dispatch)
  disconnectTronWallet()
}

export const listenForDisconnect = (dispatch: AppDispatch) => (walletType?: SupportedWallets) => {
  const provider = providerToEmitter(getProvider())
  provider?.on('disconnect', (error) => {
    // Metamask re-establishing connection, skip disconnection
    if (error?.code === 1013) {
      return
    }
    disconnectWallet(dispatch)(walletType)
  })
}

export const requireChain = (dispatch: AppDispatch) => async (chainName: string | null) => {
  return new Promise<{ done: boolean; reason?: string }>((resolve, reject) => {
    dispatch(
      portalSlice.actions.setRequiredChain({
        requiredChain: {
          chainName,
          resolve,
          reject,
        },
      }),
    )
  })
}
