// Failed events
export const LOGIN_ERROR = 'Login failed'
export const LOGIN_ERROR_APPROVAL = 'User denied wallet connection approval'
export const LOGIN_ERROR_WALLET_TYPE = 'Wallet does not match wallet type'

export const LEDGER_LIST_ACCOUNTS = 'Could not list Ledger accounts'
export const SIGN_MESSAGE = 'User failed to sign the message'
export const SIGN_TRANSACTION = 'User refused to sign the transaction'
export const INVALID_KEYSTORE_TYPE = 'Keystore type did not match any of the expected types'
export const INVALID_KEYSTORE_PASSWORD = 'Invalid keystore file password'

export const DTK_DECRYPT_ERROR = 'Failed to decrypt DTK'
export const DTK_RECOVER_ERROR = 'Failed to recover DTK'
export const DTK_CREATION_ERROR = 'Failed to create DTK'

export const DEPOSIT_APPROVAL = 'User did not approve the spend a token'
export const DEPOSIT_CANCELED = 'User canceled the deposit'
export const DEPOSIT_FAILED = 'Deposit failed'

export const ORDER_CANCELED = 'User canceled the order'
export const ORDER_SIGNED = 'User signed the order'
export const FAST_WITHDRAWAL_CANCELED = 'User canceled fast withdrawal'

// Successfull events
export const LOGIN_SUCCESS = 'User successfully connected wallet'
export const DEPOSIT_SUCCESS = 'User successfully deposited'
export const ORDER_SUBMITED_SUCCESS = 'User successfully submitted order'
