export const parseDescription = (
  description?: string,
  { characters = 370, onlySanitize = false }: { characters?: number; onlySanitize?: boolean } = {},
) => {
  const sanitized = (description || '').replaceAll(/<[^>]*>/gi, '')

  if (onlySanitize || sanitized.length < characters) {
    return sanitized
  }

  return `${sanitized.substring(0, characters)}...`
}
