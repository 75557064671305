import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import type { RootState } from '../store/configureStore'
import { settingsSlice } from '../reducers/settingsSlice/settingsSlice'

export const settingsListenerMiddleware = createListenerMiddleware<RootState>()
export const SETTINGS_LOCAL_STORAGE_KEY = 'rhino_settings'
const settingsActionsMatchers = Object.values(settingsSlice.actions).map((action) => action.match)

settingsListenerMiddleware.startListening({
  matcher: isAnyOf(...settingsActionsMatchers),
  effect: (action, listenerApi) => {
    const state = listenerApi.getState()
    window.localStorage.setItem(SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(state.settings))
  },
})
