import ENS from 'ethjs-ens'
import { envConfig } from '../../env/envConfig'
import { getWeb3 } from '../wallets/wallet'
import type { NSLookup, NSReverseLookup } from './nameServices'
const { network } = envConfig

export const getEns = () => {
  const web3 = getWeb3()
  return new ENS({ provider: web3.ETHEREUM.currentProvider, network })
}

export const ensReverseLookup: NSReverseLookup = (address: string) =>
  new Promise((resolve) => {
    if ([1, 3, 5].indexOf(network) === -1) {
      return resolve('')
    }
    const _ens = getEns()
    _ens
      .reverse(address)
      .then((res) => {
        resolve(res)
      })
      .catch(() => {
        resolve('')
      })
  })

export const ensLookup: NSLookup = (name: string) =>
  new Promise<false | string>((resolve) => {
    if ([1, 3, 5].indexOf(network) === -1) {
      return resolve(false)
    }
    const _ens = getEns()
    _ens
      .lookup(name)
      .then((res) => {
        resolve(res)
      })
      .catch(() => {
        resolve(false)
      })
  })

export const validateENSName = (name: string): boolean => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/
  return regex.test(name)
}
