import type { ECDSASignature } from 'ethereumjs-util'
import { fromRpcSig, toRpcSig } from 'ethereumjs-util'
import type { SupportedWallets } from '../constants/types'
import { WALLETS } from '../constants/types'
import type { LedgerJSONData } from './dvfClient'
import { getDvf } from './dvfClient'
import type { EIP712Domain } from './eip712Service.types'
import type { Wallet } from './wallets/wallet'
import { getProvider } from './wallets/wallet'

type EIP712SignatureProps = {
  wallet: Wallet
  domain: EIP712Domain
  message: Record<string, unknown>
}

type SignEIP712MessageProps = {
  types: Record<string, Array<{ type: string; name: string }>>
  primaryType: string
} & EIP712SignatureProps

const EIP712_DOMAIN_TYPE = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
]

// Without chainId
const XCHAIN_SWAP_EIP712_DOMAIN_TYPE = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'verifyingContract', type: 'address' },
]

const EIP2612_TYPE = [
  { name: 'owner', type: 'address' },
  { name: 'spender', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
]

const DVF_CROSSCHAIN_SWAP_TYPE = [
  { name: 'user', type: 'address' },
  { name: 'swapGateway', type: 'bytes32' },
  { name: 'tokenFrom', type: 'address' },
  { name: 'tokenTo', type: 'address' },
  { name: 'amountFrom', type: 'uint256' },
  { name: 'minAmountTo', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
  { name: 'chainId', type: 'uint256' },
]

// Swap v2
const DVF_CROSSCHAIN_SWAPV2_TYPE = [
  { name: 'user', type: 'address' },
  { name: 'swapGateway', type: 'bytes32' },
  { name: 'tokensFrom', type: 'address[]' },
  { name: 'tokensTo', type: 'address[]' },
  { name: 'amountsFrom', type: 'uint256[]' },
  { name: 'minAmountsTo', type: 'uint256[]' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
  { name: 'chainId', type: 'uint256' },
]

const DVF_CROSSCHAIN_WITHDRAW_TYPE = [
  { name: 'user', type: 'address' },
  { name: 'token', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'amount', type: 'uint256' },
  { name: 'maxFee', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
  { name: 'chainId', type: 'uint256' },
]

const maxUint256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const signPermit = async ({
  wallet,
  chainId,
  spender,
  tokenAddress,
  name,
  nonce,
  version,
}: {
  wallet: {
    address: string
    walletType: SupportedWallets
    path?: string
  }
  chainId: number
  spender: string
  tokenAddress: string
  name: string
  nonce: string
  version: string
}) => {
  const permitValue = maxUint256
  const deadline = Math.floor(new Date().getTime() / 1000) + 24 * 3600
  const walletAddress = wallet.address.toLowerCase()
  const walletType = wallet.walletType
  const message = {
    owner: walletAddress,
    spender,
    value: permitValue.toString(),
    nonce,
    deadline,
  }
  const domain = {
    name,
    version,
    verifyingContract: tokenAddress,
    chainId,
  }
  const jsonData = {
    types: {
      EIP712Domain: EIP712_DOMAIN_TYPE,
      Permit: EIP2612_TYPE,
    },
    domain,
    primaryType: 'Permit',
    message,
  }

  let signature: ECDSASignature
  if (wallet.walletType === WALLETS.LEDGER) {
    if (!wallet.path) {
      throw new Error('Path required for Ledger signing')
    }
    signature = await ledgerSignEIP712(jsonData, wallet.path)
  } else {
    signature = await browserWalletSignEIP712AndFromRpcSig(jsonData, walletAddress, walletType)
  }

  return {
    deadline,
    permitValue: permitValue,
    ...signature,
  }
}

export const signCrossChainSwap = async ({ wallet, domain, message }: EIP712SignatureProps) => {
  return signEIP712Message({
    types: {
      EIP712Domain: XCHAIN_SWAP_EIP712_DOMAIN_TYPE,
      Swap: DVF_CROSSCHAIN_SWAP_TYPE,
    },
    primaryType: 'Swap',
    domain,
    message,
    wallet,
  })
}

// Swap v2 - used for x-chain yield
export const signCrossChainTransaction = async ({ wallet, domain, message }: EIP712SignatureProps) => {
  return signEIP712Message({
    types: {
      EIP712Domain: XCHAIN_SWAP_EIP712_DOMAIN_TYPE,
      SwapV2: DVF_CROSSCHAIN_SWAPV2_TYPE,
    },
    primaryType: 'SwapV2',
    domain,
    message,
    wallet,
  })
}

export const signCrossChainWithdraw = async ({ wallet, domain, message }: EIP712SignatureProps) => {
  return signEIP712Message({
    types: {
      EIP712Domain: XCHAIN_SWAP_EIP712_DOMAIN_TYPE,
      Withdraw: DVF_CROSSCHAIN_WITHDRAW_TYPE,
    },
    primaryType: 'Withdraw',
    domain,
    message,
    wallet,
  })
}

const signEIP712Message = async ({ types, primaryType, domain, message, wallet }: SignEIP712MessageProps) => {
  const jsonData = { types, domain, primaryType, message }
  if (wallet.walletType === WALLETS.LEDGER) {
    if (!wallet.path) {
      throw new Error('Path required for Ledger signing')
    }

    return await ledgerSignEIP712AndHash(jsonData, wallet.path)
  } else {
    return await browserWalletSignEIP712(jsonData, wallet.address.toLowerCase(), wallet.walletType)
  }
}

const browserWalletSignEIP712AndFromRpcSig = async (
  jsonData: LedgerJSONData,
  walletAddress: string,
  walletType: SupportedWallets,
) => {
  const hashedSignature = await browserWalletSignEIP712(jsonData, walletAddress, walletType)
  return fromRpcSig(hashedSignature as string)
}

const ledgerSignEIP712AndHash = async (jsonData: LedgerJSONData, ledgerPath: string) => {
  const signatureRsv = await ledgerSignEIP712(jsonData, ledgerPath)
  return toRpcSig(signatureRsv.v, signatureRsv.r, signatureRsv.s)
}

const browserWalletSignEIP712 = async (
  jsonData: LedgerJSONData,
  walletAddress: string,
  walletType: SupportedWallets,
) => {
  const data = JSON.stringify(jsonData)
  const provider = getProvider()
  const hashedSignature = await provider.request({
    method:
      walletType === WALLETS.METAMASK ||
      walletType === WALLETS.OKX ||
      walletType === WALLETS.WALLET_CONNECT ||
      walletType === WALLETS.WEBWALLET
        ? 'eth_signTypedData_v4'
        : 'eth_signTypedData',
    params: [walletAddress, data],
  })
  return hashedSignature
}

const ledgerSignEIP712 = async (jsonData: LedgerJSONData, ledgerPath: string) => {
  const dvf = await getDvf()
  return dvf.ledger.signEIP712Data(jsonData, ledgerPath)
}
