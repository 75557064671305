import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../env/envConfig'
import { reportMessageToSentry } from './helperService/reportToSentry'

export const statsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.tradingApi}/v3`,
  }),
  endpoints: (builder) => ({
    getMetabaseIFrameUrl: builder.query<string, { isDarkTheme: boolean }>({
      query: () => ({
        url: '/statsMetabaseIFrameUrl',
      }),
      transformResponse: async (response: string) => {
        return response.replaceAll('"', '')
      },
      transformErrorResponse: (response) => {
        reportMessageToSentry('Stats error', { error: response })
        return response
      },
      keepUnusedDataFor: 10 * 60, // 10 minute expiration
    }),
  }),
})

export const { useGetMetabaseIFrameUrlQuery } = statsApi
