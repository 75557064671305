import type { RootState } from '../../store/configureStore'

export const selectCrossChainSwapConfig = (state: RootState) => state.xchain.crossChainSwapConfig
export const selectCrossChainTokensConfigPerChain = (state: RootState) => state.xchain.crossChainTokensConfigPerChain
export const selectCrossChainYieldRegistry = (state: RootState) => state.xchain.crossChainYieldRegistry
export const selectCrossChainTokenConfig = (state: RootState) => state.xchain.crossChainTokenConfig
export const selectCrossChainYieldConfig = (state: RootState) => state.xchain.crossChainYieldConfig
export const selectCrossChainTokensMeta = (state: RootState) => state.xchain.crossChainTokensMeta
export const selectCrossChainYield = (state: RootState) => state.xchain.crossChainYield
export const selectCrossChainTokenRegistry = (state: RootState) => state.xchain.crossChainTokenRegistry
