import { checkAuthValidity, recoverTradingKey } from '../../../services/apiService'
import type { Wallet } from '../../../services/wallets/wallet'
import { APP_WALLETS, WALLETS } from '../../../constants/types'
import { attachTradingKeyToDvf, getDTKOnly } from '../../../services/tradingKeyService/webWalletTradingKey'
import type { AppDispatch } from '../../../store/configureStore'
import { parsePrivateKey } from '../../../services/wallets/legacyService'
import { fetchData } from '../../accountPickerActions'
import { registerUser } from '../registerActions'
import { getUser } from '../../../services/v3Api/getUser'
import { getDvf } from '../../../services/dvfClient'
import { getSignatureAddress } from '../../../services/v3Api/getSignatureAddress'
import { userSlice } from '../../../reducers/userSlice'
import { portalSlice } from '../../../reducers/portalSlice'

export const checkExistingAuthAndKey = (dispatch: AppDispatch) => async (wallet: Wallet) => {
  try {
    const { address, walletType, privateKey, isLegacy } = wallet
    dispatch(userSlice.actions.setAuthenticating(true))
    const { privateKey: starkPrivateKey } = getDTKOnly(address)
    const isAuthValid = (await checkAuthValidity(address)) || !!starkPrivateKey
    const hasStarkKey = !APP_WALLETS.includes(walletType) ? !!starkPrivateKey : true

    if (!APP_WALLETS.includes(walletType)) {
      if (starkPrivateKey) {
        const { dtkVersion } = await recoverTradingKey()
        const tradingKey = {
          privateKey: starkPrivateKey,
          version: dtkVersion,
        }
        await attachTradingKeyToDvf(tradingKey.privateKey)
        dispatch(userSlice.actions.setTradingKey(tradingKey))
      }
    }
    if (walletType === WALLETS.KEYSTORE && privateKey) {
      const dtk = parsePrivateKey(privateKey, isLegacy)
      await attachTradingKeyToDvf(dtk)
      const tradingKey = {
        privateKey: dtk,
        version: undefined,
      }
      dispatch(userSlice.actions.setTradingKey(tradingKey))
      // keysotore reports the same address as it uses for signatures so we can use it
      const { isRegistered } = await getUser(address)
      // Auto register keystore as we have all the information
      if (!isRegistered) {
        await registerUser(dispatch)(wallet)
      }
    }
    if (isAuthValid) {
      const signatureAddress = await getSignatureAddress()
      const { isRegistered, starkKeyHex } = await getUser(signatureAddress)
      dispatch(portalSlice.actions.setUserSignatureAddress({ signatureAddress }))
      const dvf = await getDvf()
      dvf.set('signatureAddress', await getSignatureAddress())
      dispatch(
        userSlice.actions.setAuthenticated({
          isRegistered,
          starkPublicKey: starkKeyHex || '',
          wallet,
        }),
      )
      fetchData(dispatch)(address)
    }
    dispatch(userSlice.actions.setAuthenticating(false))
    return isAuthValid && hasStarkKey
  } catch (error) {
    dispatch(userSlice.actions.setAuthenticating(false))
  }
}
