import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import './constants/portalConfig'
import { Provider } from 'react-redux'
import 'simplebar-react/dist/simplebar.min.css'
import 'overlayscrollbars/css/OverlayScrollbars.min.css'
import './styles/index.scss'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { configureStore } from './store/configureStore'
import { logAppVersions } from './services/apiService'
import { setUpSentry } from './services/helperService/setUpSentry'
import { translationSetup } from './services/translationService'
import { SETTINGS_LOCAL_STORAGE_KEY } from './middleware/settings.middleware'
import { jsonToSettingsState } from './reducers/settingsSlice/settingsStateSchema'

const MainApp = React.lazy(() => import('./components/App/MainApp').then((module) => ({ default: module.MainApp })))
const Layout = React.lazy(() => import('./components/App/App').then((module) => ({ default: module.Layout })))
const BitfinexTransfer = React.lazy(() => import('./components/Bitfinex/BitfinexTransfer'))
const SupportBitfinex = React.lazy(() => import('./components/Bitfinex/SupportBitfinex'))

window.env = import.meta.env

if (typeof window !== 'undefined') {
  window.global = window
}

translationSetup()
logAppVersions()
setUpSentry()

const localStorageSettingsState = jsonToSettingsState(window.localStorage.getItem(SETTINGS_LOCAL_STORAGE_KEY))
const store = configureStore({ settings: localStorageSettingsState })

const Root = () => {
  return (
    <Provider store={store}>
      <Outlet />
    </Provider>
  )
}

// TODO FUD-1181: Refactor: Remove global store reference and access
window.store = store

const container = document.getElementById('root')!
const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: 'support/bfxtxs',
            element: <SupportBitfinex />,
          },
          {
            path: 'account/bfxtransfer/:id',
            element: <BitfinexTransfer />,
          },
          {
            path: '*',
            element: <MainApp />,
          },
        ],
      },
    ],
  },
])

createRoot(container).render(<RouterProvider router={router} />)
