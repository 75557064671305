import type { UnknownAction } from '@reduxjs/toolkit'
import type { SupportedWallets } from '../../constants/types'
import type { WalletConfig, Wallet } from '../../services/wallets/wallet'
import { connectWallet } from '../../services/wallets/wallet'
import { SET_WALLET_ERROR, WALLET_CONNECTED, WALLET_DISCONNECT } from '../types/user'
import type { AppDispatch } from '../../store/configureStore'
import { parseWalletError, saveLastLogin } from '../../services/helperService'
import { isMobileBrowser, triggerDeepLink } from '../../services/wallets/walletService'
import { trackUserLoginEvent } from '../../services/tracking/tracking'
import { listenForDisconnect } from '../accountPickerActions'
import { checkWalletType } from '../../services/wallets/checkWalletType'
import { userSlice } from '../../reducers/userSlice'
import { listenForNetworkChange, setNetworkId } from './networkActions'
import { listenForAddressChange } from './addressChangeActions'
import { checkExistingAuthAndKey } from './authenticationActions/checkExistingAuthAndKey'

export const initiateConnection =
  (dispatch: AppDispatch) => async (walletType: SupportedWallets, config?: WalletConfig) => {
    let timeout: number | null = null
    try {
      dispatch(setWalletErrorAction({ error: '' }))
      if (isMobileBrowser(walletType)) {
        return triggerDeepLink(walletType)
      }

      dispatch(userSlice.actions.setConnectingState({ isConnecting: true }))
      // start a timeout. If any of the calls hang, we will stop the loading state after 8s. If they unhang, the app should simply go from unconnected state to connected without loaders
      timeout = window.setTimeout(() => {
        dispatch(userSlice.actions.setConnectingState({ isConnecting: false }))
      }, 8000)
      const wallet = await connectWallet({ walletType, config, dispatch })
      // Check if user is connecting with already registered walletType
      await checkWalletType(wallet?.address, walletType)
      // Set network id
      await setNetworkId(dispatch)()
      if (wallet) {
        // Check for authentication / keys stored in local storage
        const hasAuth = await checkExistingAuthAndKey(dispatch)(wallet)
        // Start listening for events: (accountChange / chainChange)
        listenForAddressChange(dispatch)()
        listenForNetworkChange(dispatch)()
        listenForDisconnect(dispatch)(wallet?.walletType)

        saveLastLogin({
          type: walletType,
          address: wallet?.address,
          ...(config && config.name && { name: config.name }),
          ...(wallet.path && { path: wallet.path }),
        })
        if (hasAuth) {
          trackUserLoginEvent(wallet, true)
        }
      }
      dispatch(
        setWalletConnectedAction({
          wallet,
          ...(config?.name ? { name: config.name } : {}),
        }),
      )
      dispatch({ type: 'CLOSE_CONNECT_WALLET' })
    } catch (error) {
      console.error(error)
      if (config?.isAutoConnect) {
        dispatch(userSlice.actions.setConnectingState({ isConnecting: false }))
      } else {
        dispatch(userSlice.actions.setConnectingState({ isConnecting: false, error: parseWalletError(error) }))
      }
    }

    if (timeout !== null) {
      window.clearTimeout(timeout)
    }
  }

export const setWalletConnectedAction = (payload: { wallet: Wallet | null; name?: string }) =>
  ({
    type: WALLET_CONNECTED,
    payload,
  }) as const

export type WalletConnectedAction = ReturnType<typeof setWalletConnectedAction>

export const walletDisconnectAction = () =>
  ({
    type: WALLET_DISCONNECT,
    payload: {},
  }) as const

export type WalletDisconnectAction = ReturnType<typeof walletDisconnectAction>

export const setWalletErrorAction = (payload: { error: string }) =>
  ({
    type: SET_WALLET_ERROR,
    payload,
  }) as const

export type ClearWalletErrorAction = ReturnType<typeof setWalletErrorAction>

export const isWalletDisconnectAction = (action: UnknownAction): action is WalletDisconnectAction =>
  action.type === WALLET_DISCONNECT

export const isWalletConnectedAction = (action: UnknownAction): action is WalletConnectedAction =>
  action.type === WALLET_CONNECTED

export const isWalletErrorAction = (action: UnknownAction): action is ClearWalletErrorAction =>
  action.type === SET_WALLET_ERROR
