import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Subscription } from '../services/emailService/emailService.types'

export type EmailState = {
  email: string
  initialFetchStatus: 'initial' | 'pending' | 'done' | 'no-auth'
  list: Array<Subscription>
}

const getInitialState = (): EmailState => {
  return {
    email: '',
    initialFetchStatus: 'initial',
    list: [],
  }
}

export const emailSlice = createSlice({
  name: 'email',
  initialState: getInitialState(),
  reducers: {
    setSubscriptionList(state, action: PayloadAction<Array<Subscription>>) {
      return {
        ...state,
        list: action.payload,
      }
    },
    setEmailAddress(state, action: PayloadAction<string>) {
      return {
        ...state,
        email: action.payload,
      }
    },
    setInitialFetchStatus(state, action: PayloadAction<'initial' | 'pending' | 'done' | 'no-auth'>) {
      return {
        ...state,
        initialFetchStatus: action.payload,
      }
    },
  },
})
