import { isFulfilled } from './isFulfilled'
import { isRejected } from './isRejected'

export function splitSettledPromises<T, K>(
  promises: [PromiseSettledResult<T>, PromiseSettledResult<K>],
  replaceFailedWithNulls: true,
): {
  fulfilled: [PromiseFulfilledResult<T> | null, PromiseFulfilledResult<K> | null]
  rejected: Array<PromiseRejectedResult>
}
export function splitSettledPromises<T>(
  promises: Array<PromiseSettledResult<T>>,
  replaceFailedWithNulls?: boolean,
): {
  fulfilled: Array<PromiseFulfilledResult<T> | null>
  rejected: Array<PromiseRejectedResult>
}

export function splitSettledPromises<T>(promises: PromiseSettledResult<T>[], replaceFailedWithNulls = false) {
  const fulfilled = replaceFailedWithNulls
    ? promises.map((promise) => (isFulfilled(promise) ? promise : null))
    : promises.filter(isFulfilled)
  const rejected = promises.filter(isRejected)

  rejected.forEach((promise) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(promise.reason)
    }
  })

  return {
    fulfilled,
    rejected,
  }
}
