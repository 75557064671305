import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  GetAccessInput,
  GetAccessResponse,
} from '@rhinofi/dvf-gated-features-api-spec/dist/apiSpec/access/endpoints'
import { apiSpec } from '@rhinofi/dvf-gated-features-api-spec'
import { Effect } from 'effect'
import { Client } from 'effect-http'
import type {
  PostWaitlistInput,
  PostWaitlistResponse,
  GetWaitlistInput,
  GetWaitlistResponse,
} from '@rhinofi/dvf-gated-features-api-spec/dist/apiSpec/waitlist/endpoints'
import {
  getNFTContract,
  getNFTContractConfig,
} from '../../../components/ActivityTracker/ActivityTrackerNFT/helpers/nftContractHelpers'
import { NETWORKS } from '../../../constants/types'
import { envConfig } from '../../../env/envConfig'

const client = Client.make(apiSpec, { baseUrl: `${envConfig.tradingApi}/gated-features` })

export const gatedFeaturesApi = createApi({
  reducerPath: 'gatedFeatures',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['Access', 'WaitlistRank'],
  endpoints: (builder) => ({
    getBetaKeyCounter: builder.query<{ maxCap: string; minted: string }, void>({
      queryFn: async () => {
        try {
          const chain = NETWORKS.SCROLL
          const betaKeyContractConfig = getNFTContractConfig('BETAKEY', chain)
          const betaKeyContract = getNFTContract('BETAKEY', chain, false)
          if (!betaKeyContract || !betaKeyContractConfig) {
            throw new Error(`Cannot find contract for BETAKEY on ${chain}`)
          }
          const tokenInfo: { maxCap: string } = await betaKeyContract.methods
            .tokens(betaKeyContractConfig.tokenId)
            .call()
          const totalSupply: string = await betaKeyContract.methods.totalSupply(betaKeyContractConfig.tokenId).call()

          return {
            data: {
              maxCap: tokenInfo.maxCap,
              minted: totalSupply,
            },
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
    }),
    hasAccessToFeature: builder.query<GetAccessResponse, GetAccessInput>({
      queryFn: async (path) => {
        try {
          const res = await Effect.runPromise(client.access({ path }))
          return { data: res }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
      providesTags: ['Access'],
    }),
    getRankOnWaitlist: builder.query<GetWaitlistResponse, GetWaitlistInput>({
      queryFn: async (path) => {
        try {
          const res = await Effect.runPromise(client.getWaitlist({ path }))
          return { data: res }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
      providesTags: ['WaitlistRank'],
    }),
    joinWaitlist: builder.mutation<PostWaitlistResponse, PostWaitlistInput>({
      queryFn: async (path) => {
        await Effect.runPromise(client.postWaitlist({ path }))
        return { data: undefined }
      },
      invalidatesTags: ['WaitlistRank'],
    }),
  }),
})

export const {
  useGetBetaKeyCounterQuery,
  useHasAccessToFeatureQuery,
  useGetRankOnWaitlistQuery,
  useJoinWaitlistMutation,
} = gatedFeaturesApi
