import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CrossChainSwapConfigForChain } from '../services/apiService/getCrossChainSwapConfig'
import type { TokensMeta } from '../services/apiService/getTokenMeta'
import type { CrossChainTokensState, CrossChainTokenItem } from './types/CrossChainTokensState'
import type { CrossChainYieldState } from './types/CrossChainYieldState'

export type CrossChainSwapConfig = {
  configPerChain: Record<string, CrossChainSwapConfigForChain>
  enabled: boolean
}

export type CrossChainTokenDisplayConfig = Pick<CrossChainTokenItem, 'token' | 'chain' | 'name' | 'iconUrl' | 'status'>

type XchainState = {
  crossChainSwapConfig?: CrossChainSwapConfig
  crossChainTokenRegistry: CrossChainTokensState
  crossChainTokenConfig: Record<string, CrossChainTokenDisplayConfig>
  crossChainYieldRegistry: CrossChainTokensState
  crossChainYieldConfig: Record<string, CrossChainTokenDisplayConfig>
  crossChainTokensConfigPerChain: Record<string, Record<string, CrossChainTokenItem>>
  crossChainTokensMeta: {
    isFetching: boolean
    data: TokensMeta
  }
  crossChainYield: CrossChainYieldState
}

const getInitialState = (): XchainState => ({
  crossChainSwapConfig: undefined,
  crossChainTokenRegistry: [],
  crossChainTokenConfig: {},
  crossChainYieldRegistry: [],
  crossChainYieldConfig: {},
  crossChainTokensConfigPerChain: {},
  crossChainTokensMeta: {
    isFetching: false,
    data: {},
  },
  crossChainYield: [],
})

export const xchainSlice = createSlice({
  name: 'xchain',
  initialState: getInitialState(),
  reducers: {
    setXchainConfig(
      state,
      action: PayloadAction<{
        crossChainSwapConfig: {
          configPerChain: Record<string, CrossChainSwapConfigForChain>
          enabled: boolean
        }
        crossChainTokenRegistry: CrossChainTokensState
        crossChainTokenConfig: Record<string, CrossChainTokenDisplayConfig>
        crossChainYieldRegistry: CrossChainTokensState
        crossChainYieldConfig: Record<string, CrossChainTokenDisplayConfig>
        crossChainTokensConfigPerChain: Record<string, Record<string, CrossChainTokenItem>>
      }>,
    ) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setXchainYield(state, action: PayloadAction<{ crossChainYield: CrossChainYieldState }>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setXchainMeta(
      state,
      action: PayloadAction<{ crossChainTokensMeta: TokensMeta; isFetching: false } | { isFetching: true }>,
    ) {
      const { isFetching } = action.payload
      if (isFetching) {
        return {
          ...state,
          crossChainTokensMeta: {
            ...state.crossChainTokensMeta,
            isFetching,
          },
        }
      }
      const { crossChainTokensMeta } = action.payload

      return {
        ...state,
        crossChainTokensMeta: {
          data: { ...state.crossChainTokensMeta.data, ...crossChainTokensMeta },
          isFetching,
        },
      }
    },
  },
})
