import { cloneDeep, merge } from 'lodash'
import * as yup from 'yup'
import type { SettingsState } from './settingsSlice'
import { initialState } from './settingsSlice'

export const settingsStateSchema = yup.object().shape({
  mode: yup.string().oneOf(['LEDGER', '']).optional(),
  swap: yup
    .object()
    .shape({
      slippage: yup.number().optional(),
    })
    .optional(),
  chartInterval: yup.string().optional(),
  collapsibles: yup
    .object()
    .shape(Object.fromEntries(Object.keys(initialState.collapsibles).map((key) => [key, yup.boolean().optional()])))
    .optional(),
  tablesSorting: yup
    .object()
    .shape(Object.fromEntries(Object.keys(initialState.tablesSorting).map((key) => [key, yup.string().optional()])))
    .optional(),
})

export const isSettingsState = (settingsState: unknown): settingsState is SettingsState => {
  try {
    settingsStateSchema.validateSync(settingsState)
    return true
  } catch (error) {
    return false
  }
}

export const jsonToSettingsState = (json: string | null): SettingsState | undefined => {
  const parsed = json ? JSON.parse(json) : null
  if (!parsed) {
    return undefined
  }
  if (!isSettingsState(parsed)) {
    return undefined
  }
  const stateFromJson = settingsStateSchema.cast(parsed, {
    stripUnknown: true,
  })

  return merge(cloneDeep(initialState), stateFromJson)
}
