import { getAuthenticationData } from '../apiService'
import { getDvf } from '../dvfClient'
import { makeUrlFor } from './v3ApiUrls'

type UserInfo =
  | {
      isRegistered: true
      isRegisteredOnChain: boolean
      starkKeyHex: string
    }
  | {
      isRegistered: false
      isRegisteredOnChain: false
      starkKeyHex: null
    }

export const getUser = async (address: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  const user = await dvf.getAuthenticated<UserInfo>(makeUrlFor.user(address), nonce, signature)

  return user
}
