import _isEmpty from 'lodash/isEmpty'
import partition from 'lodash/partition'
import _isNumber from 'lodash/isNumber'
import userflow from 'userflow.js'
import Decimal from 'decimal.js'

import {
  generateAuthMessageForAuthVersion,
  formatNonceForAuthVersion,
  extractNonceAndAuthVersion,
} from '@rhino.fi/dvf-utils'
import { toChecksumAddress } from 'ethereumjs-util'
import { firstInPair, lastInPair } from '../services/formatService'
import { translate } from '../intl/i18n'
import { StatusNotificationStatus } from '../actions/types/statusNotifications'
import type { ChainGasFeed, SupportedWallets, TurnstileData } from '../constants/types'
import { F_FLAGS_TYPES, WALLETS } from '../constants/types'
import { isMobileDevice } from '../utils'
import type { HeapEvent } from '../constants/heapEvents'
import { heapEvents, heapStatuses } from '../constants/heapEvents'
import { envConfig } from '../env/envConfig'
import type { Fill, FillsState } from '../reducers/types/FillsState'
import type { TransfersState } from '../reducers/types/TransfersState'
import { userflowEvents } from '../constants/userflowEvents'
import type { PermitParams } from '../components/Deposit/DepositFunds/NewDepositWidget/useDepositWidget'
import type { DepositState } from '../reducers/types/DepositState'
import type { WithdrawalState } from '../reducers/types/WithdrawalState'
import { showConnectWalletNotification } from '../actions/notificationActions'
import type { TokenPricesState } from '../reducers/types/TokenPricesState'
import { registerFeatureFlagAbTesting } from '../actions/userActions/registerActions'
import type { AppDispatch } from '../store/store.types'
import { convertUSD } from '../utils/convertUSD'
import { validateEthereumAddress } from './helperService/validateEthereumAddress'
import { getOrderStatus } from './helperService/getOrderStatus'
import { provideContractData } from './wallets/ledgerService'
import { isValidWallet } from './wallets/walletService'
import { isFeatureEnabled } from './helperService/isFeatureEnabled'
import type { TransferFundsData } from './dvfClient'
import { getDvf } from './dvfClient'
import { getDTKOnly } from './tradingKeyService/webWalletTradingKey'
import type { WalletPayload } from './wallets/wallet'
import { getWeb3 } from './wallets/wallet'
import type { DvfClientInstance, GetTopPerformersRequest, Signature } from './dvfClient/DvfClientInstance'
import type { TradingKey } from './tradingKeyService/tradingKey'
import { getChainGasFeed, getChainGasMultiplier, getChainNativeToken } from './ethereum/chainProviders'
import type { TxHashCallback } from './ethereumService'
import { reportMessageToSentry } from './helperService/reportToSentry'
import { handleError } from './apiService/handleError'
import { getUser } from './v3Api/getUser'
import { getSignatureAddress } from './v3Api/getSignatureAddress'
import { WalletFlows } from './wallets/walletFlows'
import type { SavedCampaignData } from './helperService/checkRequestSource'
import { trackUserflowEvent } from './apiService/trackUserflowEvent'

const { tradingApi, apiUrl, marketDataV2V1CompatUrl, marketDataV2Url } = envConfig

let signLock: false | Promise<{ signature: Signature; nonce: string }> = false
const NONCE_DURATION = 12 * 60 * 60 * 1000

export const authTypes = {
  tradingKey: 'useTradingKey',
  signature: 'useSignature',
} as const

const getAddress = (dvf: DvfClientInstance) => {
  return dvf.get('account').toLowerCase()
}

const getChecksumAddress = (dvf: DvfClientInstance) => {
  const address = getAddress(dvf)
  if (!address) {
    throw new Error('Address required for checksum')
  }

  return toChecksumAddress(address)
}

// Authentication
export const authKey = (address: string) => `auth:${address.toLowerCase()}`

export const getAuthData = (address: string) => {
  const item = localStorage.getItem(authKey(address))
  if (!item) {
    return null
  }

  const parsed = JSON.parse<{ address: string; nonce: string; signature: Signature }>(item)

  return parsed
}

const getSigntatureR = (authData: undefined | null | { signature: Signature }) => {
  if (authData && typeof authData.signature !== 'string') {
    return authData.signature.r
  }

  return null
}

const getAuthExpiryDateForAddress = async (address: string) => {
  const authData = getAuthData(address)

  if (!authData) {
    return false
  }
  if (getSigntatureR(authData)) {
    ;(await getDvf()).config[authTypes.tradingKey] = true
  }
  const [nonceValue] = extractNonceAndAuthVersion(authData.nonce)
  return new Date(nonceValue * 1000 + NONCE_DURATION)
}

export const getAuthExpiryDate = async () => {
  const address = (await getDvf()).get('account')
  if (!address) {
    return false
  }
  return await getAuthExpiryDateForAddress(address)
}

export const checkAuthValidity = async (address: string, { useTradingKey = true } = {}) => {
  // Don't use cached auth if useTradingKey = false and tradingKey signature is present
  if (!useTradingKey && getSigntatureR(getAuthData(address))) {
    return false
  }

  return (await getAuthExpiryDateForAddress(address)) >= new Date()
}

const alignSignatureRecoveryToEIP712 = (signature: string): string => {
  // last two character are recovery id in hex
  let recoveryBit = parseInt(signature.substr(-2), 16)
  // if the numerical value is below 27, add it, to align to EIP-712
  recoveryBit += recoveryBit < 27 ? 27 : 0
  // convert back to hex, no need to pad as <27,54) is always two characters in hex
  return signature.slice(0, -2) + recoveryBit.toString(16)
}

export const signNonce = async (dvf: DvfClientInstance, privateKey?: string | null) => {
  const nonce = Date.now() / 1000
  try {
    let signature
    if (privateKey) {
      signature = await dvf.stark.signAuth<Promise<Signature>>(privateKey, nonce)
      dvf.config[authTypes.tradingKey] = true
      return {
        signature,
        nonce: nonce.toString(),
      }
    }

    // Hardcoded to version 3 due to rhino.fi rebrand
    const authVersion = 3
    const messageToSign = generateAuthMessageForAuthVersion(nonce, authVersion)
    signature = await dvf.sign(messageToSign, dvf.config.useSignature)
    signature = alignSignatureRecoveryToEIP712(signature)
    dvf.config[authTypes.tradingKey] = false

    return {
      signature,
      nonce: formatNonceForAuthVersion(nonce, authVersion),
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAuthenticationData = async (
  dvf: DvfClientInstance,
  {
    useTradingKey = true,
    cacheAuth = true,
    addressToCheck,
  }: { useTradingKey?: boolean; cacheAuth?: boolean; showNotification?: boolean; addressToCheck?: string } = {},
) => {
  // Useful for debugging authentication data calls before auth
  // console.info(new Error()?.stack?.split('\n')[2].trim().split(' ')[1])
  const address = dvf.get('account')

  if (!address) {
    throw new Error('No wallet available.')
  }
  if (addressToCheck && addressToCheck.toLowerCase() !== address.toLowerCase()) {
    throw new Error('Address mismatch, please refresh')
  }
  try {
    if (await checkAuthValidity(address, { useTradingKey })) {
      const authData = getAuthData(address)
      if (!authData) {
        throw new Error('Expected auth data was not found')
      }
      return authData
    }
    if (!signLock) {
      // Use tradingKey if available
      const dtk = useTradingKey ? getDTKOnly(address) : { privateKey: undefined }

      signLock = signNonce(dvf, dtk.privateKey)
      showConnectWalletNotification(StatusNotificationStatus.pending)

      const { signature, nonce } = await signLock
      // Don't cache only when attempting to use DTK since we don't know if it'll fail
      if (cacheAuth || !dtk?.privateKey) {
        localStorage.setItem(
          authKey(address),
          JSON.stringify({
            nonce,
            signature,
            address,
          }),
        )
      }

      showConnectWalletNotification(StatusNotificationStatus.success)

      signLock = false
      return {
        nonce,
        signature,
        address,
      }
    } else if (signLock) {
      const { signature, nonce } = await signLock
      return {
        nonce,
        signature,
        address,
      }
    } else {
      throw new Error('Did not attempt to get auth data')
    }
  } catch (error) {
    console.error(error)
    signLock = false
    showConnectWalletNotification(StatusNotificationStatus.error)
    throw error
  }
}

export const signFreshNonce = async () => {
  try {
    const dvf = await getDvf()
    const { signature, nonce, address } = await getAuthenticationData(dvf, {
      cacheAuth: false,
    })
    localStorage.setItem(
      authKey(address),
      JSON.stringify({
        nonce,
        signature,
        address,
      }),
    )
    return {
      nonce,
      signature,
      address,
    }
  } catch (error) {
    return false
  }
}

export const generateUserRegistrationMetadata = (
  wallet: WalletPayload &
    ({
      referer?: string
      campaign?: SavedCampaignData
    } | null),
) => ({
  walletType:
    wallet?.walletType === WALLETS.WALLET_CONNECT
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any -- window access, prop coming from extension
        `${wallet.walletType}-${(window as any)?.provider?.connector?.peerMeta?.name}`
      : wallet?.name || wallet?.walletType,

  campaign: wallet?.campaign,
  referer: wallet?.referer,
  platform: isMobileDevice() ? ('MOBILE' as const) : ('DESKTOP' as const),
})

type DepositParams = {
  token: string
  amount: string
  permitParams: PermitParams | undefined
  useProxiedContract?: boolean
  web3Options: {
    gasLimit?: number | undefined
  }
  transactionHashCb: (hash: string) => () => void
  referralId?: string | null
  onChainOnly?: boolean
  isBridge?: boolean
  starkKeyHex: string
}

export const deposit = async ({
  token,
  amount,
  permitParams,
  useProxiedContract = true,
  web3Options = {},
  transactionHashCb,
  referralId = null,
  onChainOnly,
  isBridge,
  starkKeyHex,
}: DepositParams) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.depositV2(
    {
      token,
      amount,
      useProxiedContract,
      permitParams,
      web3Options,
      ...(referralId ? { referralId } : {}),
      isBridge,
    },
    nonce,
    signature,
    transactionHashCb,
    onChainOnly,
    starkKeyHex,
  )
}

const ensureRegistrationBeforeBridge = async (wallet: WalletPayload, dispatch: AppDispatch) => {
  const dvf = await getDvf()
  const account = dvf.get('account')

  const registrationStatus = await dvf.getRegistrationStatuses({
    targetEthAddress: account.toLowerCase(),
  })

  if (wallet && registrationStatus.isRegisteredOnDeversifi === false) {
    await registerFeatureFlagAbTesting(dispatch)(wallet)
  }
}

type DepositUsingBridgeParams = {
  token: string
  amount: string
  chain: string
  transactionHashCb: TxHashCallback
  referralId?: string | null
  onChainOnly?: boolean
  isBridge?: boolean
}

export const depositUsingBridge = async ({
  token,
  amount,
  chain,
  transactionHashCb,
  referralId = null,
  onChainOnly = false,
  isBridge,
}: DepositUsingBridgeParams) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.bridgedDeposit(
    {
      token,
      amount,
      chain,
      ...(referralId ? { referralId } : {}),
      isBridge,
    },
    nonce,
    signature,
    transactionHashCb,
    onChainOnly,
  )
}

export const withdraw = async (
  token: string,
  amount: string,
  wallet: NonNullable<WalletPayload>,
  feeAmount: string,
) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  const { tradingKey, walletType, path } = wallet
  // Legacy users case without trading key
  // Rare case which cannot sign a transfer
  if (!tradingKey?.privateKey && walletType === WALLETS.METAMASK) {
    return dvf.withdrawV2(
      {
        token,
        amount,
      },
      nonce,
      signature,
    )
  }

  const transferAndWithdraw = () =>
    dvf.transferAndWithdraw(
      {
        recipientEthAddress: getAddress(dvf),
        token,
        amount,
        feeAmount,
      },
      nonce,
      signature,
    )

  if (WalletFlows.EVMWALLET.includes(walletType)) {
    if (!tradingKey?.privateKey) {
      throw new Error(translate('errors.trading_key_not_imported'))
    }
    return transferAndWithdraw()
  } else if (WalletFlows.LEDGER.includes(walletType)) {
    return dvf.ledger.transferAndWithdraw(
      {
        recipientEthAddress: getAddress(dvf),
        token,
        amount,
        feeAmount,
      },
      path,
      nonce,
      signature,
    )
  } else if (WalletFlows.KEYSTORE.includes(walletType)) {
    return transferAndWithdraw()
  } else {
    throw new Error(translate('errors.wallet_not_supported'))
  }
}

type WithdrawUsingBridgeParams = {
  token: string
  amount: string
  chain: string
  wallet: WalletPayload
  recipientEthAddress?: string
  isBridge?: boolean
  dispatch: AppDispatch
  amountNative?: string
  maxNativeTokenCost?: string
}

export const withdrawUsingBridge = async ({
  token,
  amount,
  chain,
  wallet,
  recipientEthAddress,
  isBridge,
  dispatch,
  amountNative,
  maxNativeTokenCost,
}: WithdrawUsingBridgeParams) => {
  const dvf = await getDvf()
  await ensureRegistrationBeforeBridge(wallet, dispatch)
  const { nonce, signature } = await getAuthenticationData(dvf)

  const nativeToken = amountNative || maxNativeTokenCost ? getChainNativeToken(chain) : undefined

  return dvf.bridgedWithdraw(
    {
      token,
      amount,
      chain,
      recipientEthAddress,
      isBridge,
      amountNative: amountNative || undefined,
      maxNativeTokenCost: maxNativeTokenCost || undefined,
      nativeToken,
    },
    nonce,
    signature,
  )
}

export const createFastWithdrawalPayload = async (
  token: string,
  amount: string,
  recipientEthAddress: string,
  transactionFee: string | null = null,
) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.createFastWithdrawalPayload(
    {
      token,
      amount,
      recipientEthAddress,
      transactionFee,
    },
    {
      nonce,
      signature,
    },
  )
}

type FastWithdrawParams = {
  token: string
  amount: string
  recipientEthAddress: string
  transactionFee: string | null
  isBridge?: boolean
}

export const fastWithdraw = async ({
  token,
  amount,
  recipientEthAddress,
  transactionFee = null,
  isBridge,
}: FastWithdrawParams) => {
  const dvf = await getDvf()

  const authData = await getAuthenticationData(dvf)

  return dvf.fastWithdrawal(
    {
      token,
      amount,
      recipientEthAddress,
      transactionFee,
      isBridge,
    },
    authData,
  )
}

export const withdrawOnchain = async (
  token: string,
  address: string | undefined,
  walletType: SupportedWallets | undefined,
  starkKeyHex: string,
) => {
  const dvf = await getDvf()
  if (walletType === 'ledger') {
    await provideContractData(dvf, token, true)
  }
  return dvf.withdrawOnchain(token, address || starkKeyHex)
}

export const cancelWithdrawal = async (id: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.cancelWithdrawal(id, nonce, signature)
}

export const cancelOrder = async (orderId: string | number) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.cancelOrder(orderId, nonce, signature)
}

export const transferFunds = async (
  data: TransferFundsData,
  wallet: NonNullable<WalletPayload>,
  walletSignCb = () => {},
) => {
  const { tradingKey, path, walletType } = wallet
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  if (WalletFlows.EVMWALLET.includes(walletType)) {
    if (!tradingKey?.privateKey) {
      throw new Error(translate('errors.trading_key_not_imported'))
    }
    return dvf.transfer(data, nonce, signature)
  } else if (WalletFlows.LEDGER.includes(walletType)) {
    const transferResult = await dvf.ledger.transfer(data, path, nonce, signature)
    walletSignCb()
    return transferResult
  } else if (WalletFlows.KEYSTORE.includes(walletType)) {
    return dvf.transfer(data, nonce, signature)
  } else {
    throw new Error(translate('errors.wallet_not_supported'))
  }
}

export const storeTradingKey = async (encryptedTradingKey: string, dtkVersion = 'v3') => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const ethAddress = dvf.get('account')

  return dvf.postAuthenticated<void>('/v1/trading/w/storeStarkTradingKey', nonce, signature, {
    ethAddress,
    encryptedTradingKey,
    dtkVersion,
  })
}

export const sendWalletFailedEvent = async (walletType: string | undefined, errorText: string) => {
  if (isValidWallet(walletType)) {
    try {
      const dvf = await getDvf()
      await dvf.walletFailedEvent({ walletType, errorText })
    } catch (err) {
      console.error(err)
    }
  }
}

export const sendWalletSuccessEvent = async (walletType: string | undefined, successText: string) => {
  if (isValidWallet(walletType)) {
    try {
      const dvf = await getDvf()
      await dvf.walletSuccessEvent({ walletType, successText })
    } catch (err) {
      console.error(err)
    }
  }
}

export const getDeposits = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.getDeposits(null, nonce, signature)
}

export const getWithdrawals = async (starkKeyHex: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  const address = getChecksumAddress(dvf)
  const withdrawals = await dvf.getWithdrawals(null, address, nonce, signature, starkKeyHex)
  return withdrawals.map((item) => ({
    ...item,
    status: item.status || 'pending',
    target: item.target,
  }))
}

export const defaultHistoryEndpointHeaders = { accept: 'application/json' }

export type GetFillsFilters = {
  limit?: number
  symbol?: string
  startDate?: string
  endDate?: string
  skip?: number | null
  sortDirection?: string
  sortBy?: 'date'
}

type GetFillsHeaders = { accept: 'text/csv' }

export function getFills(filters: GetFillsFilters, headers: GetFillsHeaders): Promise<FillsState | BlobPart>
export function getFills(filters: GetFillsFilters): Promise<FillsState>
export async function getFills(filters: GetFillsFilters = {}, headers = defaultHistoryEndpointHeaders) {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const result: Promise<FillsState | BlobPart> = dvf.getAuthenticated(
    '/v1/trading/fills',
    nonce,
    signature,
    filters,
    headers,
  )

  return result
}

export const getFill = async (id: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.getAuthenticated<Fill | { error: unknown }>(`/v1/trading/fill?id=${id}`, nonce, signature)
}

export const getDeposit = async (id: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  // type is a guess
  return dvf.getAuthenticated<
    | (DepositState & {
        txHash?: string
        chain?: string
      })
    | { error: string }
  >(`/v1/trading/deposit?id=${id}`, nonce, signature)
}

export const getWithdrawal = async (id: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  // type is a guess
  return dvf.getAuthenticated<
    | (Omit<WithdrawalState, 'status'> & { txHash?: string; chain?: string; recipient?: string; status?: string })
    | { error: string }
  >(`/v1/trading/withdrawal?id=${id}`, nonce, signature)
}

type SingleTransfer = {
  amount: number
  createdAt: string
  senderOrRecipient: string
  sent: boolean
  token: string
  _id: string
  error?: string
}

export const getTransfer = async (id: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.getAuthenticated<SingleTransfer>(`/v1/trading/transfer?id=${id}`, nonce, signature)
}
export type GetTransfersFilters = GetFillsFilters & {
  token?: string
}
export function getTransfers(filters: GetTransfersFilters, headers: { accept: 'text/csv' }): Promise<BlobPart>
export async function getTransfers(filters = {}, headers = defaultHistoryEndpointHeaders) {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.getAuthenticated<TransfersState | BlobPart>('/v1/trading/transfers', nonce, signature, filters, headers)
}

export const getTradingFeeRate = async ({ feature, symbol }: { feature: string; symbol?: string }) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const { fees } = await dvf.getFeeRate(
    {
      feature,
      symbol,
    },
    nonce,
    signature,
  )
  return {
    taker: fees.taker / 10000,
    maker: fees.maker / 10000,
  }
}

export const get24hrVolume = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.getAuthenticated<{ totalUSDVolume: number }>('/v1/trading/r/User24HoursVolume', nonce, signature)
}

export const get30DaysVolume = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.get30DaysVolume<{ totalUSDVolume: number }>(nonce, signature)
}

export const getTickerV2 = async (symbol = '') => {
  try {
    const data = await fetch(`${marketDataV2Url}/ticker/${symbol}`)
    const ticker = await data.json<{
      symbol: string
      timestamp: string
      dailyChange: number
      dailyChangeRelative: number
      open: number
      high: number
      low: number
      lastPrice: number
      volume: number
    }>()
    return ticker
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const getTicker = async (symbol = '') => {
  try {
    const data = await fetch(`${apiUrl}/ticker/${symbol}`)
    const ticker = await data.json<[number, number, number, number, number, number, number, number, number, number]>()
    return {
      symbol,
      bid: ticker[0],
      bidSize: ticker[1],
      ask: ticker[2],
      askSize: ticker[3],
      dailyChange: ticker[4],
      dailyChangeRelative: ticker[5],
      lastPrice: ticker[6],
      volume: ticker[7],
      high: ticker[8],
      low: ticker[9],
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const getTokenCurrentUsdPrice = async (token: string) => {
  try {
    const response = await fetch(`${apiUrl}/getUsdtPrice/${token}`)
    const data = await response.json<{ price: number }>()
    return data.price
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getHistoricUsdtPrice = async (token = '', date: string | number, interval = '15m') => {
  try {
    const dateTime = new Date(date).toISOString()
    const response = await fetch(`${apiUrl}/getUsdtPrice/${token}?dateTime=${dateTime}&interval=${interval}`)
    const data = await response.json<{ price: number }>()
    return data.price
  } catch (error) {
    console.error(error)
    return 0
  }
}

export const getTickersV2 = async () => {
  try {
    const data = await fetch(`${marketDataV2Url}/tickers?symbols=ALL`)
    return data.json<
      Array<{
        symbol: string
        timestamp: string
        dailyChange: number
        dailyChangeRelative: number
        open: number
        high: number
        low: number
        lastPrice: number
        volume: number
      }>
    >()
  } catch (error) {
    console.error(error)
    return []
  }
}

type BookSnapshot = {
  symbol: string
  rows: Array<[number, number, number]>
  length: number
  precision: string
  sequence: number
}
type BookEntry = [number, number, number]
export type BookObject = {
  price: number
  amount: number
}

const bookEntryToObject = ([price, flag, amount]: BookEntry): BookObject => ({
  price,
  amount: Math.abs(amount),
})

export type BookData = {
  bids: Array<BookObject>
  asks: Array<BookObject>
}

export const getBook = async (symbol: string, precision = 'P0', length = '25'): Promise<BookData> => {
  try {
    const data = await fetch(`${apiUrl}/book/${symbol}/${precision}/${length}`)
    const bookData = await data.json<Array<BookEntry>>()

    const bidAskData = Array.isArray(bookData) ? partition(bookData, ([price, flag, amount]) => amount > 0) : []
    const [bids = [], asks = []] = bidAskData.map((book) => book.map(bookEntryToObject))

    return {
      bids,
      asks,
    }
  } catch (error) {
    console.error(error)
    return {
      bids: [],
      asks: [],
    }
  }
}

export const getBookV2 = async (symbol: string, precision = 'P0', length = '25'): Promise<BookData> => {
  try {
    const data = await fetch(`${marketDataV2V1CompatUrl}/book/${symbol}/${precision}/${length}`)
    const bookData = await data.json<Array<BookEntry>>()

    const bidAskData = Array.isArray(bookData) ? partition(bookData, ([price, flag, amount]) => amount > 0) : []
    const [bids = [], asks = []] = bidAskData.map((book) => book.map(bookEntryToObject))

    return {
      bids,
      asks,
    }
  } catch (error) {
    console.error(error)
    return {
      bids: [],
      asks: [],
    }
  }
}

export const getBookV2Snapshot = async (symbol: string, precision = 'P0', length = '25'): Promise<BookSnapshot> => {
  const response = await fetch(`${marketDataV2Url}/order-books/${symbol}/${precision}/${length}`)

  const data = await response.json<BookSnapshot>()

  return data
}

export const getLatestCandlePrice = async (symbol: string) => {
  try {
    const data = await fetch(`${apiUrl}/candles/trade:1m:${symbol}/last?limit=1&sort=-1`)
    // type is a guess
    const [candle] = await data.json<[[number, number, number, number, number, number]]>()
    return {
      symbol,
      timestamp: candle[0],
      open: candle[1],
      close: candle[2],
      high: candle[3],
      low: candle[4],
      volume: candle[5],
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const getCandlePriceAtDate = async (symbol: string, date: Date) => {
  const start = date.getTime()
  try {
    // Could be more generic but YAGNI
    const data = await fetch(`${apiUrl}/candles/trade:1m:${symbol}/hist?start=${start}&limit=1&sort=1`)
    const [candle] = await data.json<[[number, number, number, number, number, number]]>()
    return {
      symbol,
      timestamp: candle[0],
      open: candle[1],
      close: candle[2],
      high: candle[3],
      low: candle[4],
      volume: candle[5],
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

type CandleV1 = [number, number, number, number, number, number, number, number]

export const getCandles = async (symbol: string, interval: string, until: number) => {
  try {
    const url = `${apiUrl}/candles/trade:${interval}:${symbol}/hist${until ? '?end=' + until : ''}`

    const response = await fetch(url)
    if (response.status !== 200) {
      return null
    }
    const data = await response.json<Array<CandleV1>>()
    return data || []
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getOrdersHist = async (passedSymbol: string | null = null) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const orders = await dvf.getOrdersHist(passedSymbol, nonce, signature)
  return orders.map((order) => {
    const { active, amount, symbol, totalFilled, canceled, tokenBuy, totalBought } = order
    const isSellOrder = (typeof amount === 'string' ? parseFloat(amount) : amount) < 0
    const first = convertUSD(firstInPair(symbol, true))
    const last = convertUSD(lastInPair(symbol, true))
    const [buying, selling] = isSellOrder ? [last, first] : [first, last]
    return {
      ...order,
      isSellOrder,
      buying,
      selling,
      totalFilled: Math.abs(totalFilled || 0),
      amount: Math.abs(amount),
      status: getOrderStatus(active, canceled, Math.abs(totalFilled || 0), Math.abs(amount)),
      ...(totalBought && { totalBought: Number(dvf.token.fromQuantizedAmount(tokenBuy, totalBought, false)) }),
    }
  })
}

export type ParsedHistOrder = Awaited<ReturnType<typeof getOrdersHist>>[0]
export type ExchangeBalanceResponse = Array<{
  token: string
  balance: string
  available: string
  locked: string
}>

export const getExchangeBalances = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const exchangeBalances: Record<
    string,
    { token: string; deposit: string; available: string; locked: string; depositValue: string; total: string }
  > = {}
  // Fill in data for tokens you don't have balance for
  Object.keys(dvf.config.tokenRegistry).map((token) => {
    exchangeBalances[token] = {
      token,
      deposit: '0',
      available: '0',
      locked: '0',
      depositValue: '0',
      total: '0',
    }
  })
  const balances = await dvf.getBalance<Promise<ExchangeBalanceResponse>>(null, nonce, signature)
  balances.map((item) => {
    exchangeBalances[item.token] = {
      token: item.token,
      available: dvf.token.fromQuantizedAmount(item.token, item.available, false),
      deposit: dvf.token.fromQuantizedAmount(item.token, item.available, false),
      locked: dvf.token.fromQuantizedAmount(item.token, item.locked, false),
      total: dvf.token.fromQuantizedAmount(item.token, item.balance, false),
      depositValue: dvf.token.fromQuantizedAmount(item.token, item.available, false),
    }
  })

  return exchangeBalances
}

export const getBalanceWithDate = async (token: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const fields = ['available', 'updatedAt']
  try {
    const [balanceWithDate] = await dvf.getBalance<
      Promise<
        [
          {
            available: string
            updatedAt: string
          },
        ]
      >
    >(
      {
        token,
        fields,
      },
      nonce,
      signature,
    )

    if (!balanceWithDate) {
      return {
        deposit: 0,
        updatedAt: new Date(),
      }
    }

    return {
      deposit: dvf.token.fromQuantizedAmount(token, balanceWithDate.available, false),
      updatedAt: new Date(balanceWithDate.updatedAt),
    }
  } catch (error) {
    console.error(error)
    return {
      deposit: 0,
      updatedAt: new Date(),
    }
  }
}

export const getFastWithdrawalMaxAmount = async (token: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.fastWithdrawalMaxAmount(token, nonce, signature)
}

export const getEstimatedNextBatchTime = async () => {
  try {
    const dvf = await getDvf()
    const response = await dvf.estimatedNextBatchTime()
    const { estimatedTime = null, averageTime = null, finalisedBatchPendingConfirmation = false } = response || {}
    return {
      estimatedTime: estimatedTime,
      origAverageTime: averageTime,
      averageTime: Math.floor((averageTime || 0) / 60),
      finalisedBatchPendingConfirmation,
    }
  } catch (error) {
    return {
      estimatedTime: null,
      averageTime: null,
      finalisedBatchPendingConfirmation: false,
      origAverageTime: null,
    }
  }
}

export const getGasPrices = async () => {
  const dvf = await getDvf()
  return !_isEmpty(dvf.recommendedGasPrices)
    ? dvf.recommendedGasPrices
    : {
        fast: '20000000000',
        fastWait: '',
        average: '15000000000',
        averageWait: '',
        cheap: '10000000000',
        cheapWait: '',
      }
}

const defaultSidechainGasPriceGwei = 20
// gasPricesFeed can be value or URL
export const getSafeGasPriceForSidechain = async ({
  chain,
  customChainGasFeed,
}: {
  chain: string
  customChainGasFeed?: ChainGasFeed
}): Promise<string> => {
  const gasPricesFeed = customChainGasFeed || getChainGasFeed(chain)

  if (!gasPricesFeed) {
    throw new Error('NO_GAS_PRICE_FEED_FOR_CHAIN')
  }
  if (_isNumber(gasPricesFeed)) {
    return gasPricesFeed.toString()
  }
  try {
    if (gasPricesFeed === 'WEB3') {
      const web3PerChain = getWeb3()
      const gasMultiplier = getChainGasMultiplier(chain)
      const gasPrice = await web3PerChain[chain].eth.getGasPrice()
      return new Decimal(gasPrice).mul(gasMultiplier).toFixed(0)
    }

    const response = await fetch(gasPricesFeed)
    const gasPrices = await response.json<{
      fastest?: number
      fast?: number
      result?: {
        FastGasPrice: number
      }
    }>()
    // Assuming other sidechain will follow a similar format.
    // Plan for either custom code per chain or server-side uniformization.
    // TODO BINF-273: We have uniformity for this server-side, expose gas price per chain endpoint
    // - MATIC_POS: fastest (or fast)
    // - BINANCE: result.FastGasPrice
    const fastestGas = gasPrices?.fastest ?? gasPrices?.fast ?? gasPrices?.result?.FastGasPrice
    if (fastestGas) {
      return new Decimal(fastestGas).mul(1e9).toFixed(0)
    } else {
      throw new Error('Invalid gas feed format')
    }
  } catch (error) {
    console.warn(
      `Failed to fetch gas prices for sidechain ${chain}. Falling back to default ${defaultSidechainGasPriceGwei} gwei`,
      error,
    )
    return (defaultSidechainGasPriceGwei * 1e9).toString()
  }
}

export const getTransactionGasFees = async () => {
  const { average } = await getGasPrices()
  const pricesArr = [60, 80, 38, 40]
    .map((gasCost) => gasCost * +average * 1e-6) // gasCost * 1e3 * average * 1e-9
    .map(Math.floor)
    .map((value) => value * 1e-9)

  return {
    default: {
      low: pricesArr[0],
      high: pricesArr[1],
    },
    ETH: {
      low: pricesArr[2],
      high: pricesArr[3],
    },
  }
}

// Remove potential decimals from calculation and return the value as string
const gasPriceToString = (gasPrice: number) => Math.ceil(gasPrice).toString()

export const getSafeGasPrice = async ({
  chain = 'ETHEREUM',
  customChainGasFeed,
}: {
  chain?: string
  customChainGasFeed?: ChainGasFeed
}): Promise<string> => {
  if (chain !== 'ETHEREUM') {
    return getSafeGasPriceForSidechain({ chain, customChainGasFeed })
  }
  const dvf = await getDvf()
  if (_isEmpty(dvf.recommendedGasPrices)) {
    const defaultEthereumGasPrice = 20000000000
    return gasPriceToString(defaultEthereumGasPrice)
  }
  return gasPriceToString(parseFloat(dvf.recommendedGasPrices.fast) * 1.02)
}

export const recoverTradingKey = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const ethAddress = dvf.get('account')

  return dvf.postAuthenticated<{ encryptedTradingKey: string; dtkVersion: TradingKey['version'] }>(
    '/v1/trading/r/recoverTradingKey',
    nonce,
    signature,
    {
      ethAddress,
    },
  )
}

export const getNumTrades = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.postAuthenticated<{ count: number }>('/v1/trading/r/userOrderCount', nonce, signature)
}

export const getHasReceivedCompensation = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  return dvf.postAuthenticated<{
    hasReceived: boolean
    updatedAt: string
  }>('/v1/trading/r/hasReceivedCompensation', nonce, signature)
}

export const isAccountRegisteredInDB = async (account?: string | false) => {
  if (!account || !validateEthereumAddress(account)) {
    return {
      isRegisteredOnDeversifi: false,
      walletType: undefined,
    }
  }

  const dvf = await getDvf()
  const { isRegisteredOnDeversifi, isRegisteredOnChain, l1RegistrationSignature, walletType } =
    await dvf.getRegistrationStatuses({
      targetEthAddress: account,
    })

  return {
    isRegisteredOnDeversifi,
    isRegisteredOnChain,
    l1RegistrationSignature,
    walletType,
  }
}

export const storeStarkL1Registration = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  await dvf.storeStarkL1Registration(nonce, signature)
}

/**
 * To be used in testing/debugging flows
 * and not directly exposed to all users
 */
export const registerOnChain = async (address: string) => {
  const dvf = await getDvf()
  const signatureAddress = await getSignatureAddress()
  const { starkKeyHex } = await getUser(signatureAddress)

  const l1RegistrationSignature = await dvf.stark.signRegistration(signatureAddress)

  return dvf.eth.send(
    dvf.contract.abi.getStarkEx(),
    dvf.config.DVF.starkExContractAddress,
    'registerEthAddress',
    [address, starkKeyHex, l1RegistrationSignature],
    null,
    {
      chain: 'ETHEREUM',
      transactionHashCb: () => {},
    },
  )
}

export const publicUserPermissions = async () => {
  const dvf = await getDvf()
  return dvf.publicUserPermissions()
}

export type DvfPermissions = Record<string, boolean>
export const getPermissions = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.account.getPermissions<DvfPermissions>(nonce, signature)
}

export const setPermissions = async (key: string, value?: boolean) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.account.setPermissions<DvfPermissions>(
    {
      key,
      value,
    },
    nonce,
    signature,
  )
}

// Misc

export const logAppVersions = async () => {
  try {
    const data = await fetch(`${tradingApi}/v1/trading/apiVersion`)
    const { version } = await data.json<{ version: string }>()
    // eslint-disable-next-line no-console -- Log required for QA
    console.log(
      `%c Frontend version (Vite): ${import.meta.env.VITE_REACT_APP_VERSION}`,
      'background: #000; color: #fff; font-size: 18px;',
    )
    // eslint-disable-next-line no-console -- Log required for QA
    console.log(`%c Backend version: ${version}`, 'background: #000; color: #fff; font-size: 18px;')
  } catch (error) {
    // eslint-disable-next-line no-console -- Log required for QA
    console.log(
      '%c Frontend v. (Vite)' + import.meta.env.VITE_REACT_APP_VERSION,
      'background: #000; color: #fff; font-size: 18px;',
    )
  }
}

export const getMinMaxOrderSize = async (symbol: string) => {
  try {
    const response = await fetch(`${apiUrl}/order-size/${symbol}`)
    if (response.status !== 200) {
      return {}
    }
    const data = await response.json<Record<string, unknown>>()
    return data || {}
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const handleUpdateUserMetaTurnstile = (token: string, errorCode: number) => {
  if (errorCode === -1) {
    return updateUserMetaTurnstile({ type: 'REGULAR', errorCode: -1, token })
  }

  return updateUserMetaTurnstile({ type: 'BOT', errorCode })
}

export const updateUserMetaTurnstile = async (turnstile: TurnstileData) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)

  const data = {
    meta: {
      turnstile,
    },
  }

  try {
    await dvf.postAuthenticated<void>('/v1/trading/w/userMeta', nonce, signature, data)
  } catch (error) {
    reportMessageToSentry('updateUserMetaTurnstile issue', {
      turnstile,
      fullMessage: handleError(error),
    })
  }
}

export const getDlmData = async (token: string) => {
  const dvf = await getDvf()

  try {
    const tokenHolders = await dvf.getTokenHolders(token)
    const tokenLiquidityLeft = await dvf.getTokenLiquidityLeft(token)
    const tokenSaleStartEnd = JSON.parse<null | {
      start?: string | undefined
      end?: string | undefined
      startPrice?: number | undefined
    }>(await dvf.getTokenSaleStartEnd(token))

    return {
      tokenHolders,
      tokenLiquidityLeft: JSON.parse<{ liquidityAvailable: number; totalSupply: number }>(tokenLiquidityLeft),
      tokenSaleStartEnd: {
        start: tokenSaleStartEnd?.start,
        end: tokenSaleStartEnd?.end,
      },
      tokenStartPrice: tokenSaleStartEnd?.startPrice || 2,
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const fetchDlmPrice = async (symbol = '', startDate: string, endDate: string) => {
  try {
    const data = await fetch(`${apiUrl}/price/${symbol}?startDate=${startDate}&endDate=${endDate}`)
    const dataJson = await data.json<
      Array<{
        time: string
      }>
    >()
    return dataJson
  } catch (error) {
    console.error(error)
    return []
  }
}

export const claimAirdropTokens = async (ethAddress: string, token: string, origin?: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const payload: {
    ethAddress: string
    token: string
    origin?: string
  } = {
    ethAddress,
    token,
  }
  if (origin) {
    payload.origin = origin
  }
  await dvf.postAuthenticated<unknown>('/v1/trading/w/claimAirdrop', nonce, signature, payload)
}

export const getTokenPrices = async () => {
  try {
    const endpoint = `${apiUrl}/getUsdtPrices`
    const data = await fetch(endpoint)
    const dataJson = await data.json<TokenPricesState>()
    return dataJson
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const getBridgeLimits = async (token: string, chain: string, user?: string) => {
  const endpoint = `${tradingApi}/v1/trading/bridgeLimits`
  const params = new URLSearchParams({
    token,
    chain,
    ...(user ? { user } : {}),
  })

  const data = await fetch(`${endpoint}?${params.toString()}`)
  const result = await data.json<{
    token: string
    chain: string
    statusCode: number
    maxDepositAmount: string
    maxWithdrawalAmount: string
  }>()

  if (result.statusCode === 404) {
    return {
      token,
      chain,
      maxWithdrawalAmount: '0',
      maxDepositAmount: '0',
    }
  }
  return result
}

export type BitfinexTransfers = {
  items?: Array<{
    _id: string
    token: string
    amount: string
    source: string
    recipient: string
    date: string
    memo: string
    feeAmount: string
  }>
  pagination: {
    limit: number
    skip?: number
    totalItems: number
  }
}

export const getBitfinexTransfers = async (filters = {}) => {
  try {
    const dvf = await getDvf()
    return await dvf.bitfinex.transfers<Promise<BitfinexTransfers>>(null, filters)
  } catch (error) {
    console.error(error)
    return {
      items: [],
      pagination: {
        limit: 10,
        skip: 0,
        totalItems: 0,
      },
    }
  }
}

export const getBitfinexTransfer = async (id: string) => {
  try {
    const dvf = await getDvf()
    return await dvf.bitfinex.transfers<Promise<NonNullable<BitfinexTransfers['items']>[0]>>(id)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const shouldLogTracking = isFeatureEnabled(F_FLAGS_TYPES.DEV_LOG_TRACKING_EVENTS)
export const trackHeapEvent = (event: HeapEvent, data: Record<string, unknown> | string = {}) => {
  try {
    if (shouldLogTracking) {
      // eslint-disable-next-line no-console -- logging
      console.log('Heap Event:', event, data)
    }
    if (window.heap) {
      window.heap.track(event, data)
    }
  } catch (error) {
    console.error("Couldn't send tracked event to Heap.", error)
  }
}

// Identify Heap user session
export const identifyHeap = (address: string, walletType: string, isAutoConnected: boolean) => {
  try {
    if (window.heap) {
      window.heap.identify(address)
      window.heap.addUserProperties({ walletType })
      trackHeapEvent(heapEvents.connectWallet, {
        status: heapStatuses.success,
        walletType,
        isAutoConnected,
      })
    }
  } catch (error) {
    console.error("Couldn't identify Heap user session.", error)
  }
}

export const addUserPropertiesHeap = (data: Record<string, unknown>) => {
  try {
    if (window.heap) {
      window.heap.addUserProperties(data)
    }
  } catch (error) {
    console.error("Couldn't add user properties to Heap.", error)
  }
}

// Identify Userflow user session
export const identifyUserflow = (address: string, walletType: string) => {
  try {
    userflow.identify(address, {
      walletType,
      device_type: isMobileDevice() ? 'mobile' : 'desktop',
    })
    trackUserflowEvent(userflowEvents.connectWallet)
  } catch (error) {
    console.error("Couldn't identify Userflow user session.", error)
  }
}

export const getReferralId = async () => {
  try {
    const dvf = await getDvf()
    const { nonce, signature } = await getAuthenticationData(dvf)
    const result = await dvf.account.getReferralId(nonce, signature)
    return result?.referralId
  } catch (err) {
    console.error(err)
  }
}

export const getRemainingSpins = async () => {
  try {
    const dvf = await getDvf()
    const { nonce, signature } = await getAuthenticationData(dvf)
    const result = await dvf.account.getRemainingSpins(nonce, signature)
    return result?.remainingSpins
  } catch (err) {
    console.error(err)
  }
}

export const getReferralRewards = async () => {
  try {
    const dvf = await getDvf()
    const rewards = await dvf.account.getReferralRewards()
    if (rewards?.length) {
      // @ts-expect-error TS(2554): Expected 1-3 arguments, but got 0.
      return new Array(8).fill().map((_, i) => rewards[i % rewards.length])
    }
  } catch (err) {
    console.error(err)
  }
}

export const postReferralSpin = async (address: string) => {
  try {
    const dvf = await getDvf()
    const { nonce, signature } = await getAuthenticationData(dvf)
    const reward = await dvf.account.postReferralSpin(address, nonce, signature)
    return reward
  } catch (err) {
    console.error(err)
  }
}

export const executeCrossChainSwap = async (data: Record<string, unknown>) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.postAuthenticated<{ _id: string }>('/v1/trading/chainswap/swaps', nonce, signature, data)
}

export const getTopPerformersTokens = async ({ count, minMarketCap, chains }: GetTopPerformersRequest) => {
  const dvf = await getDvf()
  return dvf.topPerformersTokens({ count, minMarketCap, chains })
}
