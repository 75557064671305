import type { UnknownAction } from 'redux'
import type { ModalClearAction } from '../actions/modalActions/clearModal'
import type { ModalShowAction } from '../actions/modalActions/showModal'
import { MODAL_SHOW } from '../actions/modalActions'
import { ADDRESS_CHANGE, WALLET_CONNECTED, WALLET_DISCONNECT } from '../actions/types/user'

type ModalState = {
  transactionConfirmations: Array<{ gasLimit: number; resolveId: string; EIP1559: Array<unknown> }>
  accountPicker: boolean
  rhinoFiWrapped: boolean
  airdropClaim: boolean
  connectWallet: boolean
  deposit: boolean
  depositFeedback: boolean
  withdraw: boolean
  withdrawChain?: string
  migrateDTK: boolean
  legacyMigrateDTK: boolean
  token?: string
  simpleUIModal: boolean
  firstDlmBuy: boolean
  autoConnectWallet: null
  closeConfirmation: null
  yieldSuggestionModal: {
    open: false
    opportunity?: 'ALL'
  }
  firstDlmBuyDetails?: {
    token: string
    confirmPromise: Promise<unknown>
  }
  modal:
    | ModalShowAction['payload']
    | {
        modalShown: ''
        modalPayload: null
      }
}

export const getInitialState = (): ModalState => ({
  modal: { modalShown: '', modalPayload: null },
  transactionConfirmations: [],
  airdropClaim: false,
  accountPicker: false,
  connectWallet: false,
  deposit: false,
  rhinoFiWrapped: false,
  depositFeedback: false,
  withdraw: false,
  migrateDTK: false,
  legacyMigrateDTK: false,
  token: undefined,
  simpleUIModal: false,
  firstDlmBuy: false,
  autoConnectWallet: null,
  closeConfirmation: null,
  yieldSuggestionModal: {
    open: false,
  },
})

export type LegacyModalAction = {
  type:
    | typeof WALLET_DISCONNECT
    | typeof WALLET_CONNECTED
    | typeof ADDRESS_CHANGE
    | 'TOGGLE_SIMPLE_UI_MODAL'
    | 'TOGGLE_LEGACY_MIGRATE_DTK'
    | 'SHOW_NATIONALITY_MODAL'
    | 'SHIFT_TRANSACTION_CONFIRMATION'
    | 'ADD_TRANSACTION_CONFIRMATION'
    | 'TOGGLE_CONNECT_WALLET'
    | 'CLEAR_AUTO_CONNECT_WALLET'
    | 'TOGGLE_DEPOSIT'
    | 'TOGGLE_DEPOSIT_FEEDBACK'
    | 'CLOSE_CONNECT_WALLET'
    | 'TOGGLE_WITHDRAW'
    | 'TOGGLE_AIRDROP_CLAIM'
    | 'TOGGLE_FIRST_DLM_BUY'
    | 'TOGGLE_YIELD_SUGGESTION_MODAL'
    | 'INIT_CLOSE_CONFIRM'
    | 'CLEAR_CLOSE_CONFIRM'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
  payload: any
}

export type ModalAction = ModalShowAction | ModalClearAction | LegacyModalAction
// TODO FUD-819: remove modals from redux because of non-serialzable entities
export const modalReducer = (state = getInitialState(), rawAction: ModalAction | UnknownAction): ModalState => {
  const action = rawAction as ModalAction

  switch (action.type) {
    case MODAL_SHOW: {
      return {
        ...state,
        modal: action.payload,
      }
    }

    case 'MODAL_CLEAR': {
      return {
        ...state,
        modal: {
          modalShown: '',
          modalPayload: null,
        },
      }
    }

    case 'TOGGLE_CONNECT_WALLET': {
      const { autoConnectWallet } = action.payload
      return {
        ...state,
        connectWallet: !state.connectWallet,
        autoConnectWallet: autoConnectWallet,
      }
    }

    case 'CLOSE_CONNECT_WALLET': {
      return {
        ...state,
        connectWallet: false,
      }
    }

    case 'CLEAR_AUTO_CONNECT_WALLET': {
      return {
        ...state,
        autoConnectWallet: null,
      }
    }

    case 'TOGGLE_DEPOSIT': {
      const { token } = action.payload
      return {
        ...state,
        token,
        deposit: !state.deposit,
      }
    }

    case 'TOGGLE_DEPOSIT_FEEDBACK': {
      return {
        ...state,
        depositFeedback: !state.depositFeedback,
      }
    }

    case 'TOGGLE_WITHDRAW': {
      const { token } = action.payload
      return {
        ...state,
        token,
        withdraw: action.payload.forcedState !== undefined ? action.payload.forcedState : !state.withdraw,
        withdrawChain: action.payload.chain,
      }
    }

    case 'TOGGLE_AIRDROP_CLAIM': {
      return {
        ...state,
        airdropClaim: !state.airdropClaim,
      }
    }

    case 'ADD_TRANSACTION_CONFIRMATION': {
      const { transactionConfirmation } = action.payload
      return {
        ...state,
        transactionConfirmations: [...state.transactionConfirmations, transactionConfirmation],
      }
    }

    case 'SHIFT_TRANSACTION_CONFIRMATION': {
      return {
        ...state,
        transactionConfirmations: [...state.transactionConfirmations.slice(1)],
      }
    }

    case 'TOGGLE_LEGACY_MIGRATE_DTK': {
      return {
        ...state,
        legacyMigrateDTK: !state.legacyMigrateDTK,
      }
    }

    case 'TOGGLE_SIMPLE_UI_MODAL': {
      return {
        ...state,
        simpleUIModal: !state.simpleUIModal,
      }
    }

    case WALLET_CONNECTED:
    case WALLET_DISCONNECT:
    case ADDRESS_CHANGE: {
      return {
        ...state,
        deposit: false,
        transactionConfirmations: [],
        migrateDTK: false,
        token: 'ETH',
      }
    }

    case 'TOGGLE_FIRST_DLM_BUY': {
      return {
        ...state,
        firstDlmBuy: !state.firstDlmBuy,
        firstDlmBuyDetails: action.payload,
      }
    }

    case 'TOGGLE_YIELD_SUGGESTION_MODAL': {
      return {
        ...state,
        yieldSuggestionModal: action.payload,
      }
    }

    case 'INIT_CLOSE_CONFIRM': {
      return {
        ...state,
        closeConfirmation: action.payload,
      }
    }

    case 'CLEAR_CLOSE_CONFIRM': {
      return {
        ...state,
        closeConfirmation: null,
      }
    }

    default: {
      return state
    }
  }
}
