import { firstInPair, lastInPair } from '../../services/formatService'
import { getOrderStatus } from '../helperService/getOrderStatus'
import { getDvf } from '../dvfClient'
import { getAuthenticationData } from '../apiService'
import { convertUSD } from '../../utils/convertUSD'

type Meta = {
  feature: string
  platform: string
}

type OpenOrder = {
  _id: string
  isPostOnly: boolean
  isHidden: boolean
  isFillOrKill: boolean
  isSlippageDisabled: boolean
  user: string
  symbol: string
  amount: number
  type: string
  price: number
  feeRate: number
  meta: Meta
  tokenBuy: string
  tokenSell: string
  tokenSellLockedBalance: string
  createdAt: string
  updatedAt: string
  activatedAt: string
  active?: string | boolean
  awaitingSettlement: number
  totalBought: string
  totalFilled: number
  totalSold: string
  averagePrice: number
  amountOrig: number
  lastFillTime?: string
  canceled?: boolean
}

export const getOrders = async (symbol: string | null = null) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const orders = await dvf.getOrders<Promise<Array<OpenOrder>>>(symbol, nonce, signature)

  return orders.map((order) => {
    const isSellOrder = parseFloat(`${order.amount}`) < 0
    const first = convertUSD(firstInPair(order.symbol, true))
    const last = convertUSD(lastInPair(order.symbol, true))
    const [buying, selling] = isSellOrder ? [last, first] : [first, last]
    return {
      ...order,
      isSellOrder,
      buying,
      selling,
      amount: Math.abs(order.amount),
      totalFilled: Math.abs(order.totalFilled || 0),
      status: getOrderStatus(order.active, order.canceled, Math.abs(order.totalFilled || 0), Math.abs(order.amount)),
      ...(order.totalBought && {
        totalBought: Number(dvf.token.fromQuantizedAmount(order.tokenBuy, order.totalBought, false)),
      }),
    }
  })
}

export type ParsedOpenOrder = Awaited<ReturnType<typeof getOrders>>[0]
