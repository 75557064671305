import type { AppDispatch } from '../../store/configureStore'

export const clearModal = (dispatch: AppDispatch) => () => {
  dispatch({
    type: 'MODAL_CLEAR',
  })
}

export type ModalClearAction = {
  type: 'MODAL_CLEAR'
}
