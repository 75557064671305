import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { TokenMeta } from '../services/apiService/getTokenMeta'
import type { TokenCoingeckoMarketData } from '../services/externalApiService/getTokenMarketData'

type TokenMetaState = Record<
  string,
  { coingecko?: Partial<TokenCoingeckoMarketData>; crosschain?: TokenMeta } | undefined
>

const getInitialState = (): TokenMetaState => ({})

export const tokenMetaSlice = createSlice({
  name: 'tokenMeta',
  initialState: getInitialState(),
  reducers: {
    addCoingeckoTokenMeta: (
      state,
      action: PayloadAction<{
        token?: string
        meta: Partial<TokenCoingeckoMarketData>
        tokenAddress: string
      }>,
    ) => {
      const { tokenAddress, token, meta } = action.payload
      const tokenMeta = state[tokenAddress]
      const newMeta = {
        ...tokenMeta,
        coingecko: meta,
      }
      return {
        ...state,
        [tokenAddress]: newMeta,
        ...(token ? { [token]: newMeta } : {}),
      }
    },
    addCrosschainTokenMeta: (
      state,
      action: PayloadAction<{ token: string; meta: TokenMeta; tokenAddress?: string }>,
    ) => {
      const { tokenAddress, token, meta } = action.payload
      const tokenMeta = state[token]
      const newMeta = {
        ...tokenMeta,
        crosschain: meta,
      }
      return {
        ...state,
        [token]: newMeta,
        ...(tokenAddress ? { [tokenAddress]: newMeta } : {}),
      }
    },
  },
})
