const table = {
  mts: 0,
  open: 1,
  close: 2,
  high: 3,
  low: 4,
  volume: 5,
} as const

export type RawCandle = [number, number, number, number, number, number]
export type AdaptedCandle = {
  mts: number
  open: number
  close: number
  high: number
  low: number
  volume: number
}

// converts data row into object row
// ex. [x, y, z, ...] => { mts: x, open: y, close: z, ... }
export function candlesAdapter(rawCandle: RawCandle): AdaptedCandle {
  const keys = Object.keys(table) as Array<keyof typeof table>

  return keys.reduce(
    (accumulator, current) => {
      accumulator[current] = rawCandle[table[current]]
      return accumulator
    },
    {
      mts: 0,
      open: 0,
      close: 0,
      high: 0,
      low: 0,
      volume: 0,
    },
  )
}

// converts data matrix into object
// ex. [ [...],[...], ... ] => { mts_1:{...}, mts_2:{...}, ... }
export const candlesSnapshot = (data: Array<RawCandle>) => {
  const list = data.map && data.map((row) => candlesAdapter(row))

  return list.reduce(
    (accumulator, current) => {
      accumulator[current.mts] = current
      return accumulator
    },
    {} as Record<string, AdaptedCandle>,
  )
}
