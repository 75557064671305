import * as Paradex from '@paradex/sdk'
import { ethers } from 'ethers'
import { InjectedConnector } from 'starknetkit/injected'
import { connect } from 'starknetkit'
import { RpcProvider } from 'starknet'
import { NETWORKS } from '../../../constants/types'
import { getProvider } from '../../wallets/wallet'
import { setParadexProvider } from '../providers/paradexProvider'
import { envConfig } from '../../../env/envConfig'
import { getChainRpcUrl } from '../../ethereum/chainProviders'
import type { SecondaryConnectionPayload } from './connectors.types'

const { configEnv } = envConfig

export const ParadexWalletOptions = {
  EVM: 'EVM',
  STARKNET: 'STARKNET',
}

export const connectToParadex = async (walletOption: string): Promise<SecondaryConnectionPayload> => {
  const configType = configEnv === 'production' ? 'prod' : 'testnet'
  const config = await Paradex.Config.fetchConfig(configType)
  const paraclearProvider = new Paradex.ParaclearProvider.DefaultProvider(config)

  if (walletOption === ParadexWalletOptions.STARKNET) {
    const { wallet } = await connect({
      modalMode: 'alwaysAsk',
      dappName: 'rhino.fi',
      connectors: [
        new InjectedConnector({
          options: {
            id: 'argentX',
            provider: new RpcProvider({
              nodeUrl: getChainRpcUrl(NETWORKS.STARKNET),
            }),
          },
        }),
        new InjectedConnector({
          options: {
            id: 'braavos',
            provider: new RpcProvider({
              nodeUrl: getChainRpcUrl(NETWORKS.STARKNET),
            }),
          },
        }),
      ],
    })
    if (!wallet || !wallet.selectedAddress) {
      throw new Error('Something went wrong when connecting your Starknet wallet.')
    }
    const account = await Paradex.Account.fromStarknetAccount({
      provider: paraclearProvider,
      config,
      account: wallet.account,
    })
    setParadexProvider(paraclearProvider, account, config)

    return {
      address: account.address,
      networkId: '0',
      domain: '',
      connectedChain: NETWORKS.PARADEX,
    }
  } else if (walletOption === ParadexWalletOptions.EVM) {
    const provider = getProvider()
    const ethersProvider = new ethers.BrowserProvider(provider)
    const ethersSigner = await ethersProvider.getSigner()
    const signer = Paradex.Signer.ethersSignerAdapter(ethersSigner)
    const account = await Paradex.Account.fromEthSigner({ provider: paraclearProvider, config, signer })

    setParadexProvider(paraclearProvider, account, config)

    return {
      address: account.address,
      networkId: '0',
      domain: '',
      connectedChain: NETWORKS.PARADEX,
    }
  } else {
    throw new Error('Invalid wallet option')
  }
}
