import memoizeOne from 'memoize-one'
import { poseidonHashMany } from 'micro-starknet'
import { translate } from '../intl/i18n'
import { getDvf } from './dvfClient'
import { parsePrivateKey } from './wallets/legacyService'
import { reportToSentry } from './helperService/reportToSentry'
import { getDTKPrivateKey } from './tradingKeyService/webWalletTradingKey'
import type { WalletPayload } from './wallets/wallet'
import { WalletFlows } from './wallets/walletFlows'

export const getLedgerPublicKey = async (path: string | undefined) => {
  const dvf = await getDvf()

  return dvf.stark.ledger.getPublicKey<{ x: string }>(path)
}

export const getStarkKeys = async (privateKey: string | null | undefined) => {
  const dvf = await getDvf()
  if (!privateKey) {
    throw new Error(translate('errors.trading_key_not_imported'))
  }
  return dvf.stark.createKeyPair(privateKey)
}

export const walletToStarkKey = async (wallet: WalletPayload) => {
  if (!wallet) {
    throw new Error(translate('errors.wallet_not_supported'))
  }
  const { privateKey, tradingKey, path, walletType, isLegacy } = wallet
  let starkKey
  let encryptedTradingKey

  if (WalletFlows.EVMWALLET.includes(walletType)) {
    if (!tradingKey?.privateKey) {
      throw new Error(translate('errors.trading_key_not_imported'))
    }
    const privateKeyWebWallet = getDTKPrivateKey(wallet)
    const metamaskKeys = await getStarkKeys(privateKeyWebWallet)
    starkKey = metamaskKeys.starkPublicKey
    if (tradingKey?.encryptedTradingKey) {
      ;({ encryptedTradingKey } = tradingKey)
    }
  } else if (WalletFlows.LEDGER.includes(walletType)) {
    starkKey = await getLedgerPublicKey(path)
  } else if (WalletFlows.KEYSTORE.includes(walletType)) {
    if (!privateKey) {
      throw new Error(translate('errors.trading_key_not_imported'))
    }
    const privateKeyKeystore = parsePrivateKey(privateKey, isLegacy)
    const keys = await getStarkKeys(privateKeyKeystore)
    starkKey = keys.starkPublicKey
  } else {
    throw new Error(translate('errors.wallet_not_supported'))
  }

  return { starkKey, encryptedTradingKey }
}

export const checkIsLegacyStarkKey = async (address: string, starkKey: string) => {
  const dvf = await getDvf()
  const args = [`0x${starkKey}`]
  try {
    const ethAddressFromStark = await dvf.eth.call<Promise<string>>(
      dvf.contract.abi.getStarkEx(),
      dvf.config.DVF.starkExContractAddress,
      'getEthKey',
      args,
    )
    if (ethAddressFromStark === '0x0000000000000000000000000000000000000000') {
      return false
    }
    return ethAddressFromStark.toLowerCase() !== address.toLowerCase()
  } catch (error) {
    reportToSentry(error)
    return false
  }
}

export const verifyOnchainRegistration = memoizeOne(async (address, starkKey) => {
  const dvf = await getDvf()
  let ethAddressFromStark
  const args = [`0x${starkKey}`]
  try {
    ethAddressFromStark = await dvf.eth.call(
      dvf.contract.abi.getStarkEx(),
      dvf.config.DVF.starkExContractAddress,
      'getEthKey',
      args,
    )
  } catch (error) {
    console.error(error)
    if ((error as Error)?.message?.indexOf('USER_UNREGISTERED') >= 0) {
      return false
    }
    reportToSentry(error)
    // Fail silently
    return true
  }

  return ethAddressFromStark.toLowerCase() === address.toLowerCase()
})

export const starkSign = async (starkPrivateKey: string, starkMessage: string) => {
  const dvf = await getDvf()
  const { starkKeyPair } = await dvf.stark.createKeyPair(starkPrivateKey)

  return dvf.stark.sign(starkKeyPair, starkMessage)
}

export const ledgerStarkSign = async (address: string, starkMessage: string) => {
  const dvf = await getDvf()

  const { default: TransportWebHid } = await import('@ledgerhq/hw-transport-webhid')
  const { default: Eth } = await import('@ledgerhq/hw-app-eth')
  let transport

  try {
    transport = await TransportWebHid.create()
    const eth = new Eth(transport)
    const starkPath = dvf.stark.ledger.getPath(address)
    const parsedStarkMessage = `0x${starkMessage.padEnd(64, '0').substr(-64)}`
    const signature = (await eth.starkUnsafeSign(starkPath, parsedStarkMessage)) as { r: string; s: string }
    await transport.close()
    return signature
  } catch (error) {
    if (transport) {
      await transport.close()
    }
    throw error
  }
}

export const hashPoseidon = (values: string[]) => {
  return poseidonHashMany(values.map((value) => BigInt(value))).toString(16)
}
