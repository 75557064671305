export const SigningMethod = {
  v3: 'V3',
  v4: 'V4',
  v4_plain: 'V4_PLAIN',
  v4_default: 'V4_DEFAULT',
}

// On mainnet, include an extra onlySignOn parameter.
export const EIP712_SIGNATURE_STRUCT_v2 = [
  {
    type: 'string',
    name: 'action',
  },
  {
    type: 'string',
    name: 'onlySignOn',
  },
]

export const EIP712_SIGNATURE_STRUCT_TESTNET_v2 = [
  {
    type: 'string',
    name: 'action',
  },
]

export const EIP712_MESSAGE_DEVERSIFI_v2 = {
  action: 'Access DeversiFi account',
  onlySignOn: 'https://app.deversifi.com',
}
export const EIP712_MESSAGE_TESTNET_DEVERSIFI_v2 = { action: 'Access DeversiFi account' }

export const EIP712_MESSAGE_RHINO_v3 = {
  action: 'Access your rhino.fi account',
  onlySignOn: 'app.rhino.fi',
}

export const EIP712_DOMAIN_v2 = [
  {
    name: 'name',
    type: 'string',
  },
  {
    name: 'version',
    type: 'string',
  },
  {
    name: 'chainId',
    type: 'uint256',
  },
]

export const EIP712_DOMAIN_v3 = [
  {
    name: 'name',
    type: 'string',
  },
  {
    name: 'version',
    type: 'string',
  },
]
