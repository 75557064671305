import { shallowEqual } from 'react-redux'
import { createSelector, lruMemoize } from 'reselect'
import { APP_WALLETS } from '../../constants/types'
import { shortenAddress } from '../../services/helperService/shortenAddress'
import { shortenEnsName } from '../../services/helperService/shortenEnsName'
import { NameServices } from '../../services/nameServices/nameServices'
import type { WalletPayload } from '../../services/wallets/wallet'
import type { RootState } from '../../store/configureStore'

export const selectWallet = (state: RootState) => state.user.wallet.data
export const selectWalletState = (state: RootState) => state.user.wallet
export const selectWalletType = (state: RootState) => state.user.wallet.data?.walletType
export const selectWalletError = (state: RootState) => state.user.wallet.error
export const selectAddress = (state: RootState) => state.user.wallet.data?.address
export const selectIsRegistered = (state: RootState) => state.user.wallet.isRegistered
export const selectL1RegistrationSignatureStatus = (state: RootState) => state.user.l1RegistrationSignatureStatus
export const selectShortenedAddress = (state: RootState) => shortenAddress(state.user.wallet.data?.address, false)
export const selectShortenedMoreAddress = (state: RootState) => shortenAddress(state.user.wallet.data?.address, true)
export const selectIsWalletConnected = (state: RootState) => !!state.user.wallet.data
export const selectIsConnecting = (state: RootState) => state.user.wallet.isConnecting
export const selectIsAuthenticated = (state: RootState) => state.user.authentication.isAuthenticated
export const selectContractWallet = (state: RootState) => state.user.contractWallet
export const selectAuth = (state: RootState) => state.user.authentication
export const selectTradingKey = (state: RootState) => state.user.tradingKey.data
export const selectTradingKeyState = (state: RootState) => state.user.tradingKey
export const selectStarkPublicKey = (state: RootState) => state.user.wallet.starkPublicKey
export const selectConnectedNetwork = (state: RootState) => state.user.network
export const selectSignatureAddress = (state: RootState) => state.portal.signatureAddress
export const selectUserWalletName = (state: RootState) => state.user.wallet.name

export const selectHasTradingKey = createSelector(selectWalletType, selectTradingKey, (walletType, tradingKey) => {
  if (!walletType) {
    return false
  }
  return APP_WALLETS.includes(walletType) ? true : !!tradingKey?.privateKey
})

// Extends the wallet object with the tradingKey - to be used when passing to api services temporarily
export const selectWalletPayload = createSelector(
  [selectWallet, selectTradingKey, selectStarkPublicKey],
  (wallet, tradingKey, starkPublicKey): WalletPayload =>
    wallet !== null
      ? {
          ...wallet,
          tradingKey,
          starkPublicKey,
        }
      : null,
  {
    memoize: lruMemoize,
    memoizeOptions: {
      equalityCheck: shallowEqual,
    },
  },
)

export const selectNsNames = (state: RootState) => state.user.nsNames
// priority on ENS above ZKNS
export const selectNameServiceName = createSelector(selectNsNames, (nsNames) => {
  if (nsNames[NameServices.ENS]) {
    return nsNames[NameServices.ENS]
  }
  if (nsNames[NameServices.ZKNS]) {
    return nsNames[NameServices.ZKNS]
  }
  return ''
})
export const selectShortenedNsName = createSelector(selectNameServiceName, (nsName) => shortenEnsName(nsName))

export const selectHasFinishedWalletConnection = createSelector(
  selectWalletType,
  selectTradingKey,
  selectIsAuthenticated,
  (walletType, tradingKey, isAuthenticated) => {
    if (!walletType) {
      return false
    }
    if (APP_WALLETS.includes(walletType)) {
      return isAuthenticated
    }
    return !!tradingKey?.privateKey
  },
)
