import type { ToBufferInputTypes } from 'ethereumjs-util'
import { ecsign, hashPersonalMessage, toBuffer, toRpcSig } from 'ethereumjs-util'
import Wallet from 'ethereumjs-wallet'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'web3... Remove this comment to see the full error message
import HookedWalletSubprovider from 'web3-provider-engine/subproviders/hooked-wallet'
import type { BaseProvider } from '@metamask/providers'
import type { TransactionConfig } from 'web3-core'
import { WALLETS } from '../../constants/types'
import WalletDecryptWorker from '../../workers/walletDecrypt.worker?worker'
import WalletEncryptWorker from '../../workers/walletEncrypt.worker?worker'
import { sendWalletFailedEvent } from '../apiService'
import { approveTransaction, EIP1559GasFeeEstimation } from '../ethereumService'
import { WEI_MAX_LIMIT } from '../../constants/portalConfig'
import { INVALID_KEYSTORE_TYPE, SIGN_MESSAGE, SIGN_TRANSACTION } from '../../constants/walletEvents'
import { getChainNameForNetworkId } from '../ethereum/chainProviders'
import { isValidWalletError } from '../helperService/isValidWalletError'
import { getWeb3 } from './wallet'

export type UnlockedKeystoreWallet = {
  address: string
  privateKey: string
  isLegacy: boolean
  isBackedUp?: boolean
  type?: string
  generated?: string
  timestamp?: number
  error?: { message: string }
}

export type KeystoreProviderType = BaseProvider
export class KeystoreProvider extends HookedWalletSubprovider {
  address: string
  privateKey: string
  constructor(privateKey: string, address: string) {
    super({
      getAccounts: (cb: (error: undefined | null | unknown | Error, value: string[]) => void) =>
        cb(null, [this.address]),
      signPersonalMessage: signMessage(privateKey),
      signTransaction: signTransaction(privateKey),
      signMessage: signMessage(privateKey),
      approveTransaction: approveTransaction,
    })
    this.address = address
    this.privateKey = privateKey
  }
}

export const KeystoreTypes = {
  presale: 'presale',
  utc: 'v2-v3-utc',
  v1Unencrypted: 'v1-unencrypted',
  v1Encrypted: 'v1-encrypted',
  v2Unencrypted: 'v2-unencrypted',
  generatedRaw: 'generated_raw',
}

export const getKeystoreWallet = (keystore: unknown, password: unknown): Promise<UnlockedKeystoreWallet> => {
  const worker = new WalletDecryptWorker()
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- coming from JS file
    worker.addEventListener('message', async (event: any) => {
      if (event.error) {
        return reject(event.error)
      }
      resolve(event.data)
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- coming from JS file
    worker.addEventListener('error', function (error: any) {
      reject(error)
    })
    worker.postMessage({ keystore, password })
  })
}

const signMessage =
  (privateKey: string) =>
  (
    msgParams: { data: ToBufferInputTypes },
    cb: (error: undefined | null | unknown | Error, value?: string) => void,
  ) => {
    try {
      const hash = hashPersonalMessage(toBuffer(msgParams.data))
      const signed = ecsign(hash, toBuffer(privateKey))
      const signature = toRpcSig(signed.v, signed.r, signed.s)
      cb(null, signature)
    } catch (error) {
      if (isValidWalletError(error)) {
        sendWalletFailedEvent(WALLETS.KEYSTORE, SIGN_MESSAGE)
      }
      cb(error)
    }
  }

let lastNonce: number | null = null

type TxParams = TransactionConfig & {
  maxPriorityFee: string
}
type RawTxParams = Omit<TxParams, 'nonce'> & { nonce?: string | number; gasLimit?: string }
const signTransaction =
  (privateKey: string) =>
  async (txParams: TxParams, cb: (error: undefined | null | unknown | Error, value?: string) => void) => {
    const { DEFAULT: web3 } = getWeb3()
    const { FeeMarketEIP1559Transaction, Transaction } = await import('@ethereumjs/tx')
    const chainId = Number(txParams.chainId)
    const isEthereum = (getChainNameForNetworkId(chainId) ?? 'ETHEREUM') === 'ETHEREUM'

    let common
    if (isEthereum) {
      const { default: Common, Hardfork } = await import('@ethereumjs/common')
      common = new Common({
        chain: chainId,
        hardfork: Hardfork.London,
      })
    }

    try {
      const rawTx: RawTxParams = { ...txParams }
      const gasLimit = await web3.eth.estimateGas(txParams)
      rawTx.gasLimit = web3.utils.numberToHex(gasLimit)

      // cast - parsing number results in a number, parsing undefined is NaN
      if (lastNonce === parseInt(rawTx.nonce as unknown as string)) {
        rawTx.nonce = web3.utils.numberToHex(lastNonce + 1)
      }

      if (isEthereum) {
        const { baseFee } = await EIP1559GasFeeEstimation()
        const baseFeeInWei = Math.floor(baseFee * 10 ** 9)
        const maxPriorityFeePerGas = txParams.maxPriorityFee
        rawTx.maxPriorityFeePerGas = web3.utils.toHex(maxPriorityFeePerGas)
        rawTx.maxFeePerGas = web3.utils.toHex(baseFeeInWei + parseInt(txParams.maxPriorityFee))
        // @ts-expect-error -- gasPrice is supposed to be undefined, but TransactionConfig has it as optional
        const tx = FeeMarketEIP1559Transaction.fromTxData(rawTx, { common })

        if (parseInt(rawTx.maxFeePerGas) > WEI_MAX_LIMIT) {
          throw new Error('The transaction is overpriced!')
        }

        let signedTx = tx.sign(toBuffer(privateKey))
        // cast - parsing number results in a number, parsing undefined is NaN
        lastNonce = parseInt(rawTx.nonce as unknown as string)
        cb(null, `0x${signedTx.serialize().toString('hex')}`)
      } else {
        const tx = Transaction.fromTxData(rawTx)
        tx.sign(toBuffer(privateKey))
        // cast - parsing number results in a number, parsing undefined is NaN
        lastNonce = parseInt(rawTx.nonce as unknown as string)
        cb(null, `0x${tx.serialize().toString('hex')}`)
      }
    } catch (error) {
      if (isValidWalletError(error)) {
        sendWalletFailedEvent(WALLETS.KEYSTORE, SIGN_TRANSACTION)
      }
      cb(error)
    }
  }

export type Keystore = {
  encseed?: unknown
  Crypto?: unknown
  crypto?: unknown
  hash?: unknown
  locked?: boolean
  publisher?: unknown
  type?: unknown
  address?: string
}

export const determineKeystoreType = (keystore: string | Keystore) => {
  const parsed = typeof keystore === 'string' ? JSON.parse<Keystore>(keystore) : keystore
  if (parsed.encseed) {
    return KeystoreTypes.presale
  }
  if (parsed.Crypto || parsed.crypto) {
    return KeystoreTypes.utc
  }
  if (parsed.hash && parsed.locked === true) {
    return KeystoreTypes.v1Encrypted
  }
  if (parsed.hash && parsed.locked === false) {
    return KeystoreTypes.v1Unencrypted
  }
  if (parsed.publisher === 'MyEtherWallet') {
    return KeystoreTypes.v2Unencrypted
  }
  if (parsed.type === 'generated_raw') {
    return KeystoreTypes.generatedRaw
  }
  sendWalletFailedEvent(WALLETS.KEYSTORE, INVALID_KEYSTORE_TYPE)
  throw new Error('Invalid keystore')
}

export const isKeystorePassRequired = (keystore: string | Keystore) => {
  const keystoreType = determineKeystoreType(keystore)
  return (
    keystoreType === KeystoreTypes.presale ||
    keystoreType === KeystoreTypes.v1Encrypted ||
    keystoreType === KeystoreTypes.utc
  )
}

export const cacheKeystore = (keystore: Keystore) => {
  const cachedWallets = JSON.parse<string[]>(localStorage.getItem('cached-wallets') || '[]')
  if (!cachedWallets.find((wallet) => JSON.parse<{ address: string }>(wallet).address === keystore.address)) {
    cachedWallets.push(JSON.stringify(keystore))
    localStorage.setItem('cached-wallets', JSON.stringify(cachedWallets))
  }
}

export const uncacheKeystore = (address: string) => {
  let cachedWallets = JSON.parse<string[]>(localStorage.getItem('cached-wallets') || '[]')
  cachedWallets = cachedWallets.filter((wallet) => JSON.parse<{ address?: string }>(wallet).address !== address)
  localStorage.setItem('cached-wallets', JSON.stringify(cachedWallets))
}

export const getCachedKeystores = (): UnlockedKeystoreWallet[] =>
  JSON.parse<string[]>(localStorage.getItem('cached-wallets') || '[]').map((keystore) =>
    JSON.parse<UnlockedKeystoreWallet>(keystore),
  )

type CachedWallet = {
  address: string
  isLegacy?: boolean
  isBackedUp?: boolean
}

export const markAsBackedUp = (address: string) => {
  const cachedWallets = JSON.parse<string[]>(localStorage.getItem('cached-wallets') || '[]')
  const walletIndex = cachedWallets.findIndex((wallet) => JSON.parse<{ address: string }>(wallet).address === address)
  if (walletIndex === -1) {
    throw new Error('Keystore not found')
  }
  const cachedWallet = JSON.parse<CachedWallet>(cachedWallets[walletIndex])
  cachedWallet.isBackedUp = true
  cachedWallets[walletIndex] = JSON.stringify(cachedWallet)
  localStorage.setItem('cached-wallets', JSON.stringify(cachedWallets))
}

export const updateKeystore = (address: string | undefined, data?: Partial<CachedWallet>) => {
  const cachedWallets = JSON.parse<string[]>(localStorage.getItem('cached-wallets') || '[]')
  const walletIndex = cachedWallets.findIndex((wallet) => JSON.parse<CachedWallet>(wallet).address === address)
  if (walletIndex === -1) {
    throw new Error('Keystore not found')
  }
  let cachedWallet = JSON.parse<CachedWallet>(cachedWallets[walletIndex])
  cachedWallet = {
    ...cachedWallet,
    ...data,
  }
  cachedWallets[walletIndex] = JSON.stringify(cachedWallet)
  localStorage.setItem('cached-wallets', JSON.stringify(cachedWallets))
}

export const generateWallet = (auto = false, shouldCache = true) => {
  const generated = Wallet.generate()
  const keystore = {
    type: 'generated_raw',
    address: generated.getAddressString(),
    privateKey: generated.getPrivateKeyString(),
    generated: auto ? 'auto_generated' : 'manual',
    timestamp: Date.now(),
  }
  if (shouldCache) {
    cacheKeystore(keystore)
  }
  return keystore
}

export const encryptPrivateKey = async (pk: string, password: string) => {
  const worker = new WalletEncryptWorker()
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- coming from JS file
    worker.addEventListener('message', function (event: any) {
      resolve(event.data)
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- coming from JS file
    worker.addEventListener('error', function (error: any) {
      reject(error)
    })
    worker.postMessage({ pk, password })
  })
}
