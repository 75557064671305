import { request } from '@rhino.fi/dvf-utils'
import { envConfig } from '../../env/envConfig'
import type { CrossChainTokensState } from '../../reducers/types/CrossChainTokensState'

export const getCrossChainTokens = async () => {
  try {
    const { tradingApi } = envConfig
    const data = await request.get<CrossChainTokensState>(`${tradingApi}/v1/trading/chainswap/tokens`)

    return data
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error("couldn't fetch cross chain tokens: ", error)
    }
    return []
  }
}
