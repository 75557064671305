import type { UnifiedTokenRegistryState } from '../../reducers/types/UnifiedTokenRegistryState'

export const getCrossChainChainsFromRegistry = (tokenRegistry: UnifiedTokenRegistryState) => {
  const tokenRegistryValues = Object.values(tokenRegistry)

  return tokenRegistryValues.reduce((acc, tokenConfig) => {
    if (tokenConfig.xchain && 'chain' in tokenConfig && !acc.includes(tokenConfig.chain)) {
      acc.push(tokenConfig.chain)
    }
    return acc
  }, [] as string[])
}
