import _intersection from 'lodash/intersection'
import { getChainNativeSafeguard, getChainNativeToken } from '../services/ethereum/chainProviders'
import { getExchangeBalances } from '../services/apiService'
import { getCrossChainBalances } from '../services/crossChainSwapServiceNew/getCrossChainBalances'
import { splitSettledPromises } from '../utils/splitSettledPromises/splitSettledPromises'
import { getBlockchainBalanceForChain, supportedChains } from '../services/ethereum/blockchainBalances'
import type { AppDispatch } from '../store/configureStore'
import type { ExchangeBalanceState } from '../reducers/types/ExchangeBalanceState'
import type { UnifiedTokenRegistryState } from '../reducers/types/UnifiedTokenRegistryState'
import { portalSlice } from '../reducers/portalSlice'
import { getCrossChainChainsFromRegistry } from '../services/helperService/getCrossChainChainsFromRegistry'
import { getDepositChainsFromRegistry } from '../services/helperService/getDepositChainsFromRegistry'

export const fetchBlockchainBalances =
  (dispatch: AppDispatch) =>
  async (
    address: string,
    secondaryWallet: { secondaryWalletAddress: string; connectedChain: string },
    tokenRegistry: UnifiedTokenRegistryState,
    enabledBridges: string[],
    withBalanceTimeout = false,
  ) => {
    try {
      const crossChainChains = getCrossChainChainsFromRegistry(tokenRegistry)
      const bridgeChains = getDepositChainsFromRegistry(tokenRegistry)
      const enabledBridgeChains = _intersection(bridgeChains, enabledBridges)

      const chains = [...enabledBridgeChains, ...crossChainChains]
      const allChains = chains.filter((chain) => supportedChains(chain, secondaryWallet.connectedChain))
      allChains.map((chain) =>
        fetchBlockChainBalanceForChain(dispatch)({
          chain,
          address,
          secondaryWallet,
          tokenRegistry,
          withBalanceTimeout,
        }),
      )
    } catch (error) {
      console.error('Failed fetching blockchain balances.', error)
    }
  }

export const fetchBlockChainBalanceForChain =
  (dispatch: AppDispatch) =>
  async ({
    chain,
    address,
    secondaryWallet,
    tokenRegistry,
    withBalanceTimeout = false,
  }: {
    chain: string
    address: string
    secondaryWallet: { secondaryWalletAddress: string; connectedChain: string }
    tokenRegistry: UnifiedTokenRegistryState
    withBalanceTimeout?: boolean
  }) => {
    try {
      const balance = await getBlockchainBalanceForChain(
        chain,
        address,
        secondaryWallet,
        tokenRegistry,
        withBalanceTimeout,
      )
      const nativeBalance = parseFloat(balance?.[chain]?.[getChainNativeToken(chain)]?.balance ?? '0')
      const hasBalanceForTransaction = nativeBalance > getChainNativeSafeguard(chain)

      dispatch(
        portalSlice.actions.updateBlockchainBalance({
          blockchainBalance: balance,
          failedChains: { [chain]: false },
          hasBalanceForTransaction: { [chain]: hasBalanceForTransaction },
        }),
      )
    } catch (error) {
      dispatch(
        portalSlice.actions.updateBlockchainBalance({
          blockchainBalance: {},
          failedChains: { [chain]: true },
          hasBalanceForTransaction: { [chain]: false },
        }),
      )
    }
  }

export const fetchCrossChainBalanceQuantized = (dispatch: AppDispatch) => async () => {
  const crossChainBalanceQuantized = await getCrossChainBalances()
  dispatch(portalSlice.actions.updateCrossChainBalanceQuantized({ crossChainBalanceQuantized }))
}

export const fetchExchangeBalances = (dispatch: AppDispatch) => async () => {
  try {
    const settled = await Promise.allSettled([getExchangeBalances(), fetchCrossChainBalanceQuantized(dispatch)()])
    const [exchangeBalanceStarkEx] = splitSettledPromises(settled, true).fulfilled
    let exchangeBalance: ExchangeBalanceState = exchangeBalanceStarkEx?.value || {}
    dispatch(portalSlice.actions.updateExchangeBalance({ exchangeBalance }))
    return { exchangeBalance }
  } catch (error) {
    console.error('Failed fetching exchange balances.', error)
  }
}
