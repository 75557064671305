import { createListenerMiddleware } from '@reduxjs/toolkit'
import type { RootState } from '../store/configureStore'

import {
  backupL1RegistrationSignatureWalletConnectedListener,
  backupL1RegistrationSignatureSetTradingKeyListener,
} from '../listeners/backupL1RegistrationSignature'
import { userSlice } from '../reducers/userSlice'
import type { AppDispatch } from '../store/store.types'

export const l1RegistrationWalletConnectedMiddleware = createListenerMiddleware<RootState, AppDispatch>()
export const l1RegistrationTradingKeySetMiddleware = createListenerMiddleware<RootState, AppDispatch>()

l1RegistrationWalletConnectedMiddleware.startListening({
  matcher: userSlice.actions.setAuthenticated.match,
  effect: backupL1RegistrationSignatureWalletConnectedListener,
})
l1RegistrationTradingKeySetMiddleware.startListening({
  matcher: userSlice.actions.setTradingKey.match,
  effect: backupL1RegistrationSignatureSetTradingKeyListener,
})
