import { WALLETS } from '../../../constants/types'
import { setUpWeb3 } from '../../web3/web3Service'
import type { KeystoreProviderType } from '../keystoreService'
import { cacheKeystore, KeystoreProvider } from '../keystoreService'
import { isLegacyKeystore } from '../legacyService'
import { setDvf } from '../../dvfClient'
import type { AppDispatch } from '../../../store/store.types'
import { getUser } from '../../v3Api/getUser'

export class KeystoreWallet {
  public provider: KeystoreProviderType | null = null
  public walletType = WALLETS.KEYSTORE
  public unlockedKeystore: { isLegacy?: boolean } = {}
  public address = ''
  public privateKey = ''
  public isLegacy: boolean | undefined = false

  constructor(keystore: { privateKey: string; address: string; isLegacy?: boolean }) {
    this.unlockedKeystore = keystore
    cacheKeystore(keystore)
    this.privateKey = keystore.privateKey
    this.address = keystore.address.toLowerCase()
  }

  // This method needs to be called on instantiation
  public async connect({ dispatch }: { dispatch: AppDispatch }) {
    await this.setProvider()
    const { web3 } = await this.checkIsLegacyAndSetupWeb3({ dispatch })
    return { web3, provider: this.provider }
  }

  private async checkIsLegacyAndSetupWeb3({ dispatch }: { dispatch: AppDispatch }) {
    const web3 = await setUpWeb3({ provider: this.provider, isSubprovider: true })

    await setDvf({ web3, autoSelectAccount: true })
    // keystore reports the same address as is used for signatures so we can use it
    const { isRegistered } = await getUser(this.address)
    this.isLegacy = await isLegacyKeystore(this.address, this.unlockedKeystore, isRegistered, WALLETS.KEYSTORE)
    return {
      web3,
    }
  }

  private async setProvider() {
    this.provider = new KeystoreProvider(this.privateKey, this.address) as unknown as KeystoreProviderType
  }

  public toJSON() {
    return {
      address: this.address,
      walletType: this.walletType,
      privateKey: this.privateKey,
      isLegacy: this.isLegacy,
      name: this.walletType,
    }
  }
}
