import { get } from 'lodash'

const forbiddenStrings = [
  'device is already open',
  "Failed to execute 'requestDevice'",
  'denied by the user',
  '0x6d02',
  'User denied transaction signature',
  'User denied message signature',
]

export const isValidWalletError = (error: unknown) => {
  try {
    if (typeof error === 'string') {
      return !forbiddenStrings.some((forbiddenString) => error.includes(forbiddenString))
    }

    return (
      !get(error, ['message']).includes('User denied transaction signature') &&
      !get(error, ['message']).includes('6985') &&
      !(error as Error).toString().includes('device is already open') &&
      !(error as Error).toString().includes("Failed to execute 'requestDevice'") &&
      !(error as Error).toString().includes('denied by the user') &&
      !(error as Error).toString().includes('User denied message signature') &&
      !(error as Error).toString().includes('0x6d02')
    )
  } catch (newError) {
    return false
  }
}
