import type Web3 from 'web3'
import type { ContractCallContext } from 'ethereum-multicall'
import { Multicall } from 'ethereum-multicall'
import Decimal from 'decimal.js'
import erc20Abi from '../abis/erc20Abi'
import type { UnifiedTokenRegistryState } from '../../reducers/types/UnifiedTokenRegistryState'
import { tokenRegistryForChain } from '../helperService'
import { envConfig } from '../../env/envConfig'
import type { AddressEntry } from './blockchainBalances'

const { multicallAdditionalContracts, multiCallDoNotAggregate } = envConfig

export type MulticallBalance = {
  balance: string
  token: string
}

export type MulticallBalances = {
  [chain: string]: Record<string, MulticallBalance>
}

export const multiCallBalances = async (
  web3: Web3,
  address: string,
  tokenAddresses: AddressEntry[],
  chain: string,
  tokenRegistry: UnifiedTokenRegistryState,
  withTimeout = false,
) =>
  new Promise((resolve, reject) => {
    if (!tokenAddresses.length) {
      resolve({})
      return
    }

    // Init multicall
    const customContract = (multicallAdditionalContracts as Record<string, string>)[chain]
    const multicall = new Multicall({
      web3Instance: web3,
      tryAggregate: !multiCallDoNotAggregate.includes(chain),
      ...(customContract && { multicallCustomContractAddress: customContract }),
    })

    // Build calls
    const contractCallContext = tokenAddresses.reduce<ContractCallContext[]>(
      (acc, tokenEntry, index) => [
        ...acc,
        {
          reference: tokenEntry.token,
          contractAddress: tokenAddresses[index].address,
          calls: [{ reference: 'balanceOfCall', methodName: 'balanceOf', methodParameters: [address] }],
          abi: [erc20Abi[10]],
        },
      ],
      [],
    )

    const timeoutId = withTimeout
      ? setTimeout(() => {
          reject(new Error('Too long to fetch balances'))
        }, 6000)
      : null

    multicall
      .call(contractCallContext)
      .then(async (calls) => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        const balances = {
          [chain]: await Object.keys(calls.results).reduce(async (acc, token) => {
            const tokenConf = tokenRegistry[token]?.xchain
              ? tokenRegistry[token]
              : await tokenRegistryForChain(token, chain)
            const balance = calls?.results[token]?.callsReturnContext[0]?.returnValues[0]?.hex || 0
            const decimals = tokenConf.decimals || 18
            return {
              ...(await acc),
              [token]: { balance: new Decimal(balance || 0).div(10 ** decimals).toString(), token },
            }
          }, {}),
        }
        resolve(balances)
      })
      .catch((error) => reject(error))
  })
