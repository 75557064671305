import { Buffer } from 'buffer'
import { getAuthenticationData } from '../apiService'
import { getDvf } from '../dvfClient'
import type { Signature } from '../dvfClient/DvfClientInstance'

type Auth = {
  signature: Signature
  nonce: string
}

const makeEcRecoverHeader = (authData: Auth) => {
  const bufferStarkAuthData = Buffer.from(JSON.stringify(authData))
  return 'EcRecover ' + bufferStarkAuthData.toString('base64')
}

const getAuthorizationToken = async () => {
  const dvf = await getDvf()
  const authData = await getAuthenticationData(dvf)
  return makeEcRecoverHeader(authData)
}

export const addAuthorizationHeader = async (headers: Headers, endpoint: string) => {
  // useful when debugging premature authorized API calls
  // console.info('Authorized call to', endpoint)
  const token = await getAuthorizationToken()
  headers.set('authorization', token)
  return headers
}

export const getAuthorizationHeadersEffect = async () => ({
  authorization: await getAuthorizationToken(),
})
