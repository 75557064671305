import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { isWalletDisconnectAction } from '../actions/userActions/connectWalletActions'
import type { YieldData } from '../services/yieldService/yieldApi'
import { isAddressChangeAction } from '../actions/userActions/addressChangeActions'

type YieldDataStatus = 'initial' | 'pending' | 'available' | 'error'

export type YieldState = {
  data: Record<
    string,
    {
      token: string
      timestamp: number
      status: YieldDataStatus
      data: YieldData
    }
  >
}

const getInitialState = (): YieldState => ({
  data: {},
})

const makeInitialYieldData = (token: string): YieldData => ({
  yieldTokenBalance: '0',
  valueAtSupplyTime: '0',
  earnings: '0',
  token,
  timestamp: 0,
})

export const yieldSlice = createSlice({
  name: 'yield',
  initialState: getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(isWalletDisconnectAction, () => getInitialState())
    builder.addMatcher(isAddressChangeAction, () => getInitialState())
  },
  reducers: {
    updateYieldData(
      state,
      action: PayloadAction<{
        status: YieldDataStatus
        tokens: string[]
        timestamp?: number
        data?: Record<string, YieldData>
      }>,
    ) {
      const { status, tokens, data } = action.payload
      const timestamp = action.payload.timestamp || Date.now()
      state.data = {
        ...state.data,
        ...tokens.reduce(
          (acc, curr) => {
            if (status === 'available') {
              if (!data) {
                throw new Error('Data is required for available tokens')
              }
              acc[curr] = { status, timestamp, token: curr, data: data[curr] }
            } else {
              acc[curr] = {
                status,
                timestamp,
                token: curr,
                data: acc[curr] ? acc[curr].data : makeInitialYieldData(curr),
              }
            }
            return acc
          },
          {} as YieldState['data'],
        ),
      }
    },
  },
})
