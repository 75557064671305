import { yieldModalLocalStorageKeys } from '../services/yieldService/yieldTokensConfigs'
import type { AppDispatch } from '../store/configureStore'

export const MODAL_SHOW = 'MODAL_SHOW'

export const toggleConnectWallet =
  (dispatch: AppDispatch) =>
  (walletType = null) => {
    if (typeof walletType !== 'string') {
      return dispatch({
        type: 'TOGGLE_CONNECT_WALLET',
        payload: { autoConnectWallet: null },
      })
    }
    dispatch({
      type: 'TOGGLE_CONNECT_WALLET',
      payload: {
        autoConnectWallet: walletType,
      },
    })
  }

export const clearAutoConnectWallet = (dispatch: AppDispatch) => () => {
  dispatch({
    type: 'CLEAR_AUTO_CONNECT_WALLET',
  })
}

export const toggleDeposit =
  (dispatch: AppDispatch) =>
  ({ token }: { token?: string } = {}) => {
    dispatch({
      type: 'TOGGLE_DEPOSIT',
      payload: { token },
    })
  }

export const toggleWithdraw =
  (dispatch: AppDispatch) =>
  ({ chain, token, forcedState }: { chain?: string; token?: string; forcedState?: boolean } = {}) => {
    dispatch({
      type: 'TOGGLE_WITHDRAW',
      payload: { chain, token, forcedState },
    })
  }

export const toggleAirdropClaim = (dispatch: AppDispatch) => () => {
  dispatch({
    type: 'TOGGLE_AIRDROP_CLAIM',
  })
}

export const toggleLegacyMigrateDTK = (dispatch: AppDispatch) => () => {
  dispatch({
    type: 'TOGGLE_LEGACY_MIGRATE_DTK',
  })
}

export const toggleSimpleUIModal = (dispatch: AppDispatch) => () => {
  dispatch({
    type: 'TOGGLE_SIMPLE_UI_MODAL',
  })
}

export const toggleFirstDlmBuy =
  (dispatch: AppDispatch) =>
  (token = '', confirmPromise = {}) => {
    dispatch({
      type: 'TOGGLE_FIRST_DLM_BUY',
      payload: {
        token,
        confirmPromise,
      },
    })
  }

export const initCloseConfirmation = (dispatch: AppDispatch) => async () => {
  let resolveReject = {}
  const promise = new Promise((resolve, reject) => {
    resolveReject = {
      resolve,
      reject,
    }
  })
  dispatch({
    type: 'INIT_CLOSE_CONFIRM',
    payload: resolveReject,
  })
  return promise
}

export const clearCloseConfirmation = (dispatch: AppDispatch) => () => {
  dispatch({
    type: 'CLEAR_CLOSE_CONFIRM',
    payload: {},
  })
}

// Used to show the yield suggestion modal on first stablecoin deposit or trade
export const toggleYieldSuggestionModal =
  (dispatch: AppDispatch) =>
  ({ open = true, opportunity = 'ALL' }) => {
    if (!open) {
      dispatch({
        type: 'TOGGLE_YIELD_SUGGESTION_MODAL',
        payload: {
          open,
        },
      })
      return
    }

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const localStorageKey = yieldModalLocalStorageKeys[opportunity] || 'yield-suggestion-modal'
    const alreadyShown = localStorage.getItem(localStorageKey) === 'true'

    if (!alreadyShown) {
      localStorage.setItem(localStorageKey, 'true')
      dispatch({
        type: 'TOGGLE_YIELD_SUGGESTION_MODAL',
        payload: {
          open: true,
          opportunity,
        },
      })
    }
  }
