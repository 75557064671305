export function convertUSD(input: string): string
export function convertUSD(input?: null | string): string | undefined
export function convertUSD(input?: null | string) {
  if (input === 'UST' || input === 'USD') {
    return 'USDT'
  }
  if (input === 'UDC') {
    return 'USDC'
  }
  return input
}
