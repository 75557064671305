import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../env/envConfig'
import { addAuthorizationHeader } from './auth/addAuthorizationHeader'

type ArbitrumChargeConfig = {
  minSwapAmount: number
  swapCount: number
  depositWithdrawalCount: number
  minYieldAmount: number
  yieldPeriodMs: number
  startDate: Date
  endDate: Date
}

type ArbitrumChargeUserState = {
  swapCount: number
  depositCount: number
  withdrawalCount: number
  yieldAmountUsd: number
  yieldStartDate: Date | null | undefined
}

const endpointsWithAuth = ['getArbitrumChargeUserState']

export const arbitrumChargeApi = createApi({
  reducerPath: 'arbitrumChargeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.tradingApi}/arbitrum-charge`,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: ['Authenticated', 'UserState'],
  endpoints: (builder) => ({
    getArbitrumChargeConfig: builder.query<ArbitrumChargeConfig, void>({
      query: () => ({ url: '/config' }),
    }),
    getArbitrumChargeUserState: builder.query<ArbitrumChargeUserState, string>({
      query: (user) => ({ url: `/users/${user}/state` }),
      providesTags: ['Authenticated', 'UserState'],
    }),
  }),
})

export const { useGetArbitrumChargeConfigQuery, useGetArbitrumChargeUserStateQuery } = arbitrumChargeApi
