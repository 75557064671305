import * as config from '../env/config'

import * as devConfig from '../env/config.dev'
import * as stgConfig from '../env/config.stg'
import * as prodConfig from '../env/config.prd'
import type { PortalConfig } from './config.type'

export const getEnvConfig = (environment?: 'local' | 'dev' | 'prod' | 'stg'): PortalConfig => {
  const override = environment || localStorage.getItem('ENV_OVERRIDE')
  if (!override) {
    return config
  }

  if (override === 'prod') {
    return prodConfig
  } else if (override === 'stg') {
    return stgConfig
  } else if (override === 'dev') {
    return devConfig
  } else {
    return config
  }
}

export const envConfig = getEnvConfig()
export type EnvConfig = typeof envConfig
