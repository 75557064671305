import merge from 'lodash/merge'
import type { UnifiedTokenRegistryState } from '../reducers/types/UnifiedTokenRegistryState'

export const tokenInfoForChain = (tokenRegistry: UnifiedTokenRegistryState) => (token: string, chain: string) => {
  const tokenInfo = tokenRegistry[token]

  if (tokenInfo && 'chainOverride' in tokenInfo && tokenInfo?.chainOverride?.[chain]) {
    return merge({}, tokenInfo, tokenInfo?.chainOverride[chain])
  }

  return tokenInfo
}
