import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  votingPower: number | null
} = {
  votingPower: null,
}

export const governanceSlice = createSlice({
  name: 'governance',
  initialState,
  reducers: {
    setVotingPower: (
      state,
      action: PayloadAction<{
        votingPower: number
      }>,
    ) => {
      state.votingPower = action.payload.votingPower
    },
  },
})
