import userflow from 'userflow.js'
import { identifyHeap, identifyUserflow } from '../apiService'
import type { Wallet } from '../wallets/wallet'
import { getProvider } from '../wallets/wallet'
import { WALLETS } from '../../constants/types'
import { isWalletConnect } from '../wallets/walletService'

export const trackUserLoginEvent = (wallet: Wallet, isAutoConnected = false) => {
  const { address, walletType } = wallet
  let walletTypeWithProvider: string = walletType
  if (walletType === WALLETS.WALLET_CONNECT) {
    const provider = getProvider()
    if (isWalletConnect(provider, walletType)) {
      walletTypeWithProvider = `${walletType}-${provider?.session?.peer?.metadata?.name}`
    }
  }
  identifyHeap(address, walletTypeWithProvider, isAutoConnected)
  if (userflow.isIdentified()) {
    userflow.reset()
  }
  identifyUserflow(address, walletTypeWithProvider)
}
