import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../../env/envConfig'
import { addAuthorizationHeader } from '../auth/addAuthorizationHeader'
import { modalKeys } from '../../constants/modalKeys'
import { showModal } from '../../actions/modalActions/showModal'

const endpointsWithAuth: string[] = ['getClaimable', 'claim', 'getClaimHistory', 'getTotalRewards']

export type Claimable = {
  amount: number
  claimable: boolean
  minAmount: number
  rewardToken: string
}
export type Claimables = Record<string, Claimable>

export enum ClaimStatus {
  optimisticSubmitted = 'oprimisticSubmitted',
  submitted = 'submitted',
  processed = 'processed',
  failed = 'failed',
  accepted = 'accepted',
}

export type ClaimHistory = Array<{
  token: string
  amount: number
  timestamp: string
  status: ClaimStatus
}>

export type TotalClaimReward = {
  amount: number
  token: string
}
export type TotalClaimRewards = Array<TotalClaimReward>

type ClaimResult = {
  success: true
}

export const boostApi = createApi({
  reducerPath: 'boostApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envConfig.yieldBoostApi,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      headers.set('accept', 'application/json')

      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: ['claimable', 'history', 'total'],
  endpoints: (builder) => ({
    getClaimable: builder.query<Claimables, Record<string, never>>({
      query: () => ({ url: '/claimable' }),
      providesTags: ['claimable'],
    }),
    claim: builder.mutation<ClaimResult, { token: string; amount: string; rewardToken: string }>({
      query: ({ token }) => ({ url: '/claim', method: 'POST', body: { token }, timeout: 4 * 60 * 1000 }),
      onQueryStarted: async ({ token, amount, rewardToken }, { queryFulfilled, dispatch }) => {
        dispatch(
          boostApi.util.updateQueryData('getClaimable', {}, (draft) => {
            draft[token].claimable = false
            draft[token].amount = 0
          }),
        )
        dispatch(
          boostApi.util.updateQueryData('getClaimHistory', {}, (draft) => {
            draft.unshift({
              token,
              amount: +amount,
              timestamp: new Date().toISOString(),
              status: ClaimStatus.optimisticSubmitted,
            })
          }),
        )

        try {
          await queryFulfilled
          showModal(dispatch)(modalKeys.claimSuccess, {
            claimedAmount: amount,
            claimedToken: rewardToken,
          })
        } catch {
          dispatch(boostApi.util.invalidateTags(['claimable', 'history', 'total']))
        }
      },
      invalidatesTags: ['claimable', 'history', 'total'],
    }),
    getClaimHistory: builder.query<ClaimHistory, Record<string, never>>({
      query: () => ({ url: '/history' }),
      providesTags: ['history'],
    }),
    getTotalRewards: builder.query<TotalClaimRewards, null>({
      query: () => ({ url: '/total-rewards' }),
      providesTags: ['total'],
    }),
  }),
})

export const { useGetClaimHistoryQuery, useGetClaimableQuery, useClaimMutation, useGetTotalRewardsQuery } = boostApi
