import type { AppDispatch } from '../../store/store.types'
import { configFetcher } from './configFetcher'

// Data fetching
export async function getConfig({
  isAuthenticated,
  dispatch,
  isFromInterval,
}: {
  isAuthenticated?: boolean
  dispatch: AppDispatch
  isFromInterval?: boolean
}) {
  try {
    return configFetcher.getConfig({ dispatch, isAuthenticated, isFromInterval })
  } catch (error) {
    return false
  }
}
