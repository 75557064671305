import { request } from '@rhino.fi/dvf-utils'
import { envConfig } from '../../env/envConfig'

export type CrossChainSwapConfigForChain = {
  eip712: {
    version: string
    name: string
  }
  contractAddress: string
  chainId: number
  feeUsd: number
  feeRateSwap: number
  feeRateYield: number
  stables: Array<string>
  tokens: Record<string, { address: string; decimals: number; quantization: number }>
}

export type GetCrossChainSwapConfigResponse = {
  enabled: boolean
  configPerChain: Record<string, CrossChainSwapConfigForChain>
}

export const getCrossChainSwapConfig = async () => {
  try {
    const { tradingApi } = envConfig
    const data = await request.get<GetCrossChainSwapConfigResponse>(`${tradingApi}/v1/trading/chainswap/config`)
    return data
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error("couldn't fetch cross chain swap config: ", error)
    }
    return {
      enabled: false,
      configPerChain: {},
    }
  }
}
