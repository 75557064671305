import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserVerification } from './types/MigrationStampedeState'

export type MigrationStampedeState = {
  userVerification?: UserVerification
}

const getInitialState = (): MigrationStampedeState => {
  return {}
}

export const migrationStampedeSlice = createSlice({
  name: 'migrationStampede',
  initialState: getInitialState(),
  reducers: {
    setUserVerification(state, action: PayloadAction<UserVerification>) {
      state.userVerification = action.payload
    },
  },
})

export const { setUserVerification } = migrationStampedeSlice.actions
