export const heapEvents = {
  swap: 'Swap',
  swapShowHistory: 'Swap Show History',
  xchainSwap: 'Cross Chain Swap',
  swapTokenSelected: 'Swap Token Selected',
  swapChainSelected: 'Swap Chain Selected',
  tokenSearch: 'Swap Search Field Change',
  chainFilter: 'Swap Chain Filter Change',
  swapWidgetTryAgain: 'Swap Widget Try Again Used',
  deposit: 'Deposit',
  bridgeDeposit: 'Bridge Deposit',
  bridge: 'Bridge',
  send: 'Send',
  receive: 'Receive',
  stake: 'Stake DVF',
  unstake: 'Unstake DVF',
  claimRewards: 'Claim Rewards',
  claimDVF: 'Claim DVF',
  investSupply: 'Invest Supply',
  investRemove: 'Invest Remove',
  trade: 'Trade',
  withdraw: 'Withdraw',
  bridgeWithdraw: 'Bridge Withdraw',
  connectWallet: 'Connect Wallet',
  authenticate: 'Authenticate',
  unlockWallet: 'Unlock wallet',
  switchLanguage: 'Switch Language',
  firstDepositFeedback: 'First Deposit Feedback',
  switchTheme: 'Swith Theme',
  pageLeave: 'page-leave',
  pageEnter: 'page-enter',
  ammSupply: 'AMM Supply',
  ammSupplyFail: 'AMM Supply Fail',
  ammRemove: 'AMM Remove',
  ammRemoveFail: 'AMM Remove Fail',
  navigatonBridgeClicked: 'Navigation Bridge Clicked',
  strategyLinkClicked: 'Strategy Link Clicked',
  strategyModalShown: 'Strategy Modal Shown',
  favoriteToken: 'Favorite token',
  crossChainYieldModalShown: 'XChain Yield Modal Shown',
  crossChainYieldLinkClicked: 'XChain Yield Link Clicked',
  rpcReconnection: 'RPC provider reconnection',
  walletSearch: 'Wallet Search',
  gmStreak: 'GM streak',
  gm7DayStreak: '7 day GM streak',
  notification: 'Notification',
  dvfUtilityArticleClick: 'DVF Utility Article Click',
  walletError: 'Wallet Error',
  yieldCollapsibleSection: 'Yield Collapsible Section',
  goldBannerClick: 'Gold Banner Click',
  activityTracker: 'Activity Tracker',
  profileAllChains: 'Profile All Chains Data Loaded',
  toggleWithdrawAnyAddress: 'Toggle withdraw any address',
  toggleBridgeAnyAddress: 'Toggle bridge any address',
  recommendations: 'Clicked on Recommendations',
  nftMinted: 'NFT Minted',
  referralLinkUnlocked: 'Referral Link Unlocked',
  referralCreated: 'Referral Created',
  activityTrackerRecommendedProjectClicked: 'Activity Tracker Recommended Project Clicked',
  myAchievementsDataLoadedInModal: 'My Achievements Data Loaded In Modal',
  myAchievementDataLoaded: 'Achievement data loaded',
  myAchievementActionClick: 'My Achievement Action Clicked',
  profileTabClick: 'Profile tab click',
  contractsInteractionsTabClick: 'Contracts Interactions Interactions Tab Clicked',
  gmContractDeployed: 'GM Contract Deployed',
  gm: 'Contract GM interaction',
  eip6963Connect: 'EIP6963 Connect',
} as const

export type HeapEvent = (typeof heapEvents)[keyof typeof heapEvents]

export const heapStatuses = {
  success: 'Successful',
  fail: 'Failed',
  partialSuccess: 'Partial Success',
}
