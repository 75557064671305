import _has from 'lodash/has'
import _get from 'lodash/get'
import _keys from 'lodash/keys'
import _toLower from 'lodash/toLower'
import _replace from 'lodash/replace'
import _capitalize from 'lodash/capitalize'
import _isEmpty from 'lodash/isEmpty'
import _escapeRegExp from 'lodash/escapeRegExp'
import { envConfig } from '../env/envConfig'
import type { I18nData } from './i18n.types'

/**
 * Handles translation missing terms gracefully with
 * a proper warning in the console
 *
 * @param {String} id : the missing dictionary term
 * @returns {String}
 */
const fallback = (id: string) => {
  if (process.env.REACT_APP_TESTING) {
    // eslint-disable-next-line no-console -- Logging for debugging
    console.warn('[i18n] missing translation for', id)
  }

  if (_has(window.I18nfallback, id)) {
    return _get(window.I18nfallback, id)
  }

  return _capitalize(_replace(id, '.', ' '))
}

/**
 * replaces placeholders in the string with actual dynamic
 * values, provided in the custom data dictionary object
 *
 * @param {String} string : the template string
 * @param {Object} dictionary : the extended dictionary
 * @returns {String}
 */

const compile = (string = '', dictionary: Record<string, string | number> = {}) => {
  let result = string

  _keys(dictionary).forEach((field) => {
    const regex = new RegExp(_escapeRegExp(field), 'g')
    result = _replace(result, regex, String(dictionary[field]))
  })

  return result
}

/**
 * it translates an id into a localized string from a
 * global dictionary object (window.I18n).
 * common placeholders will be automatically replaced.
 * you can provide extra terms with values, to extend the
 * dictionary, in the form of an object.
 *
 */
export const translate = <T extends keyof I18nData>(index: T, data?: I18nData[T]) => {
  const id = _toLower(index)
  const string = _has(window.I18n, id) ? _get(window.I18n, id) : fallback(id)

  return _isEmpty(data) || data === null ? string : compile(string, data)
}

export const dynamicT = (index: string, data?: Record<string, string | number>) => {
  const id = _toLower(index)
  const hasTranslation = _has(window.I18n, id)

  if (!hasTranslation && envConfig.environment === 'dev') {
    console.error('Missing translation for: ', id)
  }

  const string = hasTranslation ? _get(window.I18n, id) : fallback(id)

  return _isEmpty(data) || data === null ? string : compile(string, data)
}

export const unsafeT = (key: string, data?: Record<string, unknown>) =>
  translate(key as keyof I18nData, data as I18nData[keyof I18nData])
