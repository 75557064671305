import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
  PortfolioState,
  ActionErrorPayload,
  PortfolioChartDataItem,
  PortfolioChartBucketFilter,
} from '../services/portfolio/portfolio.types'
import { PORTFOLIO_CHART_BUCKET_FILTERS } from '../constants/portfolioConstants'

const initialState: PortfolioState = {
  getPortfolioChartDataRequest: { status: 'idle' },
  chartActiveBucketFilter: PORTFOLIO_CHART_BUCKET_FILTERS[1],
}

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    getPortfolioChartData: (state) => {
      if (state.getPortfolioChartDataRequest.status !== 'idle') {
        return state
      }

      return {
        ...state,
        getPortfolioChartDataRequest: { status: 'in-progress' },
      }
    },
    getPortfolioChartDataFailed: (state, action: PayloadAction<ActionErrorPayload>) => {
      if (state.getPortfolioChartDataRequest.status !== 'in-progress') {
        return state
      }

      return {
        ...state,
        getPortfolioChartDataRequest: { status: 'error', error: action.payload.error },
      }
    },
    getPortfolioChartDataSuccess: (state, action: PayloadAction<PortfolioChartDataItem[]>) => {
      if (state.getPortfolioChartDataRequest.status !== 'in-progress') {
        return state
      }

      return {
        ...state,
        getPortfolioChartDataRequest: { status: 'success', data: action.payload },
      }
    },
    getPortfolioChartDataReset: (state) => {
      if (
        state.getPortfolioChartDataRequest.status === 'error' ||
        state.getPortfolioChartDataRequest.status === 'success'
      ) {
        return { ...state, getPortfolioChartDataRequest: { status: 'idle' } }
      }

      return state
    },

    setPortfolioChartActiveBucketFilter: (state, action: PayloadAction<PortfolioChartBucketFilter>) => ({
      ...state,
      chartActiveBucketFilter: action.payload,
    }),
  },
})

export const {
  getPortfolioChartData,
  getPortfolioChartDataFailed,
  getPortfolioChartDataSuccess,
  getPortfolioChartDataReset,

  setPortfolioChartActiveBucketFilter,
} = portfolioSlice.actions
