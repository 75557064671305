export const ADD_STATUS_NOTIFICATION = 'ADD_STATUS_NOTIFICATION'
export const UPDATE_STATUS_NOTIFICATION = 'UPDATE_STATUS_NOTIFICATION'
export const CLEAR_STATUS_NOTIFICATION = 'CLEAR_STATUS_NOTIFICATION'

export enum StatusNotificationStatus {
  success = 'success',
  error = 'error',
  pending = 'pending',
  warn = 'warn',
}

export type StatusNotification = {
  id: string
  status: StatusNotificationStatus
  title: string
  meta?: {
    description?: string | React.ReactElement
    txHash?: string
    token?: string
    chain?: string
    pair?: string
    icons?: string[]
  }
}

export type StatusNotificationUpdatedFields = {
  status?: StatusNotificationStatus
  title?: string
  meta?: {
    description?: string | React.ReactElement
    txHash?: string
    token?: string
    chain?: string
    pair?: string
    icons?: string[]
  }
}

export type UpdateStatusNotificationAction = {
  type: typeof UPDATE_STATUS_NOTIFICATION
  payload: { id: string; updatedFields: StatusNotificationUpdatedFields }
}
