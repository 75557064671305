export const poll =
  <T>(timeout: number, pollingInterval: number, tag: string) =>
  (pollfn: () => Promise<T | undefined>) =>
    new Promise<T>((resolve, reject) => {
      let resolved = false
      let innerPollTimeout: NodeJS.Timeout
      const interval = setTimeout(() => {
        resolved = true
        clearTimeout(innerPollTimeout)
        reject(new Error(`TIMEOUT_${tag}`))
      }, timeout)

      const innerPoll = async () => {
        if (resolved) {
          return
        }

        const result = await pollfn()
        if (!result) {
          innerPollTimeout = setTimeout(innerPoll, pollingInterval)
        } else {
          clearTimeout(interval)
          resolve(result)
        }
      }

      innerPoll()
    })
