import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import type { RootState } from '../../store/configureStore'

type BlockTracker = Record<
  string,
  {
    latestBlockNumber?: number
  }
>

const initialState: BlockTracker = {}

const reducerName = 'blockTracker'

export const blockTrackerSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    updateLatestBlockNumberForChain: (state, action: PayloadAction<{ chain: string; latestBlockNumber: number }>) => {
      const { chain, latestBlockNumber } = action.payload
      return {
        ...state,
        [chain]: {
          ...state[chain],
          latestBlockNumber,
        },
      }
    },
  },
})

export const { updateLatestBlockNumberForChain } = blockTrackerSlice.actions

export type BlockTrackerAction = ReturnType<typeof updateLatestBlockNumberForChain>

export const selectBlockTrackers = (state: RootState) => state[reducerName]
export const selectLatestBlockNumbers = createSelector(selectBlockTrackers, (blockTrackers) => {
  return Object.entries(blockTrackers).reduce(
    (acc, [chain, tracker]) => {
      acc[chain] = tracker.latestBlockNumber
      return acc
    },
    {} as Record<string, number | undefined>,
  )
})
