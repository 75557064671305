export enum ProgressStep {
  'form' = 0,
  'pending' = 1,
  'done' = 2,
  'error' = 3,
}

export enum BridgeErrorType {
  'timeout' = 'timeout',
  'wrongNetwork' = 'wrongNetwork',
}

export enum BridgeConfirmationScreen {
  'bridgeToAny' = 0,
  'lessThanDollar' = 1,
}

export enum BridgeSuccessType {
  'blocksTimeout' = 'blocksTimeout',
}

export type BridgeFormValues = {
  depositAmount: string
  depositToken: string
  chainIn: string
  chainOut: string
  withdrawAmount: string
  destinationAddress: string
  isOtherAddress: boolean
}

export type BridgeProgress = {
  type: 'approval' | 'deposit' | 'withdrawal'
  isTxSubmitted: boolean
  isTxConfirmed: boolean
  timestamp: number | null
  isError: boolean
  token: string
  amount: string
  progressStep: ProgressStep
  error: string
  errorType: BridgeErrorType | undefined
  successType: BridgeSuccessType | undefined
}

export type BridgeConfirmationPromise = {
  type: BridgeConfirmationScreen
  resolve: (result: boolean) => boolean
}
