export const modalKeys = {
  deposit: 'deposit-modal',
  withdrawal: 'withdrawal-modal',
  strategiesCapture: 'strategies-capture-modal',
  gamification: 'gamification-modal',
  getStarted: 'get-started-modal',
  newUserEmail: 'new-user-modal',
  xchainSuccess: 'xchain-success-modal',
  yieldUpsell: 'yield-missed-opportunity-modal',
  swapSettings: 'swap-settings-modal',
  accruing: 'accruing-modal',
  claiming: 'claiming-modal',
  verify: 'verify-modal',
  xchain: 'xchain-announce-modal',
  leaveFundsOnRhino: 'leave-funds-rhino-modal',
  yieldUpcoming: 'yield-upcoming-modal',
  claimHistory: 'claim-history-modal',
  claimSuccess: 'claim-success-modal',
  yieldAlerts: 'yield-alerts-modal',
  gasFee: 'gas-fee-modal',
  changeAddress: 'change-address-modal',
  claimRewards: 'claim_rewards-modal',
  fees: 'fees-modal',
  rhinoFiWrapped: 'rhino-wrapped',
  userVerificationEmail: 'user-verification-email-modal',
  userVerificationPhone: 'user-verification-phone-modal',
  recentBridgeTx: 'recent-bridge-tx',
  ledgerIsBusy: 'ledger-is-busy-modal',
  nftMint: 'nft-mint-modal',
  viewNftMinted: 'view-nft-minted-modal',
  freeBridge: 'free-bridge-modal',
  reconnectModal: 'reconnect-modal',
  noWallet: 'no-wallet',
  airdropClaimCongrats: 'airdrop-claim-congrats',
  spendingCap: 'spending-cap',
  myAchievements: 'my-achievements',
  contractDetails: 'contract-details',
  contractShare: 'contract-share',
  contractGM: 'contract-gm',
  contractDeploy: 'contract-deploy',
  profileGradesInfo: 'profile-grades-info',
  referralCodeVerification: 'referral-code-verification',
  connectToParadex: 'connect-to-paradex',
  nationActiveMission: 'nation-active-mission',
  nationCompletedMission: 'nation-completed-mission',
} as const
