import forEach from 'lodash/forEach'
import type { UnifiedTokenRegistryState } from '../../reducers/types/UnifiedTokenRegistryState'

export const getDepositChainsFromRegistry = (tokenRegistry: UnifiedTokenRegistryState) => {
  const tokenRegistryValues = Object.values(tokenRegistry)

  return tokenRegistryValues.reduce((acc, tokenConfig) => {
    if (!tokenConfig.xchain && 'tokenAddressPerChain' in tokenConfig) {
      forEach(tokenConfig.tokenAddressPerChain, (_, chain) => {
        if (chain === 'MATIC_POS_PARENT') {
          return
        }
        if (!acc.includes(chain)) {
          acc.push(chain)
        }
      })
    }
    return acc
  }, [] as string[])
}
