/* Yield config to be
 {
   id, (uniquet for identifing the yield product)
   baseToken,
   type (used to identify what other fields are present)
   cToken (yield token)
 }
 For compound, config fields are
 {
   cTokenContract (?)
 }

 TODO: Could be moved to DVF_CLIENT_CONF_JSON once finalized
*/

import pickBy from 'lodash/pickBy'
import { translate } from '../../intl/i18n'
import { envConfig } from '../../env/envConfig'
import type { Strategy } from '../../components/Strategies/yieldStrategyConfig.types'
import { isFeatureEnabled } from '../helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from '../../constants/types'
import { DescriptionNodeType, YieldOpportunityType } from './yieldTokensConfigs.types'
import type { YieldTokenConfig, YieldBoostDescription, YieldTokenDescription } from './yieldTokensConfigs.types'

const { yieldUsdEndDate, yieldEthEndDate, yieldBtcEndDate, stargateBoostEndDate } = envConfig

const makeStargateDescription = ({
  isBoosted,
  baseToken,
  beefyVaultId,
}: {
  isBoosted: boolean
  baseToken: string
  beefyVaultId: string
}): YieldTokenConfig['description'] =>
  isBoosted
    ? [
        {
          type: DescriptionNodeType.TEXT,
          text: () =>
            `Your funds will go into a liquidity pool to earn Stargate’s native token, which is then swapped back into ${baseToken}. Your bonus rewards are immediately claimable, while the base Stargate reward is auto-compounded.`,
        },
        {
          type: DescriptionNodeType.TEXT,
          text: () =>
            'At each compounding period, the reward is added onto your investment and you earn the next tranche of interest on this new figure, so your rewards increase progressively over time.',
        },
        {
          type: DescriptionNodeType.TEXT_WITH_LINK,
          text: () =>
            '%linkStart Beefy Finance %linkEnd handles the deposit, reinvestment and withdrawal processes (on your instruction, using smart contracts), so you can enter and exit easily and are not exposed to Stargate’s native token.',
          href: `https://app.beefy.com/vault/${beefyVaultId}`,
        },
        {
          type: DescriptionNodeType.NORMAL_LINK,
          linkIcon: 'box-arrow-up-right',
          text: () => translate('global.view_originator_market'),
          href: 'https://stargate.finance/stake',
        },
      ]
    : [
        {
          type: DescriptionNodeType.TEXT_WITH_LINK,
          text: () =>
            `By participating in this opportunity you will be supplying funds to the Stargate ${baseToken} pool through %linkStart Beefy. %linkEnd`,
          href: `https://app.beefy.com/vault/${beefyVaultId}`,
        },
        {
          type: DescriptionNodeType.TEXT_WITH_LINK,
          text: () =>
            `The strategy supplies ${baseToken} to %linkStart Stargate Finance %linkEnd to earn STG. Earned tokens are harvested, sold for more ${baseToken} which is deposited back into the strategy.`,
          href: 'https://stargate.finance/stake',
        },
        {
          type: DescriptionNodeType.TEXT,
          text: () =>
            'With Stargate, users & dApps can transfer native assets cross-chain while accessing the protocol’s unified liquidity pools with instant guaranteed finality.',
        },
      ]

const makeXChainBeefyStargateConfig = ({
  id,
  baseToken,
  chain,
  beefyVaultId,
  cToken,
  cTokenContractAddress,
  rewardToken,
  isBoosted,
  isBoostAnimated,
  boostCopy,
  maxSupplyAmount,
  protocol = 'stargate',
  originator,
  icons,
  description,
  opportunityType,
  originatorMarketHref,
  descriptionV2,
  auditHref,
  contractHref,
  vaultHref,
  isSunset,
  isDisabled,
  boostEnds,
  sunsetDate,
}: {
  sunsetDate?: Date
  id: string
  baseToken: string
  chain: string
  beefyVaultId: string
  cToken: string
  cTokenContractAddress: string
  rewardToken?: string
  isBoosted?: boolean
  isBoostAnimated?: boolean
  boostCopy?: YieldBoostDescription
  maxSupplyAmount?: string
  protocol?: string
  icons?: string[]
  description: YieldTokenConfig['description']
  opportunityType: YieldOpportunityType
  originator: YieldTokenConfig['originator']
  originatorMarketHref: string
  descriptionV2: YieldTokenDescription
  auditHref: string
  contractHref: string
  vaultHref: string
  isSunset?: boolean
  isDisabled?: boolean
  boostEnds?: number
}): YieldTokenConfig => ({
  isDisabled,
  isSunset,
  xchain: true,
  type: 'beefy',
  originator,
  protocol,
  id,
  baseToken,
  chain,
  beefyVaultId,
  cToken,
  cTokenContractAddress,
  rewardToken,
  icons,
  opportunityType,
  originatorMarketHref,
  descriptionV2,
  auditHref,
  contractHref,
  vaultHref,
  sunsetDate,
  ...(isBoosted
    ? {
        isBoosted: true,
        boostPercentage: 7,
        boostValueLimit: 1000,
        isBoostAnimated: isBoostAnimated || false,
        boostCopy: boostCopy || [],
        maxSupplyAmount: maxSupplyAmount || '',
        boostEnds,
      }
    : { isBoosted: false }),
  description,
})

export const yieldTokensConfigs: Record<Strategy | string, YieldTokenConfig> = {
  ETHBTCTREND: {
    isSunset: true,
    sunsetDate: new Date('2023-07-05T15:12:00.000Z'),
    opportunityType: YieldOpportunityType.strategy,
    originatorMarketHref: 'https://app.sommelier.finance/strategies/ETH-BTC-Trend/manage',
    id: 'ETHBTCTREND',
    baseToken: 'USDT',
    type: 'sommelier',
    originator: 'sommelier',
    cToken: 'ETHBTCTREND',
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://app.sommelier.finance/strategies/ETH-BTC-Trend/manage',
        text: () =>
          `The %linkStart Sommelier Finance ETH-BTC Trend strategy %linkEnd accumulates BTC and ETH relative to USDC with a medium to long-term perspective. The rebalancing decision applies the concept of trend following while also considering the correlation between the portfolio assets and emerging trends. The strategy aims to outperform the benchmarks and have lower volatility and risk than holding BTC or ETH individually or an equally weighted portfolio of BTC and ETH.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `The strategy is long only but cuts risk exposure when the price trends are negative. The cellar is based on backtests and is expected to outperform at times when price increases are moderate and to outperform significantly and reduce risks in a bear market. The benefits of the strategy can emerge within 3-6 month (i.e., medium term) holding period in case of diverse market conditions and are highly likely to emerge for holding periods over 1 year (i.e., long term).`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `All rebalancing decisions are based on quantitative rules that are developed from historical price movements. The rules are dynamic and can change as market conditions change.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `The portfolio holds BTC, ETH and USDC as a market-neutral asset in proportions set by the quantitative rules. Those three assets can be held in any combination. The strategy evaluates market conditions in probabilities and makes rebalancing decisions accordingly. The proportions of BTC, ETH and USDC depend on which asset is deemed to have the higher upside probability at the time. This means a 50/50 split between BTC and ETH is possible but 60/40 or 80/20 splits might be equally likely. Neither BTC nor ETH is favored over the other but quantitative measurements determine which will become overweight when necessary.`,
      },
    ],
    auditHref: 'https://0xmacro.com/library/audits/sommelier-3.html',
    contractHref: 'https://etherscan.io/address/0x6b7f87279982d919bbf85182ddeab179b366d8f2',
    vaultHref: 'https://debank.com/profile/0x6b7f87279982d919Bbf85182DDeAB179B366D8f2',
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'ETH-BTC Trend strategy aims to provide a better risk-return tradeoff than holding ETH and/or BTC. The strategy follows upward price trends and exits the market when no positive trend is detected. The goal is to overperform simple buy and hold strategy, cut losses during market downturn, and re-establish the long position after prices start to go up, so that Sommelier users will not miss out any subsequent price appreciation.',
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        linkIcon: 'box-arrow-up-right',
        text: () => translate('global.view_originator_market'),
        href: 'https://app.sommelier.finance/strategies/ETH-BTC-Trend/manage',
      },
    ],
  },
  ...(isFeatureEnabled(F_FLAGS_TYPES.CDETI_YIELD)
    ? {
        CDETI: {
          isOutsideControlled: true,
          opportunityType: YieldOpportunityType.strategy,
          originatorMarketHref: 'https://indexcoop.com/products/coindesk-eth-trend-index',
          id: 'CDETI',
          baseToken: 'ETH',
          type: 'indexcoop',
          originator: 'indexcoop',
          cToken: 'CDETI',
          descriptionV2: [
            {
              type: DescriptionNodeType.TEXT_WITH_LINK,
              href: 'http://coindeskmarkets.com/eti',
              text: () =>
                `cdETI, an automated strategy token powered by %linkStart CoinDesk Indices’ Ether Trend Indicator %linkEnd (ETI), is designed to take advantage of ETH’s price momentum, without the need for constant market monitoring and manual trading`,
            },
            {
              type: DescriptionNodeType.TEXT,
              text: () =>
                `cdETI allocates to a mix of ETH and/or USDC based on the ETI. When the indicator shows a significant uptrend, cdETI is allocated 100% to ETH. When there is a significant downtrend, cdETI is allocated to 100% USDC. If there is no significant trend, the product will be allocated equally to USDC and ETH.`,
            },
          ],
          auditHref: 'https://docs.indexcoop.com/index-coop-community-handbook/protocols/security',
          contractHref: 'https://etherscan.io/address/0x55b2CFcfe99110C773f00b023560DD9ef6C8A13B',
        },
      }
    : {}),
  ETHBTCMOMENTUM: {
    isSunset: true,
    sunsetDate: new Date('2023-07-05T15:12:00.000Z'),
    opportunityType: YieldOpportunityType.strategy,
    originatorMarketHref: 'https://app.sommelier.finance/strategies/ETH-BTC-Momentum/manage',
    id: 'ETHBTCMOMENTUM',
    baseToken: 'USDT',
    type: 'sommelier',
    originator: 'sommelier',
    cToken: 'ETHBTCMOMENTUM',
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://app.sommelier.finance/strategies/ETH-BTC-Momentum/manage',
        text: () =>
          `The %linkStart Sommelier Finance ETH-BTC Momentum strategy %linkEnd accumulates BTC and ETH relative to USDC with a medium to long-term perspective. The rebalancing decision is based on price momentum and the asset with higher momentum is assigned to be overweight. The strategy aims to outperform the benchmarks and have lower volatility and risk than holding BTC or ETH individually or in an equally weighted portfolio of BTC and ETH.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `The strategy is long only but cuts risk exposure when the price trends are negative. The cellar is based on backtests and is expected to outperform at times when price increases are moderate and to outperform significantly and reduce risks in a bear market. The benefits of the strategy can emerge within 3-6 month (i.e., medium term) holding period in case of diverse market conditions and are highly likely to emerge for holding periods over 1 year (i.e., long term).`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `All rebalancing decisions are based on quantitative rules that are developed from historical price movements. The rules are dynamic and can change as market conditions change.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `The portfolio holds BTC, ETH and USDC as a market-neutral asset in proportions set by the quantitative rules. Those three assets can be held in any combination. The strategy evaluates market conditions in probabilities and makes rebalancing decisions accordingly. The proportions of BTC, ETH and USDC depend on which asset is deemed to have the higher upside probability at the time. This means a 50/50 split between BTC and ETH is possible but 60/40 or 80/20 splits might be equally likely. Neither BTC nor ETH is favored over the other but quantitative measurements determine which will become overweight when necessary`,
      },
    ],
    auditHref: 'https://0xmacro.com/library/audits/sommelier-3.html',
    contractHref: 'https://etherscan.io/address/0x6E2dAc3b9E9ADc0CbbaE2D0B9Fd81952a8D33872',
    vaultHref: 'https://0xmacro.com/library/audits/sommelier-3.html',
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'ETH-BTC Momentum strategy aims to provide a better risk-return tradeoff than holding ETH and/or BTC. The strategy reacts to positive price movement to buy ETH and/or BTC and decreases positions otherwise. The goal is to overperform simple buy-and-hold strategy, decrease losses during market downturn, and re-establish the long position after prices start to go up, so that Sommelier users will not miss out any subsequent price appreciation.',
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        linkIcon: 'box-arrow-up-right',
        text: () => translate('global.view_originator_market'),
        href: 'https://app.sommelier.finance/strategies/ETH-BTC-Momentum/manage',
      },
    ],
  },
  CUSDT: {
    isSunset: true,
    sunsetDate: new Date('2023-01-24T12:00:00.000Z'),
    originatorMarketHref: 'https://compound.finance/markets/USDT',
    opportunityType: YieldOpportunityType.liquidityPool,
    id: 'cUSDT',
    baseToken: 'USDT',
    type: 'compound',
    originator: 'compound',
    cToken: 'CUSDT',
    cTokenContractAddress: '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Compound USDT tokens are tokens that earn interest when you lend your Tether on the Compound Protocol. As more interest accrues to the global pool of Compound USDT, time the value of your supplied tokens increases.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Supply your Tether today through rhino.fi and start accruing interests every minute. You can remove your funds anytime.`,
      },
    ],
    auditHref: 'https://docs.compound.finance/v2/security/',
  },
  WSTETH: {
    originatorMarketHref: 'https://stake.lido.fi/',
    opportunityType: YieldOpportunityType.staking,
    id: 'wstETH',
    baseToken: 'ETH',
    type: 'lido',
    originator: 'lido',
    cToken: 'WSTETH',
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'stETH tokens are liquid staking tokens that earn a return when you stake your ETH into Ethereum 2.0 proof-of-stake to help secure the network. stETH tokens are a Lido Finance product.',
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        linkIcon: 'box-arrow-up-right',
        text: () => translate('global.view_originator_market'),
        href: 'https://lido.fi/ethereum',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'Supply your ETH to receive wstETH (wrapped staked ETH) tokens. As Ethereum network fees and inflation emissions accrue to stETH, the value of your stETH tokens increases',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () => 'Read %linkStart more %linkEnd about Staked ETH on rhino.fi',
        href: 'https://support.rhino.fi/en/article/staked-eth-steth-qaynxq/',
      },
    ],
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://stake.lido.fi/',
        text: () =>
          `stETH tokens are liquid staking tokens distributed by %linkStart Lido Finance %linkEnd that earn a return when you stake your ETH into Ethereum 2.0 proof-of-stake to help secure the network. stETH is a transferable rebasing utility token, which represents a share of the total ETH staked through the protocol, which consists of user deposits and staking rewards. Because stETH rebases daily, it communicates the position of the share daily.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Through rhino.fi receive wstETH (wrapped staked ETH) tokens. As Ethereum network fees and inflation emissions accrue to stETH, the value of your stETH tokens increases. Tokens can be used as collateral.`,
      },
    ],
    auditHref: 'https://github.com/lidofinance/audits',
    contractHref: 'https://etherscan.io/token/0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
  },
  STMATIC: {
    originatorMarketHref: 'https://polygon.lido.fi/',
    opportunityType: YieldOpportunityType.staking,
    id: 'stMATIC',
    baseToken: 'MATIC',
    type: 'lido',
    originator: 'lido',
    cToken: 'STMATIC',
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'STMATIC tokens are liquid staking tokens that earn a return when you stake your MATIC into Polygon network proof-of-stake to help secure the network. STMATIC tokens are a Lido Finance product.',
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        linkIcon: 'box-arrow-up-right',
        text: () => translate('global.view_originator_market'),
        href: 'https://lido.fi/polygon',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'Supply your MATIC to receive STMATIC tokens. As Polygon network fees and inflation emissions accrue to STMATIC, the value of your STMATIC tokens increases',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () => 'Read %linkStart more %linkEnd about STMATIC on rhino.fi',
        href: 'https://support.rhino.fi/en/article/staked-matic-stmatic-1xvx6r3/',
      },
    ],
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://polygon.lido.fi/',
        text: () =>
          `stMATIC tokens are liquid staking tokens distributed by %linkStart Lido Finance %linkEnd that earn a return when you stake your MATIC into Polygon proof-of-stake to help secure the network. stMATIC is a transferable rebasing utility token, which represents a share of the total MATIC staked through the protocol, which consists of user deposits and staking rewards. Because stMATIC rebases daily, it communicates the position of the share daily.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `As Polygon network fees and inflation emissions accrue to stMATIC, the value of your stMATIC tokens increases. Tokens can be used as collateral.`,
      },
    ],
    auditHref: 'https://github.com/lidofinance/audits',
    contractHref: 'https://etherscan.io/token/0x9ee91F9f426fA633d227f7a9b000E28b9dfd8599',
  },
  DSETH: {
    isSunset: true,
    sunsetDate: new Date('2024-03-28T00:00:00.000Z'),
    opportunityType: YieldOpportunityType.staking,
    originatorMarketHref: 'https://indexcoop.com/products/diversified-staked-ethereum-index',
    id: 'dsETH',
    baseToken: 'ETH',
    type: 'indexcoop',
    originator: 'indexcoop',
    cToken: 'DSETH',
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'The Diversified Staked Ethereum Index (dsETH) is an index of top liquid staking tokens. The funds are divided between these tokens according to their level of decentralisation based on number of node operators as well as the distribution of stake across node operators.',
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        linkIcon: 'box-arrow-up-right',
        text: () => translate('global.view_originator_market'),
        href: 'https://indexcoop.com/products/diversified-staked-ethereum-index',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'Supply you ETH and gain exposure to Rocket Pool ETH (rETH), Wrapped Lido Staked Ether (wstETH), and StakeWise Staked ETH (sETH2).',
      },
    ],
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://indexcoop.com/products/diversified-staked-ethereum-index',
        text: () =>
          `The %linkStart Diversified Staked Ethereum Index (dsETH) %linkEnd gives users diversified exposure to liquid staking tokens, with a weighting that favors decentralized liquid staking protocols as measured by the number of node operators as well as distribution of stake across node operators. To begin, constituents are equally weighted before adding a Node Operator Factor, which benefits staking protocols with more active node operators. An HHI (or Herfindahl-Hirschman Index) Factor is then added, which measures the concentration of stake and broader competition amongst active node operators within each protocol.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Gain exposure to Rocket Pool ETH (rETH), Wrapped Lido Staked Ether (wstETH), and StakeWise Staked ETH (sETH2).`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Other liquid staking tokens that meet these criteria can be added to the index over time. No one liquid staking token can exceed 50% of the index.`,
      },
    ],
    auditHref: '',
  },
  ICETH: {
    isSunset: true,
    sunsetDate: new Date('2024-03-28T00:00:00.000Z'),
    originatorMarketHref: 'https://indexcoop.com/products/interest-compounding-eth-index',
    opportunityType: YieldOpportunityType.leveragedStaking,
    id: 'icETH',
    baseToken: 'ETH',
    type: 'indexcoop',
    originator: 'indexcoop',
    cToken: 'ICETH',
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'The Interest Compounding ETH Index (icETH) uses a leveraged liquid staking strategy, multiplying the staking rate for stETH while minimizing transaction costs and risk associated with collateralised debt on Aave.',
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        linkIcon: 'box-arrow-up-right',
        text: () => translate('global.view_originator_market'),
        href: 'https://indexcoop.com/products/interest-compounding-eth-index',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'Supply your ETH now and receive icETH tokens. Retain spot exposure to ETH and amplify your staking returns up to 2.5x.',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () => 'Read %linkStart more %linkEnd about icETH on rhino.fi',
        href: 'https://support.rhino.fi/en/article/iceth-leveraged-token-liquidation-risks-87bi13/',
      },
    ],
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://indexcoop.com/products/interest-compounding-eth-index',
        text: () =>
          'The %linkStart Interest Compounding ETH Index (icETH) %linkEnd uses a leveraged liquid staking strategy, multiplying the staking rate for stETH while minimizing transaction costs and risk associated with collateralised debt on Aave.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'icETH methodology aims to give users leveraged exposure to Ethereum staking rewards, with automated leverage management that reduces the risk and the effort required to manually manage a leveraged position. The icETH token is deployed to mainnet Ethereum.  In execution, stETH is deposited to Aave to use as collateral to borrow ETH. This ETH is exchanged for additional stETH which is also deposited back into Aave to create the leveraged position. While stETH staking yield is greater than the cost to borrow ETH on Aave, the strategy is profitable in ETH terms. Fluctuations of the stETH:ETH conversion rate can also influence profitability.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () => 'icETH will be composed of, and limited to, astETH and awETH tokens.',
      },
    ],
    auditHref: 'https://docs.indexcoop.com/index-coop-community-handbook/protocols/security',
    contractHref: 'https://etherscan.io/token/0x7C07F7aBe10CE8e33DC6C5aD68FE033085256A84',
    vaultHref: 'https://etherscan.io/address/0xe6484a64e2ea165943c734dc498070b5902cbc2b',
  },
  YVUSDT: {
    isSunset: true,
    sunsetDate: new Date('2024-04-10T12:00:00.000Z'),
    opportunityType: YieldOpportunityType.liquidityPool,
    originatorMarketHref: 'https://stargate.finance/stake',
    id: 'yvUSDT',
    baseToken: 'USDT',
    type: 'yearn',
    originator: 'yearn',
    cToken: 'YVUSDT',
    cTokenContractAddress: '0x3b27f92c0e212c671ea351827edf93db27cc0c65',
    description: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          'By participating in this opportunity you will be supplying funds to the yVault USDT strategy on %linkStart Yearn Finance. %linkEnd',
        href: 'https://yearn.finance/',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          'The strategy supplies USDT to %linkStart Stargate Finance. %linkEnd to earn STG. Earned tokens are harvested, sold for more USDT which is deposited back into the strategy.',
        href: 'https://stargate.finance/stake',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'With Stargate, users & dApps can transfer native assets cross-chain while accessing the protocol’s unified liquidity pools with instant guaranteed finality.',
      },
    ],
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          'By participating in this opportunity you will be supplying funds to the yVault USDT strategy on %linkStart Yearn Finance. %linkEnd',
        href: 'https://yearn.finance/',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          'The strategy supplies USDT to %linkStart Stargate Finance. %linkEnd to earn STG. Earned tokens are harvested, sold for more USDT which is deposited back into the strategy.',
        href: 'https://stargate.finance/stake',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'With Stargate, users & dApps can transfer native assets cross-chain while accessing the protocol’s unified liquidity pools with instant guaranteed finality.',
      },
    ],
  },
  mooStargateUSDT: makeXChainBeefyStargateConfig({
    isSunset: true,
    sunsetDate: new Date('2024-04-10T12:00:00.000Z'),
    originator: 'stargate',
    originatorMarketHref: 'https://app.beefy.com/vault/stargate-polygon-usdt',
    opportunityType: YieldOpportunityType.liquidityPool,
    id: 'mooStargateUSDT',
    baseToken: 'USDT',
    rewardToken: 'USDT',
    chain: 'POLYGON_MAINNET',
    beefyVaultId: 'stargate-polygon-usdt',
    cToken: 'mooStargateUSDT',
    cTokenContractAddress: '0x1c480521100c962f7da106839a5a504b5a7457a1',
    isBoosted: true,
    boostEnds: +new Date(stargateBoostEndDate),
    isBoostAnimated: true,
    description: makeStargateDescription({
      isBoosted: true,
      baseToken: 'USDT',
      beefyVaultId: 'stargate-polygon-usdt',
    }),
    boostCopy: [
      {
        type: DescriptionNodeType.TITLE,
        text: (props) =>
          translate('yield.stusdt_boost_description_title', {
            '%token': props.baseToken,
            '%baseApy': props.baseApy,
            '%boostPercentage': `${props.boostPercentage}%`,
          }),
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) =>
          translate('yield.stusdt_boost_description_row_1', {
            '%baseApy': props.baseApy,
            '%boostedApy': `${props.boostedApy}%`,
            '%maxSupplyAmount': '1,000 USDT',
          }),
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) =>
          translate('yield.stusdt_boost_description_row_2', {
            '%boostPercentage': `${props.boostPercentage}%`,
          }),
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () => translate('yield.stusdt_boost_description_row_3'),
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        linkIcon: 'box-arrow-up-right',
        text: () => translate('helpers.learn_more'),
        href: 'https://support.rhino.fi/en/article/stargate-usdt-boost-2023-all-you-need-to-know-d7dm8y/',
      },
    ],
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `User funds will go into a liquidity pool to earn Stargate’s native token, which is then swapped back into USDT. Bonus rewards are immediately claimable, while the base Stargate reward is auto-compounded. At each compounding period, the reward is added to the original investment, earning the next tranche of interest.  Rewards increase progressively over time.`,
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://app.beefy.com/vault/stargate-polygon-usdt',
        text: () =>
          `The %linkStart Beefy Finance %linkEnd vault deposits the user's spUSDT in a Stargate farm, earning the platform's governance token. Earned token is swapped for more spUSDT. To complete the compounding cycle, the new spUSDT is added to the farm, ready to go for the next earning event. The transaction cost required to do all this is socialized among the vault's users.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Beefy Finance handles the deposit, reinvestment and withdrawal processes (on user instruction, using smart contracts), so users can enter and exit easily and are not exposed to Stargate’s native token.`,
      },
    ],
    contractHref: 'https://polygonscan.com/address/0xeaFd412214283907c8D20d294228Ae26b8a4759b',
    auditHref: 'https://github.com/stargate-protocol/stargate/tree/main/audit',
    vaultHref: 'https://polygonscan.com/address/0x1C480521100c962F7da106839a5A504B5A7457a1',
  }),

  mooStargateUSDC: makeXChainBeefyStargateConfig({
    isSunset: true,
    sunsetDate: new Date('2024-04-10T12:00:00.000Z'),
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `User funds will go into a liquidity pool to earn Stargate’s native token, which is then swapped back into USDT. Bonus rewards are immediately claimable, while the base Stargate reward is auto-compounded. At each compounding period, the reward is added to the original investment, earning the next tranche of interest.  Rewards increase progressively over time.`,
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://app.beefy.com/vault/stargate-arb-usdc',
        text: () =>
          `The %linkStart Beefy Finance %linkEnd vault deposits the user's sarUSDC in a Stargate farm, earning the platform's governance token. Earned token is swapped for more sarUSDC. To complete the compounding cycle, the new sarUSDC is added to the farm, ready to go for the next earning event. The transaction cost required to do all this is socialized among the vault's users.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Beefy Finance handles the deposit, reinvestment and withdrawal processes (on user instruction, using smart contracts), so users can enter and exit easily and are not exposed to Stargate’s native token.`,
      },
    ],
    contractHref: 'https://arbiscan.io/address/0xD93D6A8aECbeB690314b8D6C4895638C39dBf62d',
    auditHref: 'https://github.com/stargate-protocol/stargate/tree/main/audit',
    vaultHref: 'https://arbiscan.io/address/0xD496eA90a454eA49e30A8fD9E053C186D4FC897D',
    originatorMarketHref: 'https://app.beefy.com/vault/stargate-arb-usdc',
    originator: 'stargate',
    opportunityType: YieldOpportunityType.liquidityPool,
    id: 'mooStargateUSDC',
    baseToken: 'USDC',
    chain: 'ARBITRUM_MAINNET',
    beefyVaultId: 'stargate-arb-usdc',
    cToken: 'mooStargateUSDC',
    cTokenContractAddress: '0xd496ea90a454ea49e30a8fd9e053c186d4fc897d',
    isBoosted: false,
    description: makeStargateDescription({
      isBoosted: false,
      baseToken: 'USDC',
      beefyVaultId: 'stargate-arb-usdc',
    }),
  }),

  'mooRamsesMAI-USDC': makeXChainBeefyStargateConfig({
    isDisabled: true,
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://beefy.com/',
        text: () =>
          `The %linkStart Beefy Finance %linkEnd vault deposits the user's MAI-USDC sLP in a Ramses farm, earning the platform's governance token. Earned token is swapped for MAI and USDC in order to acquire more of the same LP token. To complete the compounding cycle, the new MAI-USDC sLP is added to the farm, ready to go for the next earning event. The transaction cost required to do all this is socialized among the vault's users.`,
      },
      {
        href: 'https://app.beefy.com/vault/ramses-mai-usdc',
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          `Risks: this vault has medium market capitalisation and may be subject to asset volatility. It relies on an overcollateralised algorithmic stablecoin which carries the risk of de-pegging. Learn more about the vault %linkStart here %linkEnd`,
      },
    ],
    contractHref: 'https://arbiscan.io/address/0xBbe7e8336bb0108603A420298bdC6a8bA8A23F15',
    auditHref: 'https://github.com/stargate-protocol/stargate/tree/main/audit',
    vaultHref: 'https://arbiscan.io/address/0xa7fC98760331409c1FC0177dECF083cE9d5aed87',
    originatorMarketHref: 'https://app.beefy.com/vault/ramses-mai-usdc',
    opportunityType: YieldOpportunityType.liquidityPool,
    originator: 'beefy',
    id: 'mooRamsesMAI-USDC',
    baseToken: 'USDC',
    chain: 'ARBITRUM_MAINNET',
    beefyVaultId: 'ramses-mai-usdc',
    cToken: 'mooRamsesMAI-USDC',
    cTokenContractAddress: '0xa7fc98760331409c1fc0177decf083ce9d5aed87',
    isBoosted: false,
    protocol: 'ramses',
    icons: [
      // URL
      'https://assets.coingecko.com/coins/images/15264/small/mimatic-red.png?1620281018',
      'USDC',
    ],
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `This Beefy vault converts user funds into equal amounts of USDC and MAI, which are invested in a Ramses farm to earn its governance token. This governance token is swapped for more MAI and USDC and reinvested in the farm. The vault leverages this compounding process to earn yield`,
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () => ` %linkStart Beefy finance %linkEnd`,
        href: 'https://app.beefy.com/vault/ramses-mai-usdc',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          `Risks:\nthis vault has medium market capitalisation and may be subject to asset volatility.\nIt relies on an overcollateralised algorithmic stablecoin which carries the risk of de-pegging.\nLearn more about how it works on the Beefy opportunity page %linkStart here. %linkEnd`,
        href: 'https://app.beefy.com/vault/ramses-mai-usdc',
      },
    ],
  }),
  YIELDUSD: {
    isSunset: true,
    sunsetDate: new Date('2024-04-10T12:00:00.000Z'),
    descriptionV2: [
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        href: 'https://app.sommelier.finance/strategies/Real-Yield-USD',
        text: () =>
          `The %linkStart Sommelier Finance vault %linkEnd derives yield from trading or lending platform fees rather than incentives. This opportunity allocates capital to Aave, Compound lending pools and Uniswap V3 LP pools to maximize yield. The Real Yield USD Strategy achieves superior yields by optimizing Uniswap V3 tick ranges. By combining lending and LPing, it aims to provide higher sustained yields than simple lending or LPing strategies.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Determining the optimal allocation of stablecoins across these three protocols for the highest yield is non-trivial and requires off-chain computation.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Sommelier's infrastructure enables active optimization of capital of an erc-4626 vault (guided by off-chain computation) while remaining non-custodial, transparent, and decentralized. The optimal allocation is determined by a numerical optimization procedure that accounts for swap fees and market impact due to position size, and makes use of various simple time-series forecasting methods to estimate (future) base yields.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `If you invested in Real Yield USD when the boost was live, between the 13th of April and the 13th of May, you can now claim your $SOMM tokens. You can claim your tokens until April 2024.`,
      },
    ],
    auditHref: 'https://0xmacro.com/library/audits/sommelier-4.html',
    contractHref: 'https://etherscan.io/address/0x97e6E0a40a3D02F12d1cEC30ebfbAE04e37C119E',
    vaultHref: 'https://debank.com/profile/0x97e6e0a40a3d02f12d1cec30ebfbae04e37c119e',
    opportunityType: YieldOpportunityType.liquidityPool,
    originatorMarketHref: 'https://app.sommelier.finance/strategies/Real-Yield-USD',
    id: 'YIELDUSD',
    baseToken: 'USDC',
    type: 'sommelier',
    originator: 'sommelier',
    cToken: 'YIELDUSD',
    rewardToken: 'SOMM',
    isBoosted: true,
    boostEnds: +new Date(yieldUsdEndDate),
    dynamicBoostApy: true,
    isBoostAnimated: false,
    boostPercentage: 0,
    boostValueLimit: 20000,
    maxSupplyAmount: '$20,000',
    boostCopy: [
      {
        type: DescriptionNodeType.TITLE,
        text: (props) =>
          translate('yield.yieldusd_boost_description_title', {
            '%baseApy': `${props.baseApy}`,
            '%boostedApy': `${props.boost}%`,
            '%token': props.baseToken,
          }),
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) =>
          translate('yield.yieldusd_boost_description_row_1', {
            '%maxSupplyAmount': props.maxSupplyAmount,
            '%baseApy': `${props.baseApy}`,
            '%boostedApy': `${props.boostedApy}%`,
          }),
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) =>
          translate('yield.yieldusd_boost_description_row_2', {
            '%boostedApy': `${props.boost}%`,
          }),
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () => translate('yield.yieldusd_boost_description_row_3'),
      },
    ],
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'Real Yield USD derives yield from trading or lending platform fees rather than incentives. This opportunity allocates capital to Aave, Compound lending pools and Uniswap V3 LP pools to maximize yield.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'Real Yield USD Strategy achieves superior yields by optimizing Uniswap V3 tick ranges, which is too complex for other strategies.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'By combining lending and LPing, it aims to provide higher sustained yields than simple lending or LPing strategies.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'If you invested in Real Yield USD when the boost was live, between the 13th of April and the 13th of May, you can now claim your $SOMM tokens. You can claim your tokens until April 2024.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) => `All users investing are still receiveding ${props.apy} APY.`,
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () => '%linkStart View originator market %linkEnd',
        href: 'https://app.sommelier.finance/strategies/Real-Yield-USD',
        linkIcon: 'box-arrow-up-right',
      },
    ],
  },

  YIELDETH: {
    descriptionV2: [
      {
        href: 'https://app.sommelier.finance/strategies/Real-Yield-ETH/manage',
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          `The %linkStart Sommelier Finance vault %linkEnd utilises trusted staking protocols Aave and Compound in combination with Uniswap V3 to earn real yield using liquid staking tokens (LST). Leveraged Staking involves a continuous cycle of exchanging ETH for an ETH denominated LST (looping), utilizing the LST as collateral on Aave or Compound, borrowing more ETH, and repeating the cycle. Additionally it provides liquidity to ETH/ ETH denominated LST trading pairs on Uniswap V3. As a liquidity provider (LP), the vault deposits both ETH and an LST into a liquidity pool, earning fees from traders who swap between the two tokens.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Sommelier’s architecture uses a sophisticated solution to accumulate leverage that is highly capital efficient and significantly reduces gas and flash loan fees frequently associated with typical leverage practices. While the vault is leveraged, its smart contract enforces a minimum 1.05 health factor during each rebalance as a safety precaution and the vault closely monitors on-chain conditions to mitigate liquidation risk. If market conditions change, the vault is able to rapidly adjust leverage ratios to help avoid liquidation.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `For the liquidity provision strategies, the vault’s ability to run off-chain computation combined with Seven Seas’ deep Uniswap V3 experience, positions the vault to be a top performing LP in the pools that it utilizes. The vault will be able to adapt to changing price movements to quote the optimal tick range(s) that collect the most fees while minimizing impermanent loss.`,
      },
    ],
    contractHref: 'https://etherscan.io/address/0x955a31153e6764FE892757AcE79123ae996B0aFB',
    vaultHref: 'https://debank.com/profile/0xb5b29320d2dde5ba5bafa1ebcd270052070483ec',
    auditHref: 'https://0xmacro.com/library/audits/sommelier-7.html',
    originatorMarketHref: 'https://app.sommelier.finance/strategies/Real-Yield-ETH/manage',
    id: 'YIELDETH',
    baseToken: 'ETH',
    type: 'sommelier',
    originator: 'sommelier',
    cToken: 'YIELDETH',
    rewardToken: 'SOMM',
    isBoosted: true,
    boostEnds: +new Date(yieldEthEndDate),
    dynamicBoostApy: false,
    isBoostAnimated: false,
    boostPercentage: 2,
    boostValueLimit: 100,
    maxSupplyAmount: '100 ETH',
    boostCopy: [
      {
        type: DescriptionNodeType.TITLE,
        text: (props) => `Earn ${props.boostedApy}% on your ETH`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) =>
          `Invest in Real Yield ETH via Sommlier Finance at any time over the next 6 months, and earn ${props.baseApy} variable APY in ETH and an additional 2% fixed in SOMM tokens.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) =>
          'The boost runs from 28.06.2023 - 28.12.2023 inclusive. Investments limited to a maximum of 100 ETH per user. Boost calculated based on $1m average TVL.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) => 'Eth yield is auto-compounding. Claim your SOMM rewards regularly above.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () => 'Lock in market-beating return with no added risk, without ever leaving Layer 2.',
      },
    ],
    opportunityType: YieldOpportunityType.liquidityPool,
    cTokenContractAddress: '0xb5b29320d2Dde5BA5BAFA1EbcD270052070483ec',
    description: [
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'Utilise trusted staking protocols Aave and Compound in combination with Uniswap V3 to earn real yield using liquid staking tokens (LST).',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () => translate('global.view_originator_market'),
        href: 'https://app.sommelier.finance/strategies/Real-Yield-ETH',
        linkIcon: 'box-arrow-up-right',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'This vault swaps ETH for an LST that is used as collateral to borrow more ETH (looping) and liquidity provisioning in a Uniswap v3 pool to earn fees from traders.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'The opportunity is launching with an initial APY of ~4% and Sommelier expect this to be 10% in 30 days time.',
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          'Read more about how the Real Yield ETH opportunity works on Sommelier Finance %linkStart here. %linkEnd',
        href: 'https://app.sommelier.finance/strategies/Real-Yield-ETH',
      },
    ],
  },
  YIELDBTC: {
    isSunset: isFeatureEnabled(F_FLAGS_TYPES.DEV_SUNSET_YIELDBTC),
    descriptionV2: [
      {
        href: 'https://app.sommelier.finance/strategies/Real-Yield-BTC/manage',
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          `The primary goal of Real Yield BTC is to make available sustainable WBTC-denominated yields through a dynamic and evolving set of strategies. Initially, the %linkStart Sommelier Finance vault %linkEnd will use Morpho for efficient leveraged ETH staking against WBTC collateral. The vault may additionally borrow ETH to deposit into Real Yield ETH, which uses looping denominated LST ETH as collateral on Aave or Compound alongside providing to ETH/Eth denominated LST trading pairs on Uniswap V3.  In the future, there is a possibility for Real Yield BTC to evolve its capabilities by making use of other protocol integrations or Sommelier vaults.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Sommelier's architecture uses a sophisticated solution to accumulate leverage that is highly capital efficient and significantly reduces gas ad flash loan fees frequently associated with typical leverage practices. While the vault is leveraged, its smart contract enforces a minimum 1.05 health factor during each rebalance as a safety precaution and the vault closely monitors on-chain conditions to mitigate liquidation risk. If market conditions change, the vault is able to rapidly adjust leverage rations to help avoid liquidation.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `For the liquidity provision strategies, the vault's ability to run off-chain computation combined with seven Seas' deep Uniswap V3 experience, positions the vault to be a top performing LP in the pools that it utilizes. The vault will be able to adapt to changing price movements to quote the optimal tick range(s) that collect the most fees while minimising impermanent loss. `,
      },
    ],
    contractHref: 'https://etherscan.io/address/0x0274a704a6D9129F90A62dDC6f6024b33EcDad36',
    vaultHref: 'https://debank.com/profile/0x0274a704a6d9129f90a62ddc6f6024b33ecdad36',
    auditHref: 'https://0xmacro.com/library/audits/sommelier-8.html',

    originatorMarketHref: 'https://app.sommelier.finance/strategies/Real-Yield-BTC/manage',
    id: 'YIELDBTC',
    baseToken: 'BTC',
    type: 'sommelier',
    originator: 'sommelier',
    cToken: 'YIELDBTC',
    rewardToken: 'SOMM',
    isBoosted: true,
    boostEnds: +new Date(yieldBtcEndDate),
    dynamicBoostApy: false,
    isBoostAnimated: false,
    boostPercentage: 5,
    boostValueLimit: 5,
    maxSupplyAmount: '5 BTC',
    boostCopy: [
      {
        type: DescriptionNodeType.TITLE,
        text: (props) => `Earn ${props.boostedApy}% on your BTC`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: (props) =>
          `Invest in Real Yield BTC via Sommlier Finance at any time over the next month, and earn ${props.baseApy} variable APY in BTC and an additional 5% fixed in SOMM tokens. Organic base rates are subject to dynamics on lending markets but could range between 1%-6% and towards 4%-6% after matching on Morpho.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          'The boost runs from 13.07.2023 - 13.08.2023 inclusive. Investments limited to a maximum of 5 BTC per user. Boost calculated based on $1m average TVL.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () => 'BTC yield is auto-compounding. Claim your SOMM rewards regularly above.',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () => 'Lock in market-beating return with no added risk, without ever leaving Layer 2.',
      },
      {
        type: DescriptionNodeType.NORMAL_LINK,
        href: 'https://app.sommelier.finance/strategies/Real-Yield-BTC',
        text: () => translate('helpers.learn_more'),
      },
    ],
    opportunityType: YieldOpportunityType.liquidityPool,
    cTokenContractAddress: '0xb5b29320d2Dde5BA5BAFA1EbcD270052070483ec',
    description: [
      {
        href: 'https://app.sommelier.finance/strategies/Real-Yield-BTC/manage',
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          `The primary goal of Real Yield BTC is to make available sustainable WBTC-denominated yields through a dynamic and evolving set of strategies. Initially, the %linkStart Sommelier Finance vault %linkEnd will use Morpho for efficient leveraged ETH staking against WBTC collateral. The vault may additionally borrow ETH to deposit into Real Yield ETH, which uses looping denominated LST ETH as collateral on Aave or Compound alongside providing to ETH/Eth denominated LST trading pairs on Uniswap V3.  In the future, there is a possibility for Real Yield BTC to evolve its capabilities by making use of other protocol integrations or Sommelier vaults.`,
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () => translate('global.view_originator_market'),
        href: 'https://app.sommelier.finance/strategies/Real-Yield-BTC',
        linkIcon: 'box-arrow-up-right',
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `Sommelier's architecture uses a sophisticated solution to accumulate leverage that is highly capital efficient and significantly reduces gas ad flash loan fees frequently associated with typical leverage practices. While the vault is leveraged, its smart contract enforces a minimum 1.05 health factor during each rebalance as a safety precaution and the vault closely monitors on-chain conditions to mitigate liquidation risk. If market conditions change, the vault is able to rapidly adjust leverage rations to help avoid liquidation.`,
      },
      {
        type: DescriptionNodeType.TEXT,
        text: () =>
          `For the liquidity provision strategies, the vault's ability to run off-chain computation combined with seven Seas' deep Uniswap V3 experience, positions the vault to be a top performing LP in the pools that it utilizes. The vault will be able to adapt to changing price movements to quote the optimal tick range(s) that collect the most fees while minimising impermanent loss. `,
      },
      {
        type: DescriptionNodeType.TEXT_WITH_LINK,
        text: () =>
          'Read more about how the Real Yield BTC opportunity works on Sommelier Finance %linkStart here. %linkEnd',
        href: 'https://app.sommelier.finance/strategies/Real-Yield-BTC',
      },
    ],
  },
}

export const yieldTokens = Object.keys(yieldTokensConfigs).filter((key) => {
  const config = yieldTokensConfigs[key]
  return config.opportunityType !== YieldOpportunityType.strategy
})

export const strategiesTokens = Object.keys(yieldTokensConfigs).filter((key) => {
  const config = yieldTokensConfigs[key]
  return config.opportunityType === YieldOpportunityType.strategy
})

export const investmentTokens = [...yieldTokens, ...strategiesTokens]

export const findConfigsForBaseToken = (token: string): Record<string, YieldTokenConfig> => {
  return pickBy(yieldTokensConfigs, (yieldToken) => yieldToken?.baseToken === token)
}

export const yieldModalLocalStorageKeys = {
  ALL: 'yield-suggestion-modal',
} as const
