import { capitalize } from 'lodash'
import { translate } from '../../intl/i18n'
import { isAccountRegisteredInDB } from '../apiService'
import type { SupportedWallets } from '../../constants/types'
import { WALLETS } from '../../constants/types'

export const checkWalletType = async (address: string | undefined, userWalletType: SupportedWallets) => {
  const { walletType } = await isAccountRegisteredInDB(address)
  if (walletType === WALLETS.WALLET_CONNECT || userWalletType === WALLETS.WALLET_CONNECT) {
    return
  }
  if (
    address &&
    walletType &&
    walletType !== userWalletType &&
    (userWalletType === WALLETS.LEDGER || walletType === WALLETS.LEDGER)
  ) {
    throw new Error(translate('errors.wallet_type_mismatch', { '%walletType': capitalize(walletType) }))
  }
}
