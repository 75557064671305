import { translate } from '../../intl/i18n'

export enum ExploreCategories {
  defi = 'defi',
  nft = 'nft',
  meme = 'meme',
  infrastructure = 'infrastructure',
  gamefi = 'gamefi',
}

type ExploreCategory = {
  title: string
  description: string
  icon: string
  tokens: string[]
}

export const categories: Record<ExploreCategories, ExploreCategory> = {
  [ExploreCategories.defi]: {
    title: translate('categories.defi_title'),
    icon: 'globe',
    description: translate('categories.defi_description'),
    tokens: [
      'DVF',
      'GMX',
      'DPX',
      'UNI',
      'SUSHI',
      'CAP',
      'PSP',
      '1INCH',
      'CAKE',
      'BAL',
      '$FUR',
      '2GCC',
      '8PAY',
      'BANANA',
      'ALPACA',
      'AUTO',
      'BIFI',
      'BETA',
    ],
  },
  [ExploreCategories.nft]: {
    title: translate('categories.nft_title'),
    icon: 'brush-fill',
    description: translate('categories.nft_description'),
    tokens: ['APE', 'MANA', 'SAND', 'GAL', 'GUM', 'HAKA', 'BLUR'],
  },
  [ExploreCategories.meme]: {
    title: translate('categories.meme_title'),
    icon: 'emoji-smile-upside-down-fill',
    description: translate('categories.meme_description'),
    tokens: ['DOGE', 'ELON', 'POLYDOGE', 'SHIB', 'CUMMIES', 'CAT'],
  },
  [ExploreCategories.infrastructure]: {
    title: translate('categories.infrastructure_title'),
    icon: 'grid-3x3-gap-fill',
    description: translate('categories.infrastructure_description'),
    tokens: ['WBNB', 'ETH', 'AVAX', 'MATIC', 'LINK', 'ANKR', 'ATA', 'BAT'],
  },
  [ExploreCategories.gamefi]: {
    title: translate('categories.gamefi_title'),
    icon: 'joystick',
    description: translate('categories.gamefi_description'),
    tokens: ['GCOIN', 'MANA', 'MAGIC', 'KATA', 'SYL', 'WOD', 'ROSN', 'BTL', 'CHAMP'],
  },
}

export const isTokenFromCategory = (token: string, category?: ExploreCategories) =>
  category ? categories[category].tokens.includes(token) : true
