import { envConfig } from '../../env/envConfig'
import type { NSLookup, NSReverseLookup } from './nameServices'
const { zknsApi } = envConfig

/**
 * ZKNS API doc
 * https://docs.star.co/zkns-integration
 */

export const zknsReverseLookup: NSReverseLookup = async (address: string) => {
  try {
    const response = await fetch(`${zknsApi}/getReverseRecord/${address}`)

    if (response.status !== 200) {
      return ''
    }

    const zknsName = await response.text()
    return zknsName
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const zknsLookup: NSLookup = async (name: string) => {
  try {
    const response = await fetch(`${zknsApi}/getRecord/${name}`)
    const address = await response.text()
    return address
  } catch (error) {
    console.error(error)
    return false
  }
}

export const validateZKNSName = (name: string): boolean => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.((zk)|(zks)|(zksync))/
  return regex.test(name)
}
