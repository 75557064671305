import type { AbiItem } from 'ethereum-multicall/dist/esm/models'
import { rhinoERC1155Abi } from '../../../../services/abis/rhinoERC1155Abi'
import { nftMinterAbi } from '../../../../services/abis/nftMinterAbi'
import { nftRewardZksyncAbi } from '../../../../services/abis/nftRewardZksyncAbi'
import { nftRewardAbi } from '../../../../services/abis/nftRewardAbi'
import { nftMinterZksyncAbi } from '../../../../services/abis/nftMinterZksyncAbi'
import { rhinofiWrapped2023ZksyncNFTAbi } from '../../../../services/abis/rhinofiWrapped2023ZksyncNFTAbi'
import { envConfig } from '../../../../env/envConfig'
import { getWeb3 } from '../../../../services/wallets/wallet'
import type { EVMNFTType } from '../../../../services/nfts/mintNFT'

const contractAbisPerChain: Record<string, Record<EVMNFTType, AbiItem[]>> = {
  ZKSYNC: {
    hunter: nftMinterZksyncAbi as AbiItem[],
    TOP30: nftRewardZksyncAbi as AbiItem[],
    wrapped2023: rhinofiWrapped2023ZksyncNFTAbi as AbiItem[],
    VOLUME: nftRewardAbi as AbiItem[],
    TXCOUNT: nftRewardAbi as AbiItem[],
    ALPHAKEY: nftRewardAbi as AbiItem[],
    BETAKEY: rhinoERC1155Abi as AbiItem[],
  },
  default: {
    hunter: nftMinterAbi as AbiItem[],
    TOP30: nftRewardAbi as AbiItem[],
    wrapped2023: rhinofiWrapped2023ZksyncNFTAbi as AbiItem[],
    VOLUME: nftRewardAbi as AbiItem[],
    TXCOUNT: nftRewardAbi as AbiItem[],
    ALPHAKEY: nftRewardAbi as AbiItem[],
    BETAKEY: rhinoERC1155Abi as AbiItem[],
  },
}

export const getNFTAbi = (type: EVMNFTType, chain: string): AbiItem[] => {
  if (chain in contractAbisPerChain) {
    return contractAbisPerChain[chain]?.[type]
  }
  return contractAbisPerChain.default[type]
}

type ERC1155Config = { address: string; tokenId: number }
type NFTContractConfig = Partial<Record<EVMNFTType, string | ERC1155Config>>

export const getNFTContractConfig = (type: EVMNFTType, chain: string) => {
  const contracts: Record<string, NFTContractConfig> = envConfig.nftsContracts

  const contractConfig = contracts[chain][type]

  if (typeof contractConfig === 'string') {
    return {
      address: contractConfig,
      tokenId: 0,
    }
  }

  return contractConfig
}

export const getNFTContract = (type: EVMNFTType, chain: string, useDefaultProvider = true) => {
  const web3PerChain = getWeb3()
  const web3 = useDefaultProvider ? web3PerChain.DEFAULT : web3PerChain[chain]

  const contractAddress = getNFTContractConfig(type, chain)
  const contractAbi = getNFTAbi(type, chain)

  if (!web3 || !contractAddress || !contractAbi) {
    return null
  }

  return new web3.eth.Contract(contractAbi, contractAddress.address)
}

export const hasNFTTypeContract = (type: EVMNFTType, chain: string): boolean => {
  const contracts: Record<string, NFTContractConfig> = envConfig.nftsContracts
  return !!contracts?.[chain]?.[type]
}
