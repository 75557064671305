import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../env/envConfig'

import type { FillsState } from '../reducers/types/FillsState'
import type { Pagination } from '../reducers/types/PaginationState'
import type { WithdrawalsHistoryState } from '../reducers/types/WithdrawalsHistoryState'
import type { TransfersState } from '../reducers/types/TransfersState'
import type { GetFillsFilters, GetTransfersFilters } from './apiService'
import type {
  GetCrossChainSwapsHistoryFilters,
  GetCrossChainSwapsHistoryResponse,
} from './apiService/getCrossChainSwapsHistory'
import { addAuthorizationHeader } from './auth/addAuthorizationHeader'
import type { PaginationFilters } from './apiService/PaginationFilters'
import type { GetWithdrawalsHistoryFilters } from './apiService/getWithdrawalsHistory'
import type { GetBridgeHistoryFilters, GetBridgeHistoryResponse } from './apiService/getBridgeHistory'
import type { RegistrationStatuses } from './dvfClient/DvfClientInstance'
import { getOrders } from './apiService/getOrders'

const endpointsWithAuth = [
  'getDepositHistory',
  'getCrossChainSwapsHistory',
  'getFills',
  'getWithdrawalsHistory',
  'getTransfersHistory',
  'getFillsHistory',
  'getBridgeHistory',
]

export type GetDepositsHistoryFilters = {
  token?: string
  status?: string
} & PaginationFilters

export type DepositsHistoryItem = {
  _id: string
  type: string
  token: string
  chain: string
  amount: number | string
  transactionHash?: string
  date: string
  source?: string
  status: string
  depositFee: number | string
}

export type DepositsHistoryState = {
  items: DepositsHistoryItem[]
  pagination: Pagination
}

export type OpenOrders = ReturnType<typeof getOrders>
export const tradingApi = createApi({
  reducerPath: 'tradingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.tradingApi}/v1/trading`,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      headers.set('accept', 'application/json')
      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: [
    'depositHistory',
    'crossChainSwapsHistory',
    'fills',
    'withdrawalsHistory',
    'transfers',
    'fillsHistory',
    'bridges',
    'registrationStatus',
    'openOrders',
  ],
  endpoints: (builder) => ({
    getDepositHistory: builder.query<DepositsHistoryState, GetDepositsHistoryFilters>({
      query: (params) => ({ url: '/deposits?excludeBridge=true', params }),
      providesTags: ['depositHistory'],
    }),
    getCrossChainSwapsHistory: builder.query<GetCrossChainSwapsHistoryResponse, GetCrossChainSwapsHistoryFilters>({
      query: (params) => ({ url: '/chainswap/swaps', params }),
      providesTags: ['crossChainSwapsHistory'],
    }),
    getFills: builder.query<FillsState, GetFillsFilters>({
      query: (params) => ({ url: '/fills', params }),
      providesTags: ['fills'],
    }),
    getWithdrawalsHistory: builder.query<WithdrawalsHistoryState, GetWithdrawalsHistoryFilters>({
      query: (params) => ({ url: '/withdrawals?excludeBridge=true', params }),
      providesTags: ['withdrawalsHistory'],
    }),
    getTransfersHistory: builder.query<TransfersState, GetTransfersFilters>({
      query: (params) => ({ url: '/transfers', params }),
      providesTags: ['transfers'],
    }),
    getFillsHistory: builder.query<FillsState, GetFillsFilters>({
      query: (params) => ({ url: '/fills', params }),
      providesTags: ['fillsHistory'],
    }),
    getBridgeHistory: builder.query<GetBridgeHistoryResponse, GetBridgeHistoryFilters>({
      query: (params) => ({ url: '/bridges', params }),
      providesTags: ['bridges'],
    }),
    getRegistrationStatus: builder.query<RegistrationStatuses, { ethAddress: string }>({
      query: (params) => ({ url: `/registrations/${params.ethAddress}` }),
      providesTags: ['registrationStatus'],
    }),
    getOpenOrders: builder.query({
      providesTags: ['openOrders'],
      queryFn: async () => {
        try {
          const orders = await getOrders()
          return { data: orders }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch open orders' } }
        }
      },
    }),
  }),
})

export const {
  useGetWithdrawalsHistoryQuery,
  useGetDepositHistoryQuery,
  useGetCrossChainSwapsHistoryQuery,
  useGetFillsQuery,
  useGetTransfersHistoryQuery,
  useGetFillsHistoryQuery,
  useGetBridgeHistoryQuery,
  useGetRegistrationStatusQuery,
  useGetOpenOrdersQuery,
} = tradingApi
