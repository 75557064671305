import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { updateSecondaryAddress } from '../secondaryWallet/secondaryWalletSlice'

export let tronListenerFunction: (event: MessageEvent) => void | undefined

export const createTronListener = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  tronListenerFunction = (event: MessageEvent) => {
    if (event.data && event.data.message && event.data.message.action === 'accountsChanged') {
      const changedAddress = event.data.message.data.address
      dispatch(updateSecondaryAddress(changedAddress))
    }
  }
  window.addEventListener('message', tronListenerFunction)
}
