import { getTronProvider } from '../secondaryWallet/providers/tronProvider'

const maxAmount = 10 ** 10
export const approveTronDeposit = async (tokenAddress: string, contractAddress: string) => {
  const { tronWeb } = getTronProvider()
  if (!tronWeb) {
    throw new Error('Please connect your Tron wallet first.')
  }
  const contract = await tronWeb.contract().at(tokenAddress)
  if (!('approve' in contract)) {
    return false
  }
  const approve = contract.approve as (
    address: string,
    amount: number,
  ) => { send: (options: { shouldPollResponse: boolean }) => void }
  return approve(contractAddress, maxAmount).send({
    shouldPollResponse: true,
  })
}
