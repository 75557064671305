import type { Middleware } from '@reduxjs/toolkit'
import { ADDRESS_CHANGE, WALLET_DISCONNECT } from '../actions/types/user'
import { activityTrackersApi } from '../services/activityTrackers/activityTrackers.api'
import { airdropsApi } from '../services/airdropsApi'
import { arbitrumChargeApi } from '../services/arbitrumChargeApi'
import { referralApi } from '../services/referralApi'
import { tradingApi } from '../services/tradingApi'
import { boostApi } from '../services/yieldService/boostApi'
import { yieldApi } from '../services/yieldService/yieldApi'
import { poolsApi } from '../services/poolService'
import { mintedNFTsApi } from '../services/mintedNFTsApi'
import { geoblockingApi } from '../services/geoblockingApi'

export const clearRTKCacheMiddleware: Middleware = (storeApi) => (next) => (action) => {
  if (!(action !== null && typeof action === 'object' && 'type' in action && typeof action.type === 'string')) {
    return next(action)
  }

  if (action?.type === ADDRESS_CHANGE || action?.type === WALLET_DISCONNECT) {
    storeApi.dispatch(tradingApi.util.resetApiState())
    storeApi.dispatch(geoblockingApi.util.resetApiState())
    storeApi.dispatch(poolsApi.util.resetApiState())
    storeApi.dispatch(yieldApi.util.resetApiState())
    storeApi.dispatch(boostApi.util.resetApiState())
    storeApi.dispatch(activityTrackersApi.util.resetApiState())
    storeApi.dispatch(mintedNFTsApi.util.resetApiState())

    // Only clear the cache of the endpoints related to the user address
    storeApi.dispatch(referralApi.util.invalidateTags(['Authenticated']))
    storeApi.dispatch(airdropsApi.util.invalidateTags(['Authenticated']))
    storeApi.dispatch(arbitrumChargeApi.util.invalidateTags(['Authenticated']))
  }

  return next(action)
}
