import { Buffer } from 'buffer'
import type { BigNumber } from 'bignumber.js'
import BN from 'bignumber.js'
import type { ToBufferInputTypes } from 'ethereumjs-util'
import { addHexPrefix, publicToAddress, toBuffer, toChecksumAddress, toRpcSig } from 'ethereumjs-util'
import HDKey from 'hdkey'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'web3... Remove this comment to see the full error message
import HookedWalletSubprovider from 'web3-provider-engine/subproviders/hooked-wallet'
import type { BaseProvider } from '@metamask/providers'
import type TransportWebHid from '@ledgerhq/hw-transport-webhid'
import type Eth from '@ledgerhq/hw-app-eth'
import type { TxData } from '@ethereumjs/tx/dist/types'
import { translate } from '../../intl/i18n'
import { showSignPrompt } from '../../actions/notificationActions'
import { WALLETS } from '../../constants/types'
import { sendWalletFailedEvent, sendWalletSuccessEvent } from '../apiService'
import { approveTransaction, EIP1559GasFeeEstimation } from '../ethereumService'
import { WEI_MAX_LIMIT } from '../../constants/portalConfig'
import { SIGN_MESSAGE, SIGN_TRANSACTION } from '../../constants/walletEvents'
import { envConfig } from '../../env/envConfig'
import { getChainNameForNetworkId } from '../ethereum/chainProviders'
import type { DvfClientInstance } from '../dvfClient/DvfClientInstance'
import { isValidWalletError } from '../helperService/isValidWalletError'
import { getWeb3 } from './wallet'
import { cmpVersions } from './cmpVersions'
type Transport = Awaited<ReturnType<typeof TransportWebHid.create>>

const { network } = envConfig

const defaultPath = "44'/60'/0'/0"
export const MIN_SUPPORTED_VERSION = '1.9.16'
export const MAX_SUPPORTED_VERSION = '1.10.3'
const OS_NAME = 'BOLOS' // This is the name of the app when nothing is running / OS name
const supportedAppsTestnet = ['Ethereum', 'Ethereum StarkEx', 'Eth Goerli', 'Eth Ropsten']
const supportedAppsMainnet = ['Ethereum', 'Ethereum StarkEx']
const supportedApps = network === 1 ? supportedAppsMainnet : supportedAppsTestnet

export type LedgerProviderType = BaseProvider
export class LedgerProvider extends HookedWalletSubprovider {
  address: string
  path: string
  constructor(path = defaultPath, address: string) {
    super({
      getAccounts: (cb: (arg1: Error | null | undefined | unknown, arg2: Array<string>) => void) =>
        cb(null, [this.address]),
      signTransaction: signTransaction(path),
      signPersonalMessage: signMessage(path),
      signMessage: signMessage(path),
      approveTransaction: approveTransaction,
    })
    this.address = address
    this.path = path
  }
}

const getOpenApp = async (transport: Transport) => {
  // Send B0010000 APDU command to ledger to check what app is currently open
  const currentApp = await transport.send(0xb0, 0x01, 0x00, 0x00)

  const appNameLen = parseInt(currentApp.slice(1, 2).toString('hex'), 16)
  const app = currentApp.slice(2, 2 + appNameLen).toString()
  const verNameLen = parseInt(currentApp.slice(2 + appNameLen, 2 + appNameLen + 1).toString('hex'), 16)
  const version = currentApp.slice(2 + appNameLen + 1, 2 + appNameLen + 1 + verNameLen).toString()

  return {
    app,
    version,
  }
}

export const openEthApp = async () => {
  const { default: TransportWebHid } = await import('@ledgerhq/hw-transport-webhid')
  const transport = await TransportWebHid.create()
  const { app } = await getOpenApp(transport)

  // If the user has some other app open, close it
  if (!supportedApps.includes(app) && app !== OS_NAME) {
    // Close the app by sending B0A7000000 APDU command
    showSignPrompt(true)
    await transport.send(0xb0, 0xa7, 0x00, 0x00)
    showSignPrompt(false)
  }
  // If the user has no apps open, attempt to open it
  if (app === OS_NAME) {
    showSignPrompt(true)
    await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from('Ethereum', 'ascii'))
    showSignPrompt(false)
  }
  await transport.close()
}

const checkIfCorrectAppIsOpen = async (transport: Transport) => {
  const { app } = await getOpenApp(transport)
  return supportedApps.includes(app) && app !== OS_NAME
}

export const listAccounts = async (path: string, start: number, accountCount: number) => {
  let transport
  const { default: TransportWebHid } = await import('@ledgerhq/hw-transport-webhid')
  const { default: Eth } = await import('@ledgerhq/hw-app-eth')
  try {
    const isSupported = TransportWebHid.isSupported()
    if (!isSupported) {
      throw new Error(translate('global.error_ledger_webusb_not_supported'))
    }
    transport = await TransportWebHid.create()
    const isCorrectAppOpen = await checkIfCorrectAppIsOpen(transport)
    if (!isCorrectAppOpen) {
      throw new Error(translate('global.error_ledger_eth'))
    }
    const eth = new Eth(transport)
    const { version } = await eth.getAppConfiguration()

    if (cmpVersions(version, MIN_SUPPORTED_VERSION) < 0 || cmpVersions(version, MAX_SUPPORTED_VERSION) > 0) {
      throw new Error('errors.wrong_ledger_version')
    }
    if (path === 'legacy') {
      return getLegacyAccounts(eth, start, accountCount)
    } else if (path === 'live') {
      return getLiveAccounts(eth, start, accountCount)
    } else {
      // cast to take into account mutating account in next line
      const account = (await eth.getAddress(path)) as { path: string } & Awaited<ReturnType<typeof eth.getAddress>>
      account.path = path
      return [account]
    }
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    if (transport?.close) {
      await transport.close()
    }
  }
}

const getLegacyAccounts = async (eth: Eth, start: number, accountCount: number) => {
  const root = await eth.getAddress("44'/60'/0'", false, true)
  const hdKey = new HDKey()
  hdKey.publicKey = Buffer.from(root.publicKey, 'hex')
  // @ts-expect-error -- chainCode can be undefined
  hdKey.chainCode = Buffer.from(root.chainCode, 'hex')

  let accounts = []

  for (let i = 0; i < accountCount; i++) {
    // cast to account for adding path and address to the object
    const account = hdKey.derive(`m/${start + i}`) as ReturnType<typeof hdKey.derive> & {
      path: string
      address: string
    }
    account.address = '0x' + publicToAddress(account.publicKey, true).toString('hex')
    account.address = toChecksumAddress(account.address)
    account.path = `44'/60'/0'/${start + i}`
    accounts.push(account)
  }
  return accounts
}

const getLiveAccounts = async (eth: Eth, start: number, accountCount: number) => {
  const accounts = []
  for (let i = 0; i < accountCount; i++) {
    const path = `44'/60'/${start + i}'/0/0`
    const account = await eth.getAddress(path)
    accounts.push({
      ...account,
      path,
    })
  }
  return accounts
}

export const signMessage =
  (ledgerPath: string) =>
  async (
    msgParams: { data: ToBufferInputTypes },
    cb: (error: null | undefined | unknown | Error, value?: string) => void,
  ) => {
    let transport
    try {
      const { default: TransportWebHid } = await import('@ledgerhq/hw-transport-webhid')
      const { default: Eth } = await import('@ledgerhq/hw-app-eth')
      const message = toBuffer(msgParams.data).toString('hex')
      transport = await TransportWebHid.create()
      const eth = new Eth(transport)
      showSignPrompt(true)
      const signed = await eth.signPersonalMessage(ledgerPath, message)
      const signature = toRpcSig(signed.v, toBuffer(`0x${signed.r}`), toBuffer(`0x${signed.s}`))
      showSignPrompt(false)
      await transport.close()
      cb(null, signature)
      sendWalletSuccessEvent(WALLETS.LEDGER, SIGN_MESSAGE)
    } catch (error) {
      if (transport) {
        await transport.close()
      }
      if (isValidWalletError(error)) {
        sendWalletFailedEvent(WALLETS.LEDGER, SIGN_MESSAGE)
      }
      showSignPrompt(false)
      cb(error)
    }
  }

type TxParams = Omit<TxData, 'gasPrice'> & { chainId: string; maxPriorityFee: string }
type RawTxParams = TxParams & { maxFeePerGas?: string; maxPriorityFeePerGas?: string }
const signTransaction =
  (ledgerPath: string) =>
  async (txParams: TxParams, cb: (error: Error | undefined | null | unknown, value?: string) => void) => {
    const { default: TransportWebHid } = await import('@ledgerhq/hw-transport-webhid')
    const { default: Eth } = await import('@ledgerhq/hw-app-eth')
    const { FeeMarketEIP1559Transaction, Transaction } = await import('@ethereumjs/tx')
    const { DEFAULT: web3 } = getWeb3()
    const chainId = Number(txParams.chainId)
    const isEthereum = (getChainNameForNetworkId(chainId) ?? 'ETHEREUM') === 'ETHEREUM'

    let common
    if (isEthereum) {
      const { default: Common, Hardfork } = await import('@ethereumjs/common')
      common = new Common({
        chain: chainId,
        hardfork: Hardfork.London,
      })
    }

    let transport: Transport

    try {
      transport = await TransportWebHid.create()
      const rawTx: RawTxParams = { ...txParams }

      let ethTx, messageToSign
      if (isEthereum) {
        const { baseFee } = await EIP1559GasFeeEstimation()
        const baseFeeInWei = Math.floor(baseFee * 10 ** 9)
        const maxPriorityFeePerGas = txParams.maxPriorityFee
        rawTx.maxPriorityFeePerGas = web3.utils.toHex(maxPriorityFeePerGas)
        rawTx.maxFeePerGas = web3.utils.toHex(baseFeeInWei + parseInt(txParams.maxPriorityFee))

        ethTx = FeeMarketEIP1559Transaction.fromTxData(rawTx, { common })

        if (parseInt(rawTx.maxFeePerGas) > WEI_MAX_LIMIT) {
          throw new Error('The transaction is overpriced!')
        }

        messageToSign = ethTx.getMessageToSign(false)
      } else {
        ethTx = Transaction.fromTxData({
          ...rawTx,
          v: web3.utils.toHex(rawTx.chainId),
          r: toBuffer(0),
          s: toBuffer(0),
        })
        messageToSign = ethTx.serialize().toString('hex')
      }

      const eth = new Eth(transport)
      showSignPrompt(true, 'tx')
      // @ts-expect-error == getMessageToSign returns a Buffer, so messageToSign can be a string or buffer
      const rsv = await eth.signTransaction(ledgerPath, messageToSign)
      showSignPrompt(false, 'tx')

      let signedTx
      if (isEthereum) {
        signedTx = FeeMarketEIP1559Transaction.fromTxData(
          {
            ...rawTx,
            r: addHexPrefix(rsv.r),
            s: addHexPrefix(rsv.s),
            v: addHexPrefix(rsv.v),
          },
          { common },
        )
      } else {
        signedTx = Transaction.fromTxData({
          ...rawTx,
          r: addHexPrefix(rsv.r),
          s: addHexPrefix(rsv.s),
          v: addHexPrefix(rsv.v),
        })
      }

      cb(null, `0x${signedTx.serialize().toString('hex')}`)
    } catch (error) {
      if (isValidWalletError(error)) {
        sendWalletFailedEvent(WALLETS.LEDGER, SIGN_TRANSACTION)
      }
      showSignPrompt(false, 'tx')
      cb(error)
    } finally {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      await transport.close()
    }
  }

export const provideContractDataForAddress = async (
  dvf: DvfClientInstance,
  tokenAddress: string,
  transferQuantization: BigNumber | null = null,
) => {
  await dvf.token.provideContractData(null, tokenAddress, transferQuantization)
}

export const provideContractData = async (
  dvf: DvfClientInstance,
  token: null | undefined | string = '',
  provideQuantization = false,
) => {
  const { tokenAddress, quantization } = dvf.token.getTokenInfo(token)
  let transferQuantization = null
  if (provideQuantization) {
    transferQuantization = new BN(quantization)
  }
  await provideContractDataForAddress(dvf, tokenAddress, transferQuantization)
}
