import { EthereumProvider as WalletConnectProvider } from '@walletconnect/ethereum-provider'
import { translate } from '../../../intl/i18n'
import type { SupportedWallets } from '../../../constants/types'
import { WALLETS } from '../../../constants/types'
import { setUpWeb3 } from '../../web3/web3Service'
import { getCoinbaseProvider } from '../coinbaseService'
import { envConfig } from '../../../env/envConfig'
import type { WebProvider } from '../walletService'
import { isCoinbase, isMetamask, isOKX, isTokenPocket, isTrustWallet, isWalletConnect } from '../walletService'
import type { EIP1193Provider } from '../../eip6963/EthereumProvider.types'

const { addNetworkParametersPerNetworkId, network } = envConfig

export const WALLET_CONNECT_PROJECT_ID = '982077ac6b911c7270812e5545e16b60'

export class WebWallet {
  public provider: WebProvider | EIP1193Provider | null = null
  public walletType: SupportedWallets
  public address = ''
  public name = ''

  constructor(walletType: SupportedWallets, name?: string) {
    this.walletType = walletType
    this.checkIsCorrectWebWallet()
    this.name = name || walletType
  }

  // This method needs to be called on instantiation
  public async connect(injectedProvider?: EIP1193Provider) {
    await this.setProvider(injectedProvider)
    await this.checkIsWalletAvailable()
    return {
      web3: await setUpWeb3({
        provider: this.provider,
        isSubprovider: false,
        nativeSupport: true,
      }),
      provider: this.provider,
    }
  }

  private async checkIsWalletAvailable() {
    if (!this.provider) {
      throw new Error(translate('errors.no_wallet_browser'))
    }
    if (isWalletConnect(this.provider, this.walletType)) {
      this.address = this.provider.accounts?.[0]?.toLowerCase() || ''
      return this.address.length > 0
    }

    const accounts = (await this.provider.request({ method: 'eth_requestAccounts' })) as string[]

    if (accounts && accounts.length === 0 && isMetamask(this.provider, this.walletType) && this.provider.isMetaMask) {
      const isUnlocked = await window.ethereum._metamask.isUnlocked()
      if (!isUnlocked) {
        throw new Error(translate('global.metamask_locked'))
      }
    }
    this.address =
      accounts?.[0]?.toLowerCase() ||
      ('selectedAddress' in this.provider && this.provider?.selectedAddress?.toLowerCase()) ||
      ''
    return (accounts?.length ?? 0) > 0
  }

  private checkIsCorrectWebWallet() {
    if (!this.provider) {
      return
    }
    const isCorrect =
      (isTrustWallet(this.provider, this.walletType) && this.provider.isTrust) ||
      (isMetamask(this.provider, this.walletType) && this.provider.isMetaMask) ||
      (isTokenPocket(this.provider, this.walletType) && this.provider.isTokenPocket) ||
      (isCoinbase(this.provider, this.walletType) && this.provider.isCoinbaseWallet) ||
      (isOKX(this.provider, this.walletType) && this.provider.isOkxWallet) ||
      isWalletConnect(this.provider, this.walletType)
    if (!isCorrect) {
      throw new Error(translate('errors.wrong_wallet_selection'))
    }
  }

  private async setProvider(injectedProvider?: EIP1193Provider) {
    if (injectedProvider) {
      this.provider = injectedProvider
      return
    }

    if (this.walletType === WALLETS.WALLET_CONNECT) {
      const supportedNetworks = Object.keys(addNetworkParametersPerNetworkId).map((key) => parseInt(key))

      this.provider = await WalletConnectProvider.init({
        projectId: WALLET_CONNECT_PROJECT_ID,
        chains: [network],
        optionalChains: supportedNetworks,
        methods: ['personal_sign', 'eth_sendTransaction', 'eth_signTypedData', 'eth_signTypedData_v4'],
        optionalMethods: [
          'eth_chainId',
          'eth_requestAccounts',
          'eth_accounts',
          'eth_signTypedData_v3',
          'eth_getEncryptionPublicKey',
          'eth_decrypt',
          'wallet_switchEthereumChain',
          'wallet_addEthereumChain',
        ],
        showQrModal: true,
      })

      await this.provider.enable()

      return
    }
    if (this.walletType === WALLETS.COINBASE) {
      this.provider = getCoinbaseProvider()
      return
    }

    if (this.walletType === WALLETS.OKX) {
      this.provider = window.okxwallet
      return
    }

    // Find the correct provider in case of multiple web providers
    let provider: WebProvider = window.ethereum
    if (window?.ethereum?.providers?.length) {
      window.ethereum.providers.forEach((ethereumProvider: WebProvider) => {
        if (isMetamask(ethereumProvider, this.walletType) && ethereumProvider?.isMetaMask) {
          provider = ethereumProvider
        } else if (isCoinbase(ethereumProvider, this.walletType) && ethereumProvider?.isCoinbaseWallet) {
          provider = ethereumProvider
        } else if (isOKX(ethereumProvider, this.walletType) && ethereumProvider?.isOkxWallet) {
          provider = ethereumProvider
        }
      })
    }
    this.provider = provider
  }

  public toJSON() {
    return {
      address: this.address,
      walletType: this.walletType,
      name: this.name,
    }
  }
}
