import type { CoinbaseWalletProvider } from '@coinbase/wallet-sdk'
import type { MetaMaskInpageProvider, BaseProvider } from '@metamask/providers'
import type WalletConnectProvider from '@walletconnect/ethereum-provider'
import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { WALLETS } from '../../constants/types'
import type { SupportedWallets } from '../../constants/types'
import type { EIP1193Provider } from '../eip6963/EthereumProvider.types'
import type { LedgerProviderType } from './ledgerService'
import type { KeystoreProviderType } from './keystoreService'

export const createWalletMetadata = <T extends object>(data: T, type: SupportedWallets) => {
  return {
    ...data,
    walletType: type,
  }
}
export const isValidWallet = (walletType: undefined | string | SupportedWallets): walletType is SupportedWallets => {
  if (Object.values(WALLETS).includes(walletType as SupportedWallets)) {
    return true
  }
  return false
}

export const isMobileBrowser = (walletType: SupportedWallets) => {
  const DEEP_LINKABLE_WALLETS = [WALLETS.METAMASK, WALLETS.TOKEN_POCKET, WALLETS.TRUST, WALLETS.OKX]
  return window.innerWidth <= defaultThreshold.xxs && DEEP_LINKABLE_WALLETS.includes(walletType) && !window.ethereum
}

export const triggerDeepLink = (walletType: SupportedWallets) => {
  const currentURL = window.location.href
  const encodedURI = encodeURIComponent(currentURL)
  if (walletType === WALLETS.METAMASK) {
    window.open(`https://metamask.app.link/dapp/${currentURL.replace(/^https?:\/\//, '')}`)
  } else if (walletType === WALLETS.TRUST) {
    window.open(`https://link.trustwallet.com/open_url?url=${encodedURI}`)
  } else if (walletType === WALLETS.TOKEN_POCKET) {
    const params = encodeURIComponent(
      JSON.stringify({
        url: currentURL,
        chain: 'eth',
      }),
    )
    const tokenPocketDeepLink = `tpoutside://pull.activity?params=${params}`
    window.open(tokenPocketDeepLink)
  } else if (walletType === WALLETS.OKX) {
    window.open(`okx://wallet/dapp/details?dappUrl=${encodedURI}`)
  }
}

type TrustWalletProvider = BaseProvider & { isTrust: true }
type TokenPocketProvider = BaseProvider & { isTokenPocket: true }
type OKXWalletProvider = BaseProvider & { isOkxWallet: true }

export type WebProvider =
  | CoinbaseWalletProvider
  | WalletConnectProvider
  | MetaMaskInpageProvider
  | TrustWalletProvider
  | TokenPocketProvider

export type Provider = WebProvider | KeystoreProviderType | LedgerProviderType | EIP1193Provider

// This is needed as when calling on|removeListener on Provider directly
// with we get a TS error, due to signatures of Provider type
// members being incompatible (removing WalletConnectProvider from WebProvider
// union makes it go away).
// TODO FUD-1340: use unknown for arg and validate that it's of expected type at
// use site.
type AnyEmitter = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TO DO above
  removeListener: (event: any, fn: (...args: any[]) => void) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TO DO above
  on: (event: any, fn: (arg: any) => void) => void
}

export const providerToEmitter = (provider: Provider): AnyEmitter | null => {
  if (typeof provider?.on !== 'function') {
    return null
  }
  if (typeof provider?.removeListener !== 'function') {
    return null
  }
  return provider
}

export const isWalletConnect = (
  provider: Provider | null,
  walletType?: SupportedWallets,
): provider is WalletConnectProvider => {
  return walletType === WALLETS.WALLET_CONNECT && !!provider
}

export const isTrustWallet = (
  provider: Provider | null,
  walletType?: SupportedWallets,
): provider is TrustWalletProvider => {
  return walletType === WALLETS.TRUST && !!provider
}

export const isMetamask = (
  provider: Provider | null,
  walletType?: SupportedWallets,
): provider is MetaMaskInpageProvider => {
  return walletType === WALLETS.METAMASK && !!provider
}

export const isTokenPocket = (
  provider: Provider | null,
  walletType?: SupportedWallets,
): provider is TokenPocketProvider => {
  return walletType === WALLETS.TOKEN_POCKET && !!provider
}

export const isCoinbase = (
  provider: Provider | null,
  walletType?: SupportedWallets,
): provider is CoinbaseWalletProvider => {
  return walletType === WALLETS.COINBASE && !!provider
}

export const isOKX = (provider: Provider | null, walletType?: SupportedWallets): provider is OKXWalletProvider => {
  return walletType === WALLETS.OKX && !!provider
}
