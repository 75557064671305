export const cmpVersions = (versionA: string, versionB: string) => {
  let i, diff
  const regExStrip0 = /(\.0+)+$/
  const segmentsA = versionA.replace(regExStrip0, '').split('.')
  const segmentsB = versionB.replace(regExStrip0, '').split('.')
  const length = Math.min(segmentsA.length, segmentsB.length)

  for (i = 0; i < length; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10)
    if (diff) {
      return diff
    }
  }
  return segmentsA.length - segmentsB.length
}
