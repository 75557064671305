import { getConfigKey } from '../../../../services/helperService'
import { isFeatureEnabled } from '../../../../services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from '../../../../constants/types'

export const getEnabledNFTMinters = () =>
  getConfigKey('enabledNFTMinters').filter((name) => {
    if (name === 'ZIRCUIT' && !isFeatureEnabled(F_FLAGS_TYPES.ZIRCUIT_DOPE)) {
      return false
    }
    return true
  })

export const isNFTMinterEnabledForChain = (chain: string): boolean => getEnabledNFTMinters().includes(chain)
