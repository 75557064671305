import BigNumber from 'bignumber.js'
import { getTronProvider } from '../secondaryWallet/providers/tronProvider'
import { executeViewTronCall } from '../starknetService/executeViewTronCall'
import { parseTronResult } from './parseTronResult'

export const getTronTokenAllowance = async (
  tokenAddress: string,
  contractAddress: string,
  nonEVMWalletAddress: string,
) => {
  const { tronWeb } = getTronProvider()
  if (!tronWeb || tokenAddress === '' || contractAddress === '' || nonEVMWalletAddress === '') {
    return false
  }
  const result = await executeViewTronCall(tokenAddress, 'allowance(address,address)', [
    { type: 'address', value: nonEVMWalletAddress },
    { type: 'address', value: contractAddress },
  ])
  return new BigNumber(parseTronResult(result))
}
