import type { StarknetWindowObject } from 'starknetkit'
import { RpcProvider } from 'starknet'
import { getChainRpcUrl } from '../../ethereum/chainProviders'
import { NETWORKS } from '../../../constants/types'

let starknetProvider: StarknetWindowObject | null = null
let publicStarknetProvider: RpcProvider | null = null

export const setStarknetProvider = (provider: StarknetWindowObject) => {
  starknetProvider = provider
  publicStarknetProvider = new RpcProvider({ nodeUrl: getChainRpcUrl(NETWORKS.STARKNET) })
}

export const getStarknetProvider = () => {
  if (!starknetProvider) {
    throw new Error('Please connect your Starknet wallet first.')
  }
  return starknetProvider
}

export const getPublicStarknetProvider = () => {
  if (!publicStarknetProvider) {
    throw new Error('Please connect your Starknet wallet first.')
  }
  return publicStarknetProvider
}
