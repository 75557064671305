import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { envConfig } from '../env/envConfig'
import { addAuthorizationHeader } from './auth/addAuthorizationHeader'
import { getDvf } from './dvfClient'

const endpointsWithAuth = ['getAirdrops', 'claimAirdrop']

type AirdropFilters = {
  tokens?: Array<string>
  origins?: Array<string>
  requested?: boolean
  locked?: boolean
}

export type Airdrop = {
  amount: string
  locked: boolean
  origin: string
  requested: boolean
  token: string
  user: string
}

type AirdropEligibility = {
  claims: Array<Airdrop>
  isRegistered: boolean
}

export const airdropsApi = createApi({
  reducerPath: 'airdropsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.tradingApi}/v1/trading`,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: ['Authenticated', 'Airdrops', 'AirdropEligibility'],
  endpoints: (builder) => ({
    getAirdrops: builder.query<Array<Airdrop>, AirdropFilters>({
      query: (queryParams) => ({
        url: `/r/airdrops?${qs.stringify(queryParams, { arrayFormat: 'repeat' })}`,
      }),
      providesTags: ['Authenticated', 'Airdrops'],
      transformResponse: async (response: Array<Airdrop>) => {
        const dvf = await getDvf()
        return response.map((airdrop) => ({
          ...airdrop,
          amount: dvf.token.fromQuantizedAmount(airdrop.token, airdrop.amount, false),
        }))
      },
    }),
    getAirdropEligibility: builder.query<AirdropEligibility, { user: string; token: string }>({
      query: ({ user, token }) => ({
        url: `/r/airdropEligibility?ethAddress=${user}&token=${token}`,
        providesTags: ['AirdropEligibility'],
      }),
      transformResponse: async (response: AirdropEligibility) => {
        const dvf = await getDvf()
        return {
          ...response,
          claims: response.claims.map((claim) => ({
            ...claim,
            amount: dvf.token.fromQuantizedAmount(claim.token, claim.amount, false),
          })),
        }
      },
    }),
    claimAirdrop: builder.mutation<void, { user: string; token: string; origin?: string }>({
      query: ({ user, token, origin }) => ({
        url: '/w/claimAirdrop',
        method: 'POST',
        body: {
          ethAddress: user,
          token,
          ...(origin ? { origin } : {}),
        },
      }),
      invalidatesTags: ['Airdrops', 'AirdropEligibility'],
    }),
  }),
})

export const { useGetAirdropsQuery, useGetAirdropEligibilityQuery, useClaimAirdropMutation } = airdropsApi
