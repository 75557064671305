import userflow from 'userflow.js'
import type { UserflowEvent } from '../../constants/userflowEvents'
import { shouldLogTracking } from '../apiService'

export const trackUserflowEvent = (event: UserflowEvent, data: Record<string, string | number | boolean> = {}) => {
  try {
    if (!userflow.isIdentified()) {
      return
    }
    if (shouldLogTracking) {
      // eslint-disable-next-line no-console -- logging
      console.log('Userflow Event:', event, data)
    }

    userflow.track(event, prepareUserflowData(data))
  } catch (error) {
    console.error("Couldn't send tracked event to Userflow.", error)
  }
}

const prepareUserflowData = (data: Record<string, string | number | boolean> = {}) => {
  // go through data values, and truncate any numbers to 4 decimal poitns
  const truncatedData: Record<string, string | number | boolean> = {}
  for (const key in data) {
    if (typeof data[key] === 'number') {
      truncatedData[key] = parseFloat(Number(data[key]).toFixed(4))
    } else {
      truncatedData[key] = data[key]
    }
  }

  return truncatedData
}
