import { translate } from '../../intl/i18n'
import { envConfig } from '../../env/envConfig'
import type { I18nData } from '../../intl/i18n.types'
import { parseWalletError } from '../helperService'
import { BridgeErrorType } from '../../pages/Bridge/types/BridgeWidget.types'

// Error handling
const errorMap: Record<string, keyof I18nData> = {
  // "There was an issue with signing the transacion",
  LEDGER_TOKENINFO_ERR: 'errors.ledger_issue_with_signing',
  CROSSCHAIN_SWAP_ALREADY_IN_PROGRESS: 'errors.xchain_wait_for_on_chain_confirmation',
  CROSSCHAIN_SWAP_REQUESTED_AMOUNT_OUT_GREATER_THAN_QUOTE: 'errors.xchain_requested_amount_out_greater_than_quote',
  [BridgeErrorType.wrongNetwork]: 'global.error',
  OPERATION_UNAVAILABLE_AT_THIS_TIME: 'errors.operation_unavailable_at_this_time',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we expect any here, but remember to use optional access
export const handleError = (error: any): string => {
  if (envConfig.environment === 'dev') {
    console.error('Raw error handled: ', JSON.stringify(error, null, 2))
  }

  if (errorMap[error?.error?.error]) {
    return translate(errorMap[error?.error?.error])
  }
  // Handle API Errors
  if (error?.error?.details?.error?.message) {
    return error.error.details.error.message
  }

  if (error?.body?.message) {
    return error.body.message.toString().replace(/_/g, ' ')
  }

  if (error?.error?.message) {
    return error.error.message.toString().replace(/_/g, ' ')
  }

  // Handle DVF Errors. First for mapped translated error, then check for error message from the server, then raw error
  if (error?.reason && errorMap[error.reason]) {
    return translate(errorMap[error.reason])
  }

  if (error?.message && errorMap[error.message]) {
    return translate(errorMap[error.message])
  }

  if (error?.data && error?.data?.error) {
    return error.data.error.toString()
  }

  if (error?.reason) {
    return error.reason
  }

  return parseWalletError(error)
}
