import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { AllTrackersResponse, TrackerResponse } from '@rhinofi/dvf-activity-trackers-api-spec'
import { heapEvents } from '../../constants/heapEvents'
import { userflowEvents } from '../../constants/userflowEvents'
import { envConfig } from '../../env/envConfig'
import { trackHeapEvent } from '../apiService'
import { reportMessageToSentry } from '../helperService/reportToSentry'
import { trackUserflowEvent } from '../apiService/trackUserflowEvent'
import type {
  ActivityTrackersBreakdownChain,
  ChainExplorerBaseTx,
  ChainExplorerTokenTransferTx,
  ChainExplorerTxs,
} from './activityTrackers.types'

const baseUrl = `${envConfig.tradingApi}/activity-trackers`

export const activityTrackersApi = createApi({
  reducerPath: 'activityTrackersApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: [
    'TrackerMetrics',
    'TrackerMetricsAllChains',
    'StarknetAirdrop',
    'Transactions',
    'BridgeVolumeUsd',
    'TrackerMetrics',
  ],
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    getTrackerMetrics: builder.query<TrackerResponse, { chain: string; address: string; forceRefresh?: boolean }>({
      query: ({ chain, address, forceRefresh = true }) => {
        if (!envConfig.activityTrackers.includes(chain)) {
          throw new Error('Chain not supported')
        }

        return {
          url: `/trackers/${chain}`,
          params: {
            address,
            forceRefresh,
          },
        }
      },
      providesTags: ['TrackerMetrics'],
      transformResponse: (response: TrackerResponse, meta, arg) => {
        trackHeapEvent(heapEvents.activityTracker, {
          address: arg.address,
          chain: arg.chain,
          ranking: response.ranking,
        })

        trackUserflowEvent(userflowEvents.activityTracker, {
          chain: arg.chain,
          score: response.transactionsCount,
        })

        return response
      },
      transformErrorResponse: (response, meta, arg) => {
        reportMessageToSentry(`Failed to fetch tracker metrics`, {
          chain: arg.chain,
          address: arg.address,
        })
        return response
      },
    }),
    getAllTrackerMetrics: builder.query<AllTrackersResponse, { address: string }>({
      queryFn: async ({ address }) => {
        const promises = envConfig.activityTrackers.map(async (chain): Promise<TrackerResponse> => {
          const response = await fetch(`${baseUrl}/trackers/${chain}?address=${address}&forceRefresh=true`)
          return response.json()
        })

        try {
          const res = await Promise.all(promises)

          const data = res.reduce<AllTrackersResponse>((acc, chainData, index) => {
            acc[envConfig.activityTrackers[index]] = chainData
            return acc
          }, {})

          trackHeapEvent(heapEvents.profileAllChains, {
            address,
          })

          return {
            data,
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch all chains data' } }
        }
      },
      providesTags: ['TrackerMetricsAllChains'],
    }),
    getBridgeVolumeUsd: builder.query<number, { address: string }>({
      // Mock until endpoint is added
      queryFn: async () => ({
        data: 0,
      }),
      providesTags: ['BridgeVolumeUsd'],
    }),
    getStarknetAirdrop: builder.query<number, { address: string }>({
      query: ({ address }) => ({
        url: '/starknetAirdrop',
        params: {
          address,
        },
      }),
      providesTags: ['StarknetAirdrop'],
    }),
    getTransactionsForAddress: builder.query<
      ChainExplorerTxs,
      { address: string; chain: ActivityTrackersBreakdownChain }
    >({
      queryFn: async ({ address, chain }) => {
        try {
          const explorerConfig = envConfig.ACTIVITY_TRACKER_CHAIN_EXPLORER_CONFIG_MAPPING[chain]

          const queryParts = [
            'module=account',
            'startblock=1',
            'endblock=99999999',
            'sort=desc',
            'page=1',
            'offset=1000',
            `address=${address}`,
          ]

          if (explorerConfig.apiKeys.length) {
            const randomIndex = Math.floor(Math.random() * explorerConfig.apiKeys.length)
            queryParts.push(`apiKey=${explorerConfig.apiKeys[randomIndex]}`)
          }

          const requests = [
            fetch(`${explorerConfig.url}?action=txlist&${queryParts.join('&')}`),
            fetch(`${explorerConfig.url}?action=tokentx&${queryParts.join('&')}`),
          ]

          const [txResponse, transfersResponse] = await Promise.all(requests)

          const { result: transactions } = await txResponse.json<{ result: ChainExplorerBaseTx[] }>()
          const { result: tokenTransfers } = await transfersResponse.json<{ result: ChainExplorerTokenTransferTx[] }>()

          const transactionsWithTransfers = transactions
            .filter(({ txreceipt_status }) => txreceipt_status === '1')
            .map((tx) => ({
              ...tx,
              tokenTransfers: tokenTransfers.filter((tokenTransfer) => tokenTransfer.hash === tx.hash),
            }))

          return {
            data: transactionsWithTransfers,
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch users transactions' } }
        }
      },
      providesTags: ['Transactions'],
    }),
  }),
})

export const {
  useGetTrackerMetricsQuery,
  useGetAllTrackerMetricsQuery,
  useGetStarknetAirdropQuery,
  useGetTransactionsForAddressQuery,
  useGetBridgeVolumeUsdQuery,
} = activityTrackersApi
