import { envConfig } from '../../env/envConfig'
const { starknetApi } = envConfig

/**
 * Starknet ID API doc
 * https://docs.starknet.id/devs/starknetid-api
 */

export type StarknetDomainLookup = (name: string) => Promise<string>
export type StarknetReverseLookup = (domain: string) => Promise<string>

interface StarknetAddrToIdResolve {
  domain: string
  domain_expiry: number
}

interface StarknetIdToAddrResolve {
  addr: string
  domain_expiry: number
}

export const starknetIdReverseLookup: StarknetReverseLookup = async (domain: string) => {
  try {
    const response = await fetch(`${starknetApi}/domain_to_addr?domain=${domain}`)

    if (response.status !== 200) {
      return ''
    }

    const data: StarknetIdToAddrResolve = await response.json()
    const address = data.addr

    return address
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const starknetLookup: StarknetDomainLookup = async (starknetAddress: string): Promise<string> => {
  try {
    const response = await fetch(`${starknetApi}/addr_to_domain?addr=${starknetAddress}`)

    const data: StarknetAddrToIdResolve = await response.json()

    const starknetId = data.domain

    return starknetId
  } catch (error) {
    return ''
  }
}

export const validateStarknetName = (name: string): boolean => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.stark/
  return regex.test(name)
}
