import { Effect } from 'effect'
import { Client } from 'effect-http'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  GetAllActiveMissionsResponse,
  GetAllActiveMissionsInputQuery,
  GetActiveMissionsUserDataResponse,
  GetAllGemsTransactionsInputQuery,
  GetAllGemsTransactionsResponse,
} from '@rhinofi/dvf-rhino-nation-api-spec'
import { apiSpec } from '@rhinofi/dvf-rhino-nation-api-spec'
import { getAuthorizationHeadersEffect } from '../../../services/auth/addAuthorizationHeader'
import type { PerksPaginatedMock, UserGemsMock, UserPerkDataMock } from '../types/nation.types'
import { envConfig } from '../../../env/envConfig'

const baseUrl = `${envConfig.tradingApi}/rhino-nation`
const client = Client.make(apiSpec, { baseUrl })

// TEMP FUNC FOR TESTING
const waitFor = (time = 500) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })

export const nationApi = createApi({
  reducerPath: 'nationApi',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['Missions', 'MissionsUserData', 'AllGemsTransactions', 'Perks', 'PerksUserData'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getUserGems: builder.query<UserGemsMock, undefined>({
      queryFn: async () => {
        try {
          await waitFor(300)

          return {
            data: {
              spent: 5,
              available: 10,
            },
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
    }),
    getMissions: builder.query<GetAllActiveMissionsResponse, GetAllActiveMissionsInputQuery>({
      queryFn: async ({ missionFrequency }) => {
        try {
          const res = await Effect.runPromise(
            client.getAllActiveMissions({
              headers: await getAuthorizationHeadersEffect(),
              query: {
                missionFrequency,
              },
            }),
          )

          return {
            data: res,
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: error } }
        }
      },
      providesTags: ['Missions'],
    }),
    getUserMissionsData: builder.query<GetActiveMissionsUserDataResponse, undefined>({
      queryFn: async () => {
        try {
          const res = await Effect.runPromise(
            client.getActiveMissionsUserData({
              headers: await getAuthorizationHeadersEffect(),
            }),
          )

          return {
            data: res,
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: error } }
        }
      },
      providesTags: ['MissionsUserData'],
    }),
    getAllGemsTransactions: builder.query<GetAllGemsTransactionsResponse, GetAllGemsTransactionsInputQuery>({
      queryFn: async ({ type, latest }) => {
        try {
          const res = await Effect.runPromise(
            client.getAllGemsTransactions({
              query: {
                type,
                latest,
              },
              headers: await getAuthorizationHeadersEffect(),
            }),
          )

          return {
            data: res,
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: error } }
        }
      },
      providesTags: ['AllGemsTransactions'],
    }),
    getPerks: builder.query<PerksPaginatedMock, { limit: number; skip: number }>({
      queryFn: async () => {
        try {
          await waitFor(200)

          return {
            data: {
              items: [
                {
                  id: '1',
                  title: 'Bitrefill - 10% cashback',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 100,
                  maxClaimableTimes: 100,
                  highlighted: true,
                  startDate: null,
                  endDate: null,
                  maxStock: 50000,
                  currentStock: 4999,
                },
                {
                  id: '2',
                  title: 'Bankless - 15% off subscription',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 100,
                  maxClaimableTimes: 1,
                  highlighted: false,
                  startDate: new Date('10/26/2024'),
                  endDate: null,
                  maxStock: 100,
                  currentStock: 95,
                },
                {
                  id: '3',
                  title: 'ZNS',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 44,
                  maxClaimableTimes: 50,
                  highlighted: false,
                  startDate: new Date('5/26/2024'),
                  endDate: new Date('7/25/2024 14:08:00Z'),
                  maxStock: 100,
                  currentStock: 95,
                },
                {
                  id: '4',
                  title: 'Alphamind',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 500,
                  maxClaimableTimes: 1,
                  highlighted: false,
                  startDate: null,
                  endDate: null,
                  maxStock: 100,
                  currentStock: 95,
                },
                {
                  id: '5',
                  title: 'Enjinstarter',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 420,
                  maxClaimableTimes: 1,
                  highlighted: false,
                  startDate: null,
                  endDate: null,
                  maxStock: 100,
                  currentStock: 95,
                },
              ],
              pagination: {
                totalItems: 250,
                skip: 5,
                limit: 10,
              },
            },
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
      providesTags: ['Perks'],
    }),
    getUserPerksData: builder.query<UserPerkDataMock[], { ids: string[] }>({
      queryFn: async () => {
        try {
          await waitFor(300)

          return {
            data: [
              {
                perkId: '1',
                timesClaimed: 10,
              },
              {
                perkId: '3',
                timesClaimed: 5,
              },
            ],
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
      providesTags: ['PerksUserData'],
    }),
    getUserPerksClaimed: builder.query<PerksPaginatedMock, { limit: number; skip: number }>({
      queryFn: async () => {
        try {
          await waitFor(200)

          return {
            data: {
              items: [
                {
                  id: '1',
                  title: 'Bitrefill - 10% cashback',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 100,
                  maxClaimableTimes: 100,
                  highlighted: true,
                  startDate: null,
                  endDate: null,
                  maxStock: 50000,
                  currentStock: 4999,
                },
                {
                  id: '2',
                  title: 'Bankless - 15% off subscription',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 100,
                  maxClaimableTimes: 1,
                  highlighted: false,
                  startDate: new Date('10/26/2024'),
                  endDate: null,
                  maxStock: 100,
                  currentStock: 95,
                },
                {
                  id: '3',
                  title: 'ZNS',
                  imageUrl: 'https://placehold.co/356x216',
                  gemsPrice: 44,
                  maxClaimableTimes: 50,
                  highlighted: false,
                  startDate: new Date('5/26/2024'),
                  endDate: new Date('7/25/2024 14:08:00Z'),
                  maxStock: 100,
                  currentStock: 95,
                },
              ],
              pagination: {
                totalItems: 250,
                skip: 5,
                limit: 10,
              },
            },
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
    }),
  }),
})

export const {
  useGetUserGemsQuery,
  useGetUserMissionsDataQuery,
  useGetAllGemsTransactionsQuery,
  useGetMissionsQuery,
  useGetPerksQuery,
  useGetUserPerksDataQuery,
  useGetUserPerksClaimedQuery,
} = nationApi
