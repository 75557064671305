import type { ReactNode } from 'react'

export enum DescriptionNodeType {
  'TITLE' = 'TITLE',
  'TEXT_WITH_LINK' = 'TEXT_WITH_LINK',
  'TEXT' = 'TEXT',
  'SMALL_LINK' = 'SMALL_LINK',
  'NORMAL_LINK' = 'NORMAL_LINK',
}

export type YieldBoostDescription = Array<
  | {
      type: DescriptionNodeType.TEXT | DescriptionNodeType.TITLE
      text: (props: {
        boostedApy: string
        baseToken: string
        baseApy: string
        boost: string
        maxSupplyAmount: string
        boostPercentage: number
      }) => string
    }
  | {
      type: DescriptionNodeType.NORMAL_LINK
      text: () => string
      linkIcon?: string
      href: string
    }
>

export type YieldTokenDescription = Array<
  | ({
      type: DescriptionNodeType.TEXT
    } & {
      text: (props: { apy: string }) => string
    })
  | ({
      type: DescriptionNodeType.TEXT_WITH_LINK | DescriptionNodeType.SMALL_LINK | DescriptionNodeType.NORMAL_LINK
      text: () => string
      linkIcon?: string
    } & ({ href: string; openNewTab?: boolean } | { to: string }))
>

export enum YieldOpportunityType {
  liquidityPool = 'liquidityPool',
  leveragedStaking = 'leveragedStaking',
  staking = 'staking',
  strategy = 'strategy',
  tokenisedRWA = 'tokenisedRWA',
}

export type BaseYieldTokenConfig = {
  isDisabled?: boolean
  isSunset?: boolean
  sunsetDate?: Date
  id: string
  baseToken: string
  opportunityType: YieldOpportunityType
  type: 'lido' | 'compound' | 'sommelier' | 'indexcoop' | 'yearn' | 'stargate' | 'beefy'
  // when adding a originator also add icon for the provider to dvf-shared-ui: src/icons/providers/index.tsx
  originator: 'lido' | 'compound' | 'sommelier' | 'indexcoop' | 'yearn' | 'stargate' | 'beefy' | 'backed'
  originatorMarketHref: string
  cToken: string
  dynamicBoostApy?: boolean
  xchain?: boolean
  chain?: string
  customTitle?: string
  rewardToken?: string
  description?: YieldTokenDescription
  descriptionV2: YieldTokenDescription
  auditHref?: string
  contractHref?: string
  vaultHref?: string
  icons?: string[]
  isOutsideControlled?: boolean
  shouldOverrideApy?: () => boolean
  apyOverride?: () => ReactNode
  apySmallOverride?: () => ReactNode
} & (
  | {
      isBoosted?: false
    }
  | {
      isBoosted: true
      isBoostAnimated: boolean
      boostCopy: YieldBoostDescription
      boostPercentage: number
      boostValueLimit: number
      maxSupplyAmount: string
      boostEnds?: number
    }
)

export type YieldBeefyTokenConfig = BaseYieldTokenConfig & {
  type: 'beefy'
  cTokenContractAddress: string
  beefyVaultId: string
  chain: string
  protocol: string
}

export type YieldTokenConfig =
  | (BaseYieldTokenConfig & {
      type: 'compound'
      cTokenContractAddress: string
    })
  | (BaseYieldTokenConfig & {
      type: 'lido'
    })
  | (BaseYieldTokenConfig & {
      type: 'yearn'
      cTokenContractAddress: string
    })
  | YieldBeefyTokenConfig
  | (BaseYieldTokenConfig & {
      type: 'sommelier'
      cTokenContractAddress?: string
    })
  | (BaseYieldTokenConfig & {
      type: 'indexcoop'
    })

export const isBeefyToken = (config: YieldTokenConfig): config is YieldBeefyTokenConfig => {
  return config.type === 'beefy'
}

export const isNormalBoostLink = (
  node: YieldBoostDescription[number],
): node is {
  type: DescriptionNodeType.NORMAL_LINK
  text: () => string
  linkIcon?: string
  href: string
} => {
  return node.type === DescriptionNodeType.NORMAL_LINK
}
