import { checkIsLegacyStarkKey, getStarkKeys } from '../starkService'
import { WALLETS } from '../../constants/types'
import { reportToSentry } from '../helperService/reportToSentry'
import { updateTradingKey } from '../tradingKeyService/webWalletTradingKey'
import { updateKeystore } from './keystoreService'

export const isLegacyKeystore = async (
  address: string,
  keystore?: { privateKey?: string; isLegacy?: boolean },
  isRegistered?: boolean,
  walletType?: string,
) => {
  try {
    if (!keystore || !keystore.privateKey) {
      return false
    }
    if (!isRegistered) {
      updateLocalStorage(address, { isLegacy: false }, walletType)
      return false
    }
    if (keystore.hasOwnProperty('isLegacy')) {
      return keystore.isLegacy
    }
    const { starkPublicKey } = await getStarkKeys(keystore.privateKey.substr(2))
    const isLegacy = await checkIsLegacyStarkKey(address, starkPublicKey.x)
    updateLocalStorage(address, { isLegacy }, walletType)
    return isLegacy
  } catch (error) {
    console.error(error)
    reportToSentry(error)
    throw new Error("Couldn't determine legacy status.")
  }
}

export const isLegacyMM = async (
  tradingKey: { privateKey: string },
  isRegistered: boolean,
  mmStarkPublicKey: string,
) => {
  try {
    if (!isRegistered) {
      return false
    }
    const { privateKey } = tradingKey
    const { starkPublicKey } = await getStarkKeys(privateKey.substr(2))
    return `0x${starkPublicKey.x}` !== mmStarkPublicKey
  } catch (error) {
    console.error(error)
    reportToSentry(error)
    throw new Error("Couldn't determine legacy status.")
  }
}

export const parsePrivateKey = (privateKey: string, isLegacy?: boolean) => {
  return isLegacy ? privateKey : privateKey.substr(2)
}

const updateLocalStorage = (address: string, data?: { isLegacy: boolean }, walletType?: string) => {
  if (walletType === WALLETS.KEYSTORE) {
    updateKeystore(address, data)
  } else if (walletType === WALLETS.METAMASK) {
    updateTradingKey(address, data)
  }
}
