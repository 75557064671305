import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type ApplicationState = {
  loading: boolean
}

const getInitialState = (): ApplicationState => {
  return {
    loading: true,
  }
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState: getInitialState(),
  reducers: {
    setApplicationLoadingState: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})
