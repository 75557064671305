import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { GeolocationCodecType } from '@rhinofi/dvf-aml-api-spec'
import { envConfig } from '../env/envConfig'
import { addAuthorizationHeader } from './auth/addAuthorizationHeader'
const endpointsWithAuth = ['checkGeoAddress']

export const geoblockingApiBaseUrl = envConfig.geoblockingApiUrl
export const makeCheckGeoAddressUrl = (address: string) => `/gl/${address}`

export const geoblockingApi = createApi({
  reducerPath: 'geoblockingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: geoblockingApiBaseUrl,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      headers.set('accept', 'application/json')
      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: ['checkGeoAddress'],
  endpoints: (build) => ({
    checkGeoAddress: build.query<GeolocationCodecType, { address: string }>({
      query: (params) => ({
        url: makeCheckGeoAddressUrl(params.address),
        method: 'GET',
      }),
    }),
  }),
})

export const { useCheckGeoAddressQuery } = geoblockingApi
