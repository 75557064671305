import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import type { RootState } from '../store/configureStore'
import type { AppDispatch } from '../store/store.types'
import {
  connectSecondaryWallet,
  updateSecondaryAddress,
  updateSecondaryNetworkId,
} from '../services/secondaryWallet/secondaryWalletSlice'
import { fetchBlockChainBalanceForChain } from '../actions/balanceActions'
import { selectUnifiedTokenRegistry } from '../actions/selectors/dataSelectors'
import { selectAddress } from '../actions/selectors/userSelectors'
import { selectSecondaryWalletAddressAndChain } from '../actions/selectors/secondaryWalletSelectors'

export const secondaryWalletBalanceMiddleware = createListenerMiddleware<RootState, AppDispatch>()

secondaryWalletBalanceMiddleware.startListening({
  matcher: isAnyOf(
    updateSecondaryAddress.match,
    updateSecondaryNetworkId.match,
    connectSecondaryWallet.fulfilled.match,
  ),
  effect: (action, { dispatch, getState }) => {
    const secondaryWallet = selectSecondaryWalletAddressAndChain(getState())
    const address = selectAddress(getState())
    const tokenRegistry = selectUnifiedTokenRegistry(getState())
    if (!address) {
      return
    }
    fetchBlockChainBalanceForChain(dispatch)({
      chain: secondaryWallet.connectedChain,
      address,
      secondaryWallet,
      tokenRegistry,
    })
  },
})
