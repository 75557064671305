import { toBN } from '@rhino.fi/dvf-utils'

import type Decimal from 'decimal.js'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Pagination } from '../reducers/types/PaginationState'
import type {
  PoolName,
  PoolTvlHistoryDuration,
  PoolTvlHistoryRecord,
  PoolData,
  PoolUserData,
} from '../reducers/types/AmmPoolsState'
import type { AmmFundingOrder } from './dvfClient/DvfClientInstance'
import { getAuthenticationData } from './apiService'
import { getDvf } from './dvfClient'

export const getPoolsData = async (pools: PoolName[]) => {
  const dvf = await getDvf()
  return dvf.poolsData({ pools })
}

export const getPoolsUserData = async (pools: PoolName[]) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.poolsUserData({ pools }, nonce, signature)
}

export const getPoolTVL = async (poolName: string) => {
  const dvf = await getDvf()
  return dvf.poolTVL({ poolName })
}

export const getPoolVolume24Hours = async (poolName: string) => {
  const dvf = await getDvf()
  return dvf.poolVolume24Hours({ poolName })
}

export const getPoolSwapFees = async (poolName: string) => {
  const dvf = await getDvf()
  return dvf.poolSwapFees<Promise<{ swapFees?: number }>>({ poolName })
}

export const getPoolAPY = async (poolName: string) => {
  const dvf = await getDvf()
  return dvf.poolAPY({ poolName })
}

export const getPoolUserAccruedFees = async (poolName: string, isAuthenticated: boolean) => {
  const dvf = await getDvf()
  if (isAuthenticated) {
    const { nonce, signature } = await getAuthenticationData(dvf)
    return dvf.poolUserAccruedFees({ poolName }, nonce, signature)
  }
}

export const getPoolStoredTokens = async (poolName: string) => {
  const dvf = await getDvf()
  return dvf.poolStoredTokens({ poolName })
}

export const getPoolUserRewards = async (poolName: string, isAuthenticated: boolean) => {
  const dvf = await getDvf()
  if (isAuthenticated) {
    const { nonce, signature } = await getAuthenticationData(dvf)
    const { totalLockedRewards, totalUnlockedRewards } = await dvf.poolUserRewards<
      Promise<{ totalLockedRewards: string; totalUnlockedRewards: string }>
    >({ poolName }, nonce, signature)

    return {
      totalLockedRewards: dvf.token.fromQuantizedAmount('DVF', totalLockedRewards, false),
      totalUnlockedRewards: dvf.token.fromQuantizedAmount('DVF', totalUnlockedRewards, false),
    }
  }
}

export const getRewardsLockedState = async (poolName: string) => {
  const dvf = await getDvf()
  const address = dvf.get('account')
  if (address) {
    const { nonce, signature } = await getAuthenticationData(dvf)
    return dvf.getRewardsLockedState<Promise<{ isLocked: boolean }>>({ poolName }, nonce, signature)
  }
}

export const postRewardsLockedState = async (poolName: string, wrapped: boolean) => {
  const dvf = await getDvf()
  const address = dvf.get('account')
  if (address) {
    const { nonce, signature } = await getAuthenticationData(dvf)
    return dvf.postRewardsLockedState<Promise<{ isLocked?: boolean }>>(
      {
        poolName,
        wrapped,
      },
      nonce,
      signature,
    )
  }
}

export const getAmmFundingOrderData = async (pool: string, token: string, amount: number | string | Decimal) => {
  const dvf = await getDvf()
  const address = dvf.get('account')
  if (address && pool && token && amount) {
    const { nonce, signature } = await getAuthenticationData(dvf)
    return dvf.getAmmFundingOrderData(
      {
        pool,
        token,
        amount: toBN(amount.toString()),
      },
      nonce,
      signature,
    )
  }
}

export const getAmmFundingOrder = async (ammFundingOrderId: string) => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.getAmmFundingOrders(nonce, signature, {
    ammFundingOrderId,
  })
}

type TokenDelta = {
  token: string
  amount: string
  amountFilled: string
}

export type AmmFundingOrderHistoryItem = {
  _id: string
  pending: boolean
  failed: boolean
  type: 'Deposit' | 'Withdrawal'
  pool: string
  user: string
  createdAt: Date
  updatedAt: Date
  tokenDeltas: TokenDelta[]
}
export type GetAmmOrdersHistoryFilters = {
  startDate?: string
  endDate?: string
  pool?: string
  sortDirection?: string
  skip?: number
  limit?: number
}

export async function getAmmFundingOrders(
  filters: GetAmmOrdersHistoryFilters,
  headers?: { accept: 'text/csv' },
): Promise<string>
export async function getAmmFundingOrders(filters = {}, headers = { accept: 'text/csv' } as const) {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.getAmmFundingOrders(nonce, signature, filters, headers)
}

export const supplyLiquidity = async (
  pool: string,
  ammFundingOrderData: {
    orders: AmmFundingOrder[]
  } | null,
  slippage = 0.05,
) => {
  if (!ammFundingOrderData || !ammFundingOrderData.orders) {
    throw new Error('No ammFundingOrderData')
  }
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const data = await dvf.applyFundingOrderDataSlippage(
    {
      pool,
      orders: ammFundingOrderData.orders,
    },
    slippage,
  )

  return dvf.postAmmFundingOrders<{ _id: string }>(data, nonce, signature)
}

export const removeLiquidity = async (
  pool: string,
  ammFundingOrderData: {
    orders: AmmFundingOrder[]
  } | null,
  slippage = 0.05,
) => {
  if (!ammFundingOrderData || !ammFundingOrderData.orders) {
    throw new Error('No ammFundingOrderData')
  }
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const data = await dvf.applyFundingOrderDataSlippage(
    {
      pool,
      orders: ammFundingOrderData.orders,
    },
    slippage,
  )

  return dvf.postAmmFundingOrders<{ _id: string }>(data, nonce, signature)
}

export type AmmFundingOrdersHistoryResponse = {
  items: AmmFundingOrderHistoryItem[]
  pagination: Pagination
}

export const poolsApi = createApi({
  reducerPath: 'poolsApi',
  tagTypes: ['PoolsHistory', 'PoolsTokensRate', 'PoolsTvlHistory', 'PoolsData', 'PoolsUserData'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getAmmFundingOrders: builder.query<AmmFundingOrdersHistoryResponse, GetAmmOrdersHistoryFilters>({
      providesTags: ['PoolsHistory'],
      queryFn: async (filters) => {
        try {
          const dvf = await getDvf()
          const { nonce, signature } = await getAuthenticationData(dvf)
          return {
            data: await dvf.getAmmFundingOrders(nonce, signature, filters),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch AMM funding orders' } }
        }
      },
    }),
    getPoolTokensRate: builder.query<{ tokensRate?: number }, string>({
      providesTags: ['PoolsTokensRate'],
      queryFn: async (poolName) => {
        try {
          const dvf = await getDvf()
          return {
            data: await dvf.poolTokensRate({ poolName }),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch pool tokens rate' } }
        }
      },
    }),
    getPoolTvlHistory: builder.query<
      { poolName: string; duration: PoolTvlHistoryDuration; tvlRecords: PoolTvlHistoryRecord[] },
      { poolName: string; duration: PoolTvlHistoryDuration }
    >({
      providesTags: ['PoolsTvlHistory'],
      queryFn: async ({ poolName, duration }) => {
        try {
          const dvf = await getDvf()
          return {
            data: await dvf.poolTvlHistory({ poolName, duration }),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch pool TVL history' } }
        }
      },
    }),
    getPoolsData: builder.query<PoolData[], { pools: PoolName[] }>({
      providesTags: ['PoolsData'],
      queryFn: async ({ pools }) => {
        try {
          const dvf = await getDvf()
          const poolsData = await dvf.poolsData({ pools })
          if (Array.isArray(poolsData)) {
            return {
              data: poolsData,
            }
          }

          return {
            data: [poolsData],
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: `Failed to fetch pools data for ${pools.join(', ')}` } }
        }
      },
    }),
    getPoolsUserData: builder.query<PoolUserData[], { pools: PoolName[] }>({
      providesTags: ['PoolsUserData'],
      queryFn: async ({ pools }) => {
        try {
          const dvf = await getDvf()
          const { nonce, signature } = await getAuthenticationData(dvf)
          const poolsData = await dvf.poolsUserData({ pools }, nonce, signature)
          if (Array.isArray(poolsData)) {
            return {
              data: poolsData,
            }
          }

          return {
            data: [poolsData],
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: `Failed to fetch user pools data for ${pools.join(', ')}` } }
        }
      },
    }),
  }),
})

export const {
  useGetAmmFundingOrdersQuery,
  useGetPoolTokensRateQuery,
  useGetPoolTvlHistoryQuery,
  useGetPoolsDataQuery,
  useGetPoolsUserDataQuery,
} = poolsApi
