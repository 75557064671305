import { heapEvents } from '../../constants/heapEvents'
import { trackHeapEvent } from '../../services/apiService'
import { randomId } from '../../services/helperService/randomId'
import type { AppDispatch } from '../../store/store.types'
import type { StatusNotificationUpdatedFields } from '../types/statusNotifications'
import {
  ADD_STATUS_NOTIFICATION,
  CLEAR_STATUS_NOTIFICATION,
  StatusNotificationStatus,
  UPDATE_STATUS_NOTIFICATION,
} from '../types/statusNotifications'

export const STATUS_NOTIFICATION_CLEAR_TIMEOUT = 10 * 1000

export type StatusNotificationData = {
  id?: string | null
  status: StatusNotificationStatus
  title?: string
  meta?: {
    description?: string
    chain?: string
    token?: string | null
    pair?: string | null
    symbol?: string | null
    txHash?: string | null
    icons?: string[]
  }
}

export const addStatusNotification =
  (dispatch: AppDispatch) =>
  ({
    id = null,
    status = StatusNotificationStatus.success,
    title = '',
    meta = {
      description: '',
      chain: 'ETHEREUM',
      token: null,
      pair: null,
      txHash: null,
    },
  }: StatusNotificationData) => {
    let notificationId = id || randomId()
    dispatch({
      type: ADD_STATUS_NOTIFICATION,
      payload: {
        id: notificationId,
        status,
        title,
        meta,
      },
    })
    trackHeapEvent(heapEvents.notification, {
      id: notificationId,
      status,
      title,
      description: meta?.description,
    })
    if (status === StatusNotificationStatus.success) {
      setTimeout(() => clearStatusNotification(dispatch)(notificationId), STATUS_NOTIFICATION_CLEAR_TIMEOUT)
    }

    return { id: notificationId }
  }

export const updateStatusNotification =
  (dispatch: AppDispatch) => (id: string, updatedFields: StatusNotificationUpdatedFields) => {
    if (!id) {
      throw new Error('ID is required')
    }

    dispatch({
      type: UPDATE_STATUS_NOTIFICATION,
      payload: {
        id,
        updatedFields,
      },
    })

    if (updatedFields?.status === StatusNotificationStatus.success) {
      setTimeout(() => clearStatusNotification(dispatch)(id), STATUS_NOTIFICATION_CLEAR_TIMEOUT)
    }
  }

export const clearStatusNotification = (dispatch: AppDispatch) => (id: string) =>
  dispatch({
    type: CLEAR_STATUS_NOTIFICATION,
    payload: { id },
  })
