import { getDvf } from '../dvfClient'
import { getAuthenticationData } from '../apiService'

export type CrossChainBalance = {
  chain: string
  token: string
  balance: string
  price: number
  usdBalance: number
}
export type CrossChainBalances = Array<CrossChainBalance>

export const getCrossChainBalances = async () => {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  const result = await dvf.getAuthenticated<CrossChainBalances>(
    `/v1/trading/chainswap/balances/${dvf.get('signatureAddress')}?withPriceInfo=true`,
    nonce,
    signature,
  )
  return result
}
