import { keyBy } from 'lodash'
import pick from 'lodash/pick'
import intersection from 'lodash/intersection'
import { createSelector, lruMemoize } from 'reselect'
import { shallowEqual } from 'react-redux'
import type { TokenData } from '../../components/Explore/ExploreTokens.columns'
import { isLpToken } from '../../services/helperService/isLpToken'
import { getTokenPrice } from '../../services/helperService/getTokenPrice'
import { getTickerForToken } from '../../services/helperService'
import type { RootState } from '../../store/configureStore'
import type {
  UnifiedTokenRegistryItem,
  UnifiedCrossChainTokenItem,
  UnifiedTokenRegistryState,
} from '../../reducers/types/UnifiedTokenRegistryState'
import type { NonL2TokenRegistryItem } from '../../services/apiService/getConfig.types'
import { isYieldToken } from '../../services/helperService/isYieldToken'
import type { ExploreCategories } from '../../components/Explore/categories'
import { isTokenFromCategory } from '../../components/Explore/categories'
import {
  selectExchangeSymbols,
  selectTokenRegistry,
  selectNonL2TokenRegistry,
  selectDisplayInfoForToken,
  selectBridgeConfigPerChain,
} from './portalSelectors'
import { selectCrossChainTokenRegistry, selectCrossChainTokensMeta } from './xchainSelectors'

export const selectTokenPrices = (state: RootState) => state.data.tokenPrices

export const selectTokenUsdPrice = (token: string) => (state: RootState) => getTokenPrice(state.data.tokenPrices, token)

export const selectTicker = (state: RootState) => state.data.ticker

export const selectPriceAndChangeForToken = createSelector(
  selectTokenPrices,
  selectTicker,
  (state: RootState, token: string) => token,
  (tokenPrices, ticker, token) => {
    const { dailyChangePerc = 0 } = getTickerForToken(ticker, token) || {}
    const price = getTokenPrice(tokenPrices, token)

    return {
      priceChange: dailyChangePerc,
      price,
    }
  },
)

export const selectTickerForToken = createSelector(
  selectTicker,
  (state: RootState, token: string) => token,
  (ticker, token) => getTickerForToken(ticker, token) || { lastPrice: 0, dailyChangePerc: 0 },
)

export const selectExchangeSymbolForToken = createSelector(
  selectExchangeSymbols,
  (state: RootState, token: string) => token,
  (exchangeSymbols, token) => {
    let usdtPair, usdcPair, ethPair
    exchangeSymbols.map((symbol) => {
      if (`${token}:USDT` === symbol) {
        usdtPair = symbol
      } else if (`${token}:USDC` === symbol) {
        usdcPair = symbol
      } else if (`${token}:ETH` === symbol) {
        ethPair = symbol
      }
    })
    return usdtPair || usdcPair || ethPair
  },
)
export const selectExchangeTokensMarketCap = (state: RootState) => state.portal.exchangeTokensMarketCap

export enum ExploreTokensSort {
  token = 'token',
  price = 'price',
  priceChange = 'priceChange',
}

export const selectExploreTokens = createSelector(
  selectTokenRegistry,
  selectCrossChainTokenRegistry,
  selectTokenPrices,
  selectTicker,
  selectExchangeTokensMarketCap,
  selectCrossChainTokensMeta,
  selectDisplayInfoForToken,
  (
    state: RootState,
    params: {
      page: number
      itemsPerPage: number
      sort: {
        field: ExploreTokensSort
        asc: boolean
      }
      chainFilters: string[]
      tokenFilter?: string
      categoryFilter?: ExploreCategories
    },
  ) => params,
  (
    tokenRegistry,
    crossChainTokenRegistry,
    tokenPrices,
    tickers,
    exchangeTokensMarketCap,
    crossChainTokensMeta,
    getTokenInfo,
    { page, itemsPerPage, sort, chainFilters, tokenFilter, categoryFilter },
  ) => {
    const exchangeTokens = Object.keys(tokenRegistry)
      .filter((token) => !isLpToken(token) && !isYieldToken(token) && isTokenFromCategory(token, categoryFilter))
      .map((token) => {
        const { dailyChangePerc = 0 } = getTickerForToken(tickers, token) || { dailyChangePerc: 0 }
        const { name: tokenName } = getTokenInfo(token)

        return {
          token,
          tokenName,
          price: getTokenPrice(tokenPrices, token),
          priceChange: dailyChangePerc,
          marketCap: exchangeTokensMarketCap[token],
        }
      })

    const crossChainTokens = crossChainTokenRegistry
      .filter(({ status, token }) => status === 'LISTED' && isTokenFromCategory(token, categoryFilter))
      .map(({ token, chain }) => {
        const { name: tokenName } = getTokenInfo(token)

        return {
          token,
          tokenName,
          price: getTokenPrice(tokenPrices, token),
          priceChange: crossChainTokensMeta?.data?.[token]?.priceChange24 || 0,
          marketCap: crossChainTokensMeta?.data?.[token]?.marketCap,
          chain,
        }
      })

    const sortCallback = (tokenA: TokenData, tokenB: TokenData) => {
      const aField = sort.field === ExploreTokensSort.token ? tokenA[sort.field].toLowerCase() : tokenA[sort.field]
      const bField = sort.field === ExploreTokensSort.token ? tokenB[sort.field].toLowerCase() : tokenB[sort.field]
      return (sort.asc ? 1 : -1) * (aField < bField ? -1 : 1)
    }

    const filterCallback = (token: TokenData) => {
      if (tokenFilter) {
        if (token.token.toLowerCase().includes(tokenFilter) || token.tokenName.toLowerCase().includes(tokenFilter)) {
          return true
        }
        return false
      }
      if (chainFilters.length === 0 || chainFilters.includes(token.chain || 'ethereum_mainnet')) {
        return true
      }
      return false
    }

    const offset = (page - 1) * itemsPerPage

    const filteredAndSortedTokens = [...exchangeTokens, ...crossChainTokens].filter(filterCallback).sort(sortCallback)
    return {
      tokens: filteredAndSortedTokens.slice(offset, offset + itemsPerPage),
      totalItems: filteredAndSortedTokens.length,
    }
  },
  {
    memoize: lruMemoize,
    memoizeOptions: {
      equalityCheck: shallowEqual,
    },
  },
)

type BridgeTokenRegistryItem = Omit<NonL2TokenRegistryItem, 'coingeckoId'>
type BridgeTokenRegistry = Record<string, BridgeTokenRegistryItem>

export const selectBridgeTokenRegistry = createSelector(
  selectTokenRegistry,
  selectNonL2TokenRegistry,
  selectBridgeConfigPerChain,
  (tokenRegistry, nonL2TokenRegistry, bridgeConfigPerChain): BridgeTokenRegistry => {
    const bridgeChains = Object.keys(bridgeConfigPerChain)
    const registryKeys = ['decimals', 'quantization', 'tokenAddressPerChain', 'chainOverride']

    return Object.entries({ ...nonL2TokenRegistry, ...tokenRegistry }).reduce((acc, [token, config]) => {
      const tokenChains = Object.keys(config.tokenAddressPerChain)
      if (intersection(bridgeChains, tokenChains).length) {
        const tokenConfig = pick(config, registryKeys)
        acc[token] = tokenConfig as BridgeTokenRegistryItem
      }
      return acc
    }, {} as BridgeTokenRegistry)
  },
)

// Produces a extended token registry with extra "xchain" and "chain" fields for x-chain tokens
export const selectUnifiedTokenRegistry = createSelector(
  selectTokenRegistry,
  selectCrossChainTokenRegistry,
  (tokenRegistry, crossChainTokenRegistry): UnifiedTokenRegistryState => {
    const unifiedTokenRegistry = Object.entries(tokenRegistry).reduce(
      (acc, [token, config]) => {
        acc[token] = {
          ...config,
          xchain: false,
          status: 'LISTED',
        }
        return acc
      },
      {} as Record<string, UnifiedTokenRegistryItem>,
    )

    const unifiedCrossChainTokenRegistry: Record<string, UnifiedCrossChainTokenItem> = keyBy(
      crossChainTokenRegistry.map((tokenConfig) => ({
        ...tokenConfig,
        xchain: true,
      })),
      'token',
    )

    return {
      ...unifiedTokenRegistry,
      ...unifiedCrossChainTokenRegistry,
    }
  },
)

export const selectListedUnifiedTokenRegistry = createSelector(
  selectUnifiedTokenRegistry,
  (tokenRegistry): UnifiedTokenRegistryState =>
    Object.entries(tokenRegistry).reduce((acc, [token, config]) => {
      if (config.status === 'LISTED') {
        return {
          ...acc,
          [token]: config,
        }
      }
      return acc
    }, {}),
)

export const selectFastWithdrawalTokens = createSelector(selectUnifiedTokenRegistry, (tokenRegistry): string[] =>
  Object.keys(tokenRegistry).filter((key) => {
    const tokenConfig = tokenRegistry[key]
    return 'fastWithdrawalRequiredGas' in tokenConfig && tokenConfig.fastWithdrawalRequiredGas
  }),
)
