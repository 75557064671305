import P from '@rhino.fi/aigle'
import { flatMap } from 'lodash'
import type { EVMNFTType } from '../../../../../services/nfts/mintNFT'
import {
  getNFTContract,
  getNFTContractConfig,
} from '../../../../ActivityTracker/ActivityTrackerNFT/helpers/nftContractHelpers'
import { isOldZkSyncNFT } from '../../../../ActivityTracker/ActivityTrackerNFT/helpers/isOldZkSyncNFT'
import { NETWORKS } from '../../../../../constants/types'
import { getNFTMetaData } from './getNFTMetaData'
import { getZkSyncNFTs } from './getZkSyncNFTs'
import type { NFTMinter } from './NFTMinterAssets'
import { getStarknetNFTs } from './getStarknetNFTs'

const getUserTokenIds = async (type: EVMNFTType, chain: string, user: string): Promise<number[] | null> => {
  // zkSync contract does not have a getUserTokenIds method
  if (isOldZkSyncNFT(type, chain)) {
    return null
  }

  const contract = getNFTContract(type, chain, false)

  if (!contract) {
    return null
  }

  if (type === 'BETAKEY') {
    const contractConfig = getNFTContractConfig(type, chain)
    if (!contractConfig) {
      return null
    }
    const hasMinted = await contract.methods.balanceOf(user, contractConfig.tokenId).call()
    return hasMinted > 0 ? [contractConfig.tokenId] : []
  }

  const userTokenIds = await contract.methods.getUserTokenIds(user).call()
  return userTokenIds.map((id: string) => Number(id))
}

export const getNFTsForChain = async (chain: string, user: string): Promise<NFTMinter[]> => {
  try {
    if (chain === NETWORKS.ZKSYNC) {
      const hunterAndProHunter = await getZkSyncNFTs(user)

      const otherNFTs: Array<EVMNFTType> = ['wrapped2023', 'VOLUME', 'TXCOUNT']
      const otherIds = await P.map(otherNFTs, (type) => getUserTokenIds(type, chain, user))
      const otherNFTsMetaData = otherIds.flatMap((ids, index) =>
        (ids || []).map((id) => getNFTMetaData(otherNFTs[index], chain, id)),
      )

      return [...hunterAndProHunter, ...otherNFTsMetaData]
    }

    if (chain === NETWORKS.STARKNET) {
      const starknetNFTs = await getStarknetNFTs(user)
      return starknetNFTs
    }

    const nftTypes = ['hunter', 'TOP30', 'VOLUME', 'TXCOUNT', 'ALPHAKEY', 'BETAKEY'] as EVMNFTType[]
    const nftIDs = await P.map(nftTypes, (type) => getUserTokenIds(type, chain, user))

    const nftsWithMetaData = nftIDs.map((nftIdsForType, index) =>
      (nftIdsForType || []).map((id) => getNFTMetaData(nftTypes[index], chain, id)),
    )

    return flatMap(nftsWithMetaData)
  } catch (error) {
    console.error(`Failed to get NFTs for chain ${chain}`, error)
    return []
  }
}
