import P from '@rhino.fi/aigle'
import { walletToStarkKey } from '../starkService'
import { getDvf } from '../dvfClient'
import type { WalletPayload } from '../wallets/wallet'
import { getAuthenticationData, generateUserRegistrationMetadata } from '../apiService'
import type { AppDispatch } from '../../store/store.types'

import type { L1RegistrationSignature } from '../dvfClient/DvfStark'
import {
  addStatusNotification,
  updateStatusNotification,
} from '../../actions/notificationActions/statusNotificationsActions'
import { StatusNotificationStatus } from '../../actions/types/statusNotifications'
import { translate } from '../../intl/i18n'
import { APP_WALLETS } from '../../constants/types'
import { L1SignatureRegistrationStatus, userSlice } from '../../reducers/userSlice'

const makeL1BackupNotificationId = (index: number) => `l1-signature-registration-generation-${index}`

const signaturePendingNotification = (dispatch: AppDispatch, index: number) =>
  addStatusNotification(dispatch)({
    id: makeL1BackupNotificationId(index),
    title: translate('global.pending_l1_signature_backup'),
    status: StatusNotificationStatus.pending,
    meta: {
      description: translate('global.pending_signature_message'),
    },
  })

const signatureErrorNotification = (dispatch: AppDispatch, index: number) =>
  updateStatusNotification(dispatch)(makeL1BackupNotificationId(index), {
    title: translate('global.failed_l1_signature_backup'),
    status: StatusNotificationStatus.error,
  })

const signatureSuccessNotification = (dispatch: AppDispatch, index: number) =>
  updateStatusNotification(dispatch)(makeL1BackupNotificationId(index), {
    status: StatusNotificationStatus.success,
  })

export const register = (dispatch: AppDispatch) => async (wallet: NonNullable<WalletPayload>) => {
  const dvf = await getDvf()
  const isAppWallet = APP_WALLETS.includes(wallet.walletType)
  // User does not exist therefore we must fall back to auth without tradingKey
  const { nonce, signature } = await getAuthenticationData(dvf, {
    useTradingKey: false,
    addressToCheck: wallet?.address,
  })

  const { starkKey, encryptedTradingKey } = await walletToStarkKey(wallet)

  // start: generate l1 signature:
  dispatch(userSlice.actions.setL1SignatureRegistrationStatus(L1SignatureRegistrationStatus.REQUESTED))
  let l1RegistrationSignature: L1RegistrationSignature | null = null

  try {
    let called = 0
    l1RegistrationSignature = await P.retry(
      {
        times: 5,
        interval: 0,
      },
      async () => {
        let result
        try {
          if (isAppWallet) {
            signaturePendingNotification(dispatch, called)
          }
          result = await dvf.stark.signRegistration(wallet.address)
        } catch (error) {
          console.error('Failed to sign registration', error)
          if (isAppWallet) {
            signatureErrorNotification(dispatch, called)
          }
          called++
          throw error
        }
        if (isAppWallet) {
          signatureSuccessNotification(dispatch, called)
        }
        return result
      },
    )
  } catch (error) {
    console.error(error)
    dispatch(userSlice.actions.setL1SignatureRegistrationStatus(L1SignatureRegistrationStatus.FAILED))
    throw error
  }
  dispatch(userSlice.actions.setL1SignatureRegistrationStatus(L1SignatureRegistrationStatus.GENERATED))
  // end: generate l1 signature

  try {
    return dvf.register({
      ethAddress: wallet.address,
      starkPublicKey: starkKey,
      nonce,
      signature,
      encryptedTradingKey,
      meta: generateUserRegistrationMetadata(wallet),
      l1RegistrationSignature,
    })
  } catch (err) {
    console.error(err)
    throw err
  } finally {
    dispatch(userSlice.actions.setL1SignatureRegistrationStatus(L1SignatureRegistrationStatus.UNKNOWN))
  }
}
