import P from '@rhino.fi/aigle'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getNFTsForChain } from '../components/Portfolio/PortfolioAssets/NFTAssets/NFTMinterAssets/getNFTsForChain'
import type { NFTMinter } from '../components/Portfolio/PortfolioAssets/NFTAssets/NFTMinterAssets/NFTMinterAssets'
import { getEnabledNFTMinters } from '../components/ActivityTracker/ActivityTrackerNFT/helpers/isNFTMinterEnabledForChain'
import { NETWORKS } from '../constants/types'

export const mintedNFTsApi = createApi({
  reducerPath: 'mintedNFTsApi',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['MintedNFTs', 'StarknetMintedNFTs'],
  endpoints: (builder) => ({
    getMintedNFTs: builder.query<NFTMinter[], { address: string }>({
      queryFn: async ({ address }) => {
        try {
          const nfts = await P.map(getEnabledNFTMinters(), (chain) => getNFTsForChain(chain, address))

          return {
            data: nfts.flat(),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch user NFTs' } }
        }
      },
      providesTags: ['MintedNFTs'],
    }),
    getStarknetMintedNFTs: builder.query<NFTMinter[], { secondaryAddress: string }>({
      queryFn: async ({ secondaryAddress }) => {
        try {
          const nfts = await getNFTsForChain(NETWORKS.STARKNET, secondaryAddress)

          return {
            data: nfts.flat(),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch user NFTs' } }
        }
      },
      providesTags: ['StarknetMintedNFTs'],
    }),
  }),
})

export const { useGetMintedNFTsQuery, useGetStarknetMintedNFTsQuery } = mintedNFTsApi
