import { translate } from '../../intl/i18n'

export const getOrderStatus = (
  active: string | boolean | undefined,
  canceled: boolean | undefined,
  totalFilled: number,
  orderAmount: number,
) => {
  if (!active && totalFilled >= orderAmount) {
    return {
      label: 'Executed',
      tooltip: null,
    }
  }
  if (!active && !canceled && totalFilled < orderAmount) {
    return {
      label: 'Executed',
      tooltip: `${translate('helpers.executed')} (${translate('helpers.partial')})`,
    }
  }
  if (totalFilled === 0 && canceled) {
    return {
      label: 'Canceled',
      tooltip: null,
    }
  }
  if (totalFilled > 0 && canceled) {
    return {
      label: 'Canceled',
      tooltip: `${translate('helpers.canceled')} (${translate('helpers.partial')})`,
    }
  }
  if (!totalFilled && canceled) {
    return {
      label: 'Canceled',
      tooltip: null,
    }
  }
  return {
    label: 'Executed',
    tooltip: null,
  }
}
