import { WALLETS } from '../../constants/types'

export const WalletFlows = {
  EVMWALLET: [
    WALLETS.METAMASK,
    WALLETS.TRUST,
    WALLETS.TOKEN_POCKET,
    WALLETS.COINBASE,
    WALLETS.WALLET_CONNECT,
    WALLETS.OKX,
    WALLETS.WEBWALLET,
  ],
  LEDGER: [WALLETS.LEDGER],
  KEYSTORE: [WALLETS.KEYSTORE],
}
