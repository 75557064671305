import { padStart } from 'lodash'

export const parseInvalidEIP712Signature = (signature: string) => {
  // eslint-disable-next-line id-length -- v is the name
  const v = `0x${signature.slice(-2)}`
  const validV = (parseInt(v) - 27).toString()
  console.warn(`Invalid EIP712 signature: ${parseInt(v)}. Using v=${validV}`)

  return `${signature.slice(0, -2)}${padStart(validV, 2, '0')}`
}
