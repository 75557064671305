import forEach from 'lodash/forEach'
import type { AddressEntry } from '../ethereum/blockchainBalances'
import type { UnifiedTokenRegistryState } from '../../reducers/types/UnifiedTokenRegistryState'

export const getTokenAddressesForChain = (
  tokens: string[],
  desiredChain: string,
  tokenRegistry: UnifiedTokenRegistryState,
) => {
  return tokens.reduce((accumulator, token) => {
    const tokenConfig = tokenRegistry[token]

    // Token Registry Tokens
    if (!tokenConfig.xchain && 'tokenAddressPerChain' in tokenConfig) {
      forEach(tokenConfig.tokenAddressPerChain, (contractAddress, chain) => {
        if (chain === 'MATIC_POS_PARENT') {
          return
        }
        if (chain === desiredChain) {
          accumulator.push({ token, address: contractAddress })
        }
      })
    }

    // Cross chain tokens
    if (tokenConfig.xchain && 'chain' in tokenConfig && 'address' in tokenConfig) {
      if (tokenConfig.chain === desiredChain) {
        accumulator.push({ token, address: tokenConfig.address })
      }
    }

    return accumulator
  }, [] as AddressEntry[])
}
