import { envConfig } from '../env/envConfig'
import { sendAndReportTxHash } from './ethereumService'
import { authTypes } from './apiService'
import { getWeb3 } from './wallets/wallet'
import type { EIP712Domain } from './eip712Service.types'
import type { DvfClientInstance } from './dvfClient/DvfClientInstance'

const { tradingApi, network } = envConfig

export type TransferFundsData = {
  token: string
  amount: string
  recipientEthAddress: string
  memo?: string
  feeAmount?: string
}

export type LedgerJSONData = {
  primaryType: string
  domain: EIP712Domain
  message: Record<string, unknown>
  types: Record<string, Array<{ name: string; type: string }>>
}

type InitDvfProps = {
  useAuthHeader?: boolean
  authType?: null | 'tradingKey' | 'signature'
  wallet?: null | {
    type: 'ledger'
    meta: {
      path: string
    }
  }
  send?: unknown
  web3?: unknown
  autoSelectAccount?: boolean
}

class DVFClient {
  private initializedPromise: Promise<boolean> | null = null
  private initialized = false
  private dvf: DvfClientInstance | null = null

  public async init({
    send = null,
    authType = null,
    wallet = null,
    useAuthHeader = true,
    web3 = getWeb3(),
    autoSelectAccount = false,
  }: InitDvfProps) {
    if (this.initializedPromise) {
      // initialization is already in progress
      return this.getDvf()
    }

    let resolveInitializedPromise: (value: boolean) => void = () => {}
    this.initializedPromise = new Promise((resolve) => {
      resolveInitializedPromise = resolve
    })

    // @ts-expect-error - client library is not typed
    const { default: DVF } = await import(/* webpackPreload: true */ '@rhino.fi/client-js')

    this.dvf = await (DVF(web3, {
      api: tradingApi,
      send: send || sendAndReportTxHash,
      autoLoadUserConf: false,
      starkProvider: null,
      ethereumChainId: network,
      autoSelectAccount,
      web3,
      ...(authType ? { [authTypes[authType]]: true } : {}),
      ...(wallet ? { wallet } : {}),
      ...(useAuthHeader && { useAuthHeader: true }),
    }) as Promise<DvfClientInstance>)

    resolveInitializedPromise(true)
    this.initialized = true
  }

  public async getDvf() {
    if (!!this.initializedPromise && (await this.initializedPromise)) {
      if (!this.dvf) {
        throw new Error('Rhino client initialization was not successful')
      }
      return this.dvf
    } else {
      throw new Error('Rhino client was not initialized')
    }
  }

  public clearDvf() {
    this.dvf = null
    this.initializedPromise = null
    this.initialized = false
  }
}

let dvfClient: DVFClient

export const clearDvf = () => {
  if (dvfClient) {
    dvfClient.clearDvf()
  }
}

export const setDvf = async (props: InitDvfProps = {}) => {
  clearDvf()
  dvfClient = new DVFClient()
  await dvfClient.init(props)
  return dvfClient.getDvf()
}

export const getDvf = async () => {
  if (dvfClient) {
    return dvfClient.getDvf()
  } else {
    dvfClient = new DVFClient()
    await dvfClient.init({})
    return dvfClient.getDvf()
  }
}
