import { Buffer } from 'buffer'
import { keccak256 } from 'ethereumjs-util'
import { getProvider } from '../wallets/wallet'
import { envConfig } from '../../env/envConfig'
import type { SupportedWallets } from '../../constants/types'
import { WalletFlows } from '../wallets/walletFlows'
import {
  SigningMethod,
  EIP712_MESSAGE_DEVERSIFI_v2,
  EIP712_MESSAGE_RHINO_v3,
  EIP712_MESSAGE_TESTNET_DEVERSIFI_v2,
  EIP712_SIGNATURE_STRUCT_TESTNET_v2,
  EIP712_SIGNATURE_STRUCT_v2,
  EIP712_DOMAIN_v2,
  EIP712_DOMAIN_v3,
} from './signatures'

const { network } = envConfig

export const selectSigningMethod = (walletType: SupportedWallets) => {
  if (WalletFlows.EVMWALLET.includes(walletType)) {
    return SigningMethod.v4
  } else {
    return SigningMethod.v4
  }
}

export const getMessage_v2 = (networkId: number) => {
  return {
    types: {
      EIP712Domain: EIP712_DOMAIN_v2,
      DeversiFi: networkId === 1 ? EIP712_SIGNATURE_STRUCT_v2 : EIP712_SIGNATURE_STRUCT_TESTNET_v2,
    },
    domain: {
      name: 'DeversiFi',
      version: '1.0.0',
      chainId: networkId,
    },
    primaryType: 'DeversiFi',
    message: networkId === 1 ? EIP712_MESSAGE_DEVERSIFI_v2 : EIP712_MESSAGE_TESTNET_DEVERSIFI_v2,
  }
}

export const getRhinoMessage_v3 = () => {
  return {
    types: {
      EIP712Domain: EIP712_DOMAIN_v3,
      'rhino.fi': EIP712_SIGNATURE_STRUCT_v2,
    },
    domain: {
      name: 'rhino.fi',
      version: '1.0.0',
    },
    primaryType: 'rhino.fi',
    message: EIP712_MESSAGE_RHINO_v3,
  }
}

export const hashFromEIP712Signature = (data?: string | null) => {
  if (!data) {
    throw new Error('No data provided - DTK generation')
  }
  const hashedData = keccak256(Buffer.from(data))
  return hashedData.toString('hex')
}

export const signEIP712Hash = async (
  address: string,
  signingMethod: (typeof SigningMethod)[keyof typeof SigningMethod],
  dtkVersion: string | undefined,
  parsed = true,
) => {
  if (dtkVersion !== 'v3' && dtkVersion !== 'v2') {
    throw new Error('Invalid DTK version.')
  }
  const data = dtkVersion === 'v3' ? getRhinoMessage_v3() : getMessage_v2(network)
  let rpcMethod
  let rpcData
  if (getProvider() === null) {
    throw new Error('Cannot sign since Web3 currentProvider is null')
  }

  switch (signingMethod) {
    case SigningMethod.v3:
      rpcMethod = 'eth_signTypedData_v3'
      rpcData = JSON.stringify(data)
      break
    case SigningMethod.v4:
      rpcMethod = 'eth_signTypedData_v4'
      rpcData = JSON.stringify(data)
      break
    case SigningMethod.v4_plain:
      rpcMethod = 'eth_signTypedData_v4'
      rpcData = data
      break
    case SigningMethod.v4_default:
      rpcMethod = 'eth_signTypedData'
      rpcData = JSON.stringify(data)
      break
    default:
      throw new Error(`Invalid signing method ${signingMethod}`)
  }
  const params = [address, rpcData]
  const result = await getProvider().request({
    method: rpcMethod,
    params,
  })

  if (typeof result !== 'string') {
    throw new Error('Invalid signature result')
  }

  if (!parsed) {
    return result
  }

  return hashFromEIP712Signature(result)
}
