import type { ListenerEffectAPI } from '@reduxjs/toolkit'
import { translate } from '../intl/i18n'
import type { FilledRootState } from '../store/rootReducer'
import type { AppDispatch } from '../store/store.types'
import {
  addStatusNotification,
  clearStatusNotification,
} from '../actions/notificationActions/statusNotificationsActions'
import { StatusNotificationStatus } from '../actions/types/statusNotifications'
import { WALLETS } from '../constants/types'
import { isAccountRegisteredInDB, storeStarkL1Registration } from '../services/apiService'
import type { userSlice } from '../reducers/userSlice'

/**
 * In case of hardware wallets such as Ledger
 * stark signing requires user intervention
 * hence a popup is needed to direct users for
 * taking action
 */
const withNotifications = (dispatch: AppDispatch) => async (fn: () => Promise<unknown>) => {
  const notifId = 'backup-l1-registration-signature'

  addStatusNotification(dispatch)({
    id: notifId,
    title: translate('global.pending_l1_signature_backup'),
    status: StatusNotificationStatus.pending,
    meta: {
      description: translate('global.pending_signature_message'),
    },
  })
  try {
    await fn()
  } finally {
    clearStatusNotification(dispatch)(notifId)
  }
}

const backupL1Registration = async (address: string | undefined, dispatch: AppDispatch, isLedger: boolean) => {
  if (!address) {
    return
  }

  const { l1RegistrationSignature, isRegisteredOnChain } = await isAccountRegisteredInDB(address)

  // Not required if user is already registered onchain
  if (isRegisteredOnChain) {
    return
  }

  if (!l1RegistrationSignature) {
    const backupFn = async () => {
      try {
        await storeStarkL1Registration()
      } catch (err) {
        if (!(err instanceof Error)) {
          return
        }

        addStatusNotification(dispatch)({
          id: `backup-l1-registration-signature-failed`,
          title: translate('global.failed_l1_signature_backup'),
          status: StatusNotificationStatus.error,
          meta: {
            description: err.message,
          },
        })

        throw err
      }
    }

    await (isLedger ? withNotifications(dispatch)(backupFn) : backupFn())
  }
}

export const backupL1RegistrationSignatureWalletConnectedListener = async (
  action: ReturnType<typeof userSlice.actions.setAuthenticated>,
  listenerApi: ListenerEffectAPI<FilledRootState, AppDispatch, unknown>,
) => {
  const { wallet } = action.payload

  const walletType = listenerApi.getState().user.wallet.data?.walletType
  const isLedger = walletType === WALLETS.LEDGER

  // Trading key has not been set
  if (!isLedger) {
    if (!listenerApi.getState().user.tradingKey?.data?.privateKey) {
      return
    }
  }

  await backupL1Registration(wallet?.address, listenerApi.dispatch, isLedger)
}

export const backupL1RegistrationSignatureSetTradingKeyListener = async (
  action: ReturnType<typeof userSlice.actions.setTradingKey>,
  listenerApi: ListenerEffectAPI<FilledRootState, AppDispatch, unknown>,
) => {
  const address = listenerApi.getState().user.wallet.data?.address

  await backupL1Registration(address, listenerApi.dispatch, false)
}
