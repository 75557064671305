import {
  CONTRACTS_INTERACTIONS_CHAINS,
  CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN,
} from './contractsInteractions.constants'

export const getContractsInteractionsSavedChain = () => {
  const defaultChain = CONTRACTS_INTERACTIONS_CHAINS[0]
  const lsChain = localStorage.getItem(CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN)

  if (lsChain) {
    const isLsChainEnabled = CONTRACTS_INTERACTIONS_CHAINS.includes(lsChain)

    if (isLsChainEnabled) {
      return lsChain
    } else {
      localStorage.removeItem(CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN)
    }
  }

  return defaultChain
}
