import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../../env/envConfig'
import { addAuthorizationHeader } from '../auth/addAuthorizationHeader'
import { firstInPairPreserveCase } from '../formatService'

const endpointsWithAuth: string[] = ['getYieldData']

export type UfavourableRateData = {
  price: string
  priceToPriceAsk: string
  priceToPriceBid: string
  unfavourablePegs: Array<[string, string]>
  unfavourablePriceBuy: boolean
  unfavourablePricePeg: boolean
  unfavourablePriceSell: boolean
}

export type YieldData = {
  token: string
  yieldTokenBalance: string
  valueAtSupplyTime: string
  earnings: string
  timestamp: number
}

export type GetYieldDataResponse = {
  trueYieldTokenBalance: string
  valueAtSupplyTime: string
  earnings: string
}

export const yieldApi = createApi({
  reducerPath: 'yieldApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.tradingApi}/v1/trading`,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      headers.set('accept', 'application/json')

      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: ['unfavourableRate', 'SymbolYieldData'],
  endpoints: (builder) => ({
    getUnfavourableRateData: builder.query<UfavourableRateData, { market: string }>({
      query: ({ market }) => ({ url: `/r/yieldPrice/${market}` }),
      providesTags: (result, error, { market }) => [{ type: 'unfavourableRate', id: market }],
    }),
    getYieldData: builder.query<Record<string, YieldData>, { symbols: string[] }>({
      query: ({ symbols }) => ({ url: `/r/yieldData?symbols=${symbols.join(',')}` }),
      providesTags: (result, error, { symbols }) => symbols.map((symbol) => ({ type: 'SymbolYieldData', id: symbol })),
      transformResponse: (response: GetYieldDataResponse[], meta, arg) => {
        const now = Date.now()
        return arg.symbols.reduce(
          (accumulator, current, index) => {
            const token = firstInPairPreserveCase(current)
            const responseForSymbol = response[index++]

            accumulator[token] = {
              yieldTokenBalance: responseForSymbol.trueYieldTokenBalance || '0',
              valueAtSupplyTime: responseForSymbol.valueAtSupplyTime || '0',
              earnings: responseForSymbol.earnings || '0',
              token,
              timestamp: now,
            }

            return accumulator
          },
          {} as Record<string, YieldData>,
        )
      },
      transformErrorResponse: (error, meta, arg) => {
        console.error(error)
        const now = Date.now()
        return arg.symbols.map((symbol) => ({
          yieldTokenBalance: '0',
          valueAtSupplyTime: '0',
          earnings: '0',
          token: firstInPairPreserveCase(symbol),
          now,
        }))
      },
    }),
  }),
})

export const { useGetUnfavourableRateDataQuery, useLazyGetYieldDataQuery } = yieldApi
